import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { ListWalletsProps, WalletStatus } from '@interfaces/Wallets.interfaces';
import { getWalletsList } from '@services/wallet/WalletServices';
import { walletKeys } from './walletKeys';

export interface UseInfiniteWalletListQuery {
  status: WalletStatus;
  search?: string;
  organizationId?: string | null;
  orderBy?: string | undefined;
  order?: string | undefined;
  ownerIds?: string[] | undefined;
}

export const useInfiniteWalletListQuery = (
  props: UseInfiniteWalletListQuery
) => {
  const { organizationId, ...params } = props;
  return useInfiniteQuery<
    ListWalletsProps,
    ApiError,
    InfiniteData<ListWalletsProps>,
    unknown[],
    string
  >({
    queryKey: walletKeys.list({ organizationId, ...params }),
    queryFn: ({ pageParam }) => getWalletsList({ ...params, next: pageParam }),
    getNextPageParam: (lastPage) => lastPage?.next || null,
    initialPageParam: '',
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    enabled: !!organizationId,
  });
};
