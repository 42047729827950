import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  Camera,
  ContextualError,
  CreditCard,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { regex } from '@constants/regex';
import { CardTypeEnum } from '@enums/Card.enum';
import { typeCardEnum } from '@enums/typeCard.enum';
import { useQueryParams } from '@hooks/useQueryParams';
import {
  CardPayloadRequest,
  SidebarCardProps,
} from '@interfaces/Card.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import {
  Button,
  ErrorMessage,
  Icon,
  Input,
  Spinner,
  Toastr,
  Typography,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useCreateCardMutation } from '@services/cards/hooks';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export const SidebarCard = ({
  actionModal,
  setCloseSidebar,
  setPinProps,
}: SidebarCardProps) => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();
  const { closeDrawer } = useDrawer();

  const [selectedTypeCard, setSelectedTypeCard] = useState('');
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const [cameraBlock, setCameraBlock] = useState(false);
  const [cardId, setcardId] = useState('');

  const walletId = queryParams.get('walletId');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<CardPayloadRequest>({
    mode: 'onChange',
  });

  const selectedCard = (typeCard: string) => {
    if (typeCard !== selectedTypeCard) {
      setSelectedTypeCard((prevShowCardDetails) =>
        prevShowCardDetails === typeCard ? '' : typeCard
      );
    }
  };

  const {
    mutate: createCard,
    isPending: isCreatingCard,
    customError: cardError,
  } = useCreateCardMutation();

  const activeCard = (typeCard: string) => {
    return selectedTypeCard === typeCard ? 'type_credit_card--active' : '';
  };

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStream(stream);
      setCameraBlock(false);
    } catch {
      if (!cameraBlock) {
        setCameraBlock(true);
      }
    }
  };

  const submitForm = async (formData: CardPayloadRequest) => {
    eventsHandler.submitDrawerCardCreationAdminCards();
    const payload = { ...formData, cardId };
    removeEmptyObjectAttributes(payload);

    createCard(
      {
        payload,
        walletId: walletId ?? 'default',
      },
      {
        onSuccess: (response) => {
          customerEvents.cardCreated({
            cardName: payload.name,
            cardType: payload.type,
            walletId: response.wallet,
            cardId: response.id.toString(),
          });

          if (response.type === CardTypeEnum.VIRTUAL) {
            Toastr.success(t('card.virtual-card-created'));
            closeDrawer();
          }

          if (response.type === CardTypeEnum.EMBOSSED) {
            setPinProps({
              cardId: response.id.toString(),
              walletId: response.wallet,
            });
          }
        },
      }
    );
  };

  useEffect(() => {
    cameraStream &&
      cameraStream.getTracks().forEach(function (track) {
        track.stop();
      });
  }, [cameraStream]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <ContextualError error={cardError} />
      <SidebarSection
        numberSection={1}
        titleSection="Selecionar tipo de cartão"
        subTitleSection="Você pode escolher entre um cartão físico ou virtual."
      >
        <CreditCard
          typeCard="embossed"
          titleCard="Cartão físico"
          onClick={() => {
            eventsHandler.selectCardTypePhysicalAdminCards();
            selectedCard('Físico');
            checkCameraPermission();
            setCloseSidebar(true);
            reset({ type: CardTypeEnum.EMBOSSED });
            setcardId('');
          }}
          className={`${activeCard(typeCardEnum.EMBOSSED)}`}
        >
          Um cartão físico que você recebeu pelos correios da Portão 3. Se ainda
          não tiver um, entre em contato com nosso time e solicite o seu.
        </CreditCard>

        <CreditCard
          typeCard="virtual"
          titleCard="Cartão virtual"
          onClick={() => {
            eventsHandler.selectCardTypeVirtualAdminCards();
            selectedCard('Virtual');
            setCloseSidebar(true);
            reset({ type: CardTypeEnum.VIRTUAL });
            setcardId('');
          }}
          className={`${activeCard(typeCardEnum.VIRTUAL)}`}
        >
          Tenha maior segurança ao realizar pagamentos e compras online
          utilizando cartões virtuais.
        </CreditCard>
      </SidebarSection>

      {selectedTypeCard === typeCardEnum.EMBOSSED && (
        <>
          <SidebarSection
            numberSection={2}
            titleSection="Escanear o QR Code no verso do cartão"
            subTitleSection="Após realizar a leitura do cartão, ele estará pronto para uso."
          >
            <Camera
              checkCameraPermission={checkCameraPermission}
              cameraStream={cameraStream}
              getCode={setcardId}
              cameraBlock={cameraBlock}
            >
              {cardId && (
                <Alert status="success">
                  <Typography
                    tag="p"
                    weight="p2"
                    color="var(--product-neutral-n500)"
                  >
                    {t('card.qr-code')}
                  </Typography>
                </Alert>
              )}
            </Camera>
          </SidebarSection>

          {cardId && (
            <SidebarSection
              numberSection={3}
              titleSection="Configurar o seu cartão"
              subTitleSection="Defina um apelido para seu cartão."
            >
              <Input
                type="text"
                placeholder="Inserir apelido"
                label="Apelido"
                id="name"
                name="name"
                data-testid="cardName"
                register={register}
                maxLength={25}
                showCounter
                error={!!errors['name']}
                validationSchema={{
                  required: 'O apelido do cartão é obrigatório',
                  pattern: {
                    value: regex.apelidoCardName,
                    message:
                      'O apelido deve conter pelo menos duas letras e não pode conter caracteres "\\" ou "/"',
                  },
                }}
                required
              >
                {errors['name']?.message && (
                  <ErrorMessage message={errors['name'].message.toString()} />
                )}
              </Input>
            </SidebarSection>
          )}
        </>
      )}

      {selectedTypeCard === typeCardEnum.VIRTUAL && (
        <SidebarSection
          numberSection={2}
          titleSection="Configurar o seu cartão"
          subTitleSection="Defina um apelido para seu cartão."
        >
          <Input
            type="text"
            placeholder="Inserir apelido"
            label="Apelido"
            id="name"
            name="name"
            data-testid="cardName"
            maxLength={25}
            showCounter
            register={register}
            error={!!errors['name']}
            validationSchema={{
              required: 'O apelido do cartão é obrigatório',
              pattern: {
                value: regex.apelidoCardName,
                message:
                  'O apelido deve conter pelo menos duas letras e não pode conter caracteres "\\" ou "/"',
              },
            }}
            required
          >
            {errors['name']?.message && (
              <ErrorMessage message={errors['name'].message.toString()} />
            )}
          </Input>
        </SidebarSection>
      )}

      <SidebarFooter>
        {isDirty ? (
          <Button
            onClick={() => {
              reset();
            }}
            variant="tertiary"
            size="large"
            type="reset"
            data-testid="resetForm"
          >
            Limpar campos
            <Icon size="large">
              <i className="fa-regular fa-trash"></i>
            </Icon>
          </Button>
        ) : (
          <Button
            type="button"
            onClick={() => {
              actionModal(true);
            }}
            variant="tertiary"
            size="large"
          >
            Voltar
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          disabled={!isValid || !isDirty || isCreatingCard}
          data-testid="submitForm"
          className="button_submit"
        >
          {isCreatingCard ? (
            <Spinner variant="secondary" />
          ) : (
            'Cadastrar Cartão'
          )}
        </Button>
      </SidebarFooter>
    </form>
  );
};
