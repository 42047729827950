import dayjs from 'dayjs';
import { useCallback, useLayoutEffect, useState } from 'react';

import { ButtonMoreItems, EmptyStateBlock } from '@components';
import { initialStateTransactionsPeriodCompany } from '@constants/TransactionCompany';
import { emptyPeriodTransactions, errorState } from '@constants/emptyState';
import { limitPerPage } from '@constants/limitPerPage';
import { typePaymentOptions } from '@enums/typePayment.enum';
import { useQueryParams } from '@hooks';
import { TransactionsResponse } from '@interfaces/Transactions.interfaces';
import { eventsHandler } from '@lib';
import {
  Icon,
  Skeleton,
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableRow,
  Typography,
} from '@portao3-web/ui';
import { getTransactions } from '@services/organization';
import {
  billingAmountAuthorized,
  rejectBillingAmount,
} from '@utils/BillingAmoutAuthorized';
import { renderTableHeader } from '@utils/renderTableHeader';
import {
  bankSlipPayment,
  billingPayment,
  pixTransaction,
} from '@utils/transactionFormatFunctions';
import { useParams } from 'react-router-dom';
import { TransactionsModuleProps } from './TransactionsModule.interface';
import './TransactionsModule.styles.scss';

export const TransactionsModule = ({
  thead,
  setTransactionsPeriodLoading,
  transactionsPeriodLoading = false,
  setTransactionsPeriodCompany,
  transactionsPeriodCompany,
  endpoint,
  columnActions,
}: TransactionsModuleProps) => {
  const { walletId } = useParams();
  const { queryParams } = useQueryParams();

  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');

  const [transactionsCompanyLoading, setTransactionsCompanyLoading] =
    useState(false);
  const [errorServiceTransactionCompany, setErrorServiceTransactionCompany] =
    useState(false);
  const [hideNextPageCompany, setHideNextPageCompany] = useState(true);
  const [transactionsPeriodCompanyCached, setTransactionsPeriodCompanyCached] =
    useState<TransactionsResponse>(initialStateTransactionsPeriodCompany);
  const [nextPage, setNextPage] = useState<string>('');
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState(false);

  const [transactionsPeriodLoadingLocal, setTransactionsPeriodLoadingLocal] =
    useState(transactionsPeriodLoading);
  const [transactionsPeriodCompanyLocal, setTransactionsPeriodCompanyLocal] =
    useState<TransactionsResponse>(
      transactionsPeriodCompany || initialStateTransactionsPeriodCompany
    );

  const emptyTransaction =
    !transactionsPeriodCompanyLocal ||
    transactionsPeriodCompanyLocal?.items?.length === 0;

  const getTransactionsPeriodCached = async () => {
    const newTransactions = { ...transactionsPeriodCompanyLocal };
    newTransactions.items.push(...transactionsPeriodCompanyCached.items);
    setTransactionsPeriodCompany &&
      setTransactionsPeriodCompany(newTransactions);
    setTransactionsPeriodCompanyLocal(newTransactions);
    setIsLoadingMoreItems(true);

    if (nextPage && startDate && endDate) {
      const response = await getTransactions(
        endpoint,
        startDate,
        endDate,
        limitPerPage,
        nextPage
      );

      setTransactionsPeriodCompanyCached(response);

      setNextPage(response.next);
      setIsLoadingMoreItems(false);
    } else {
      setIsLoadingMoreItems(false);
      setHideNextPageCompany(false);
    }
  };

  const getTransactionsPeriod = useCallback(
    async (startDate: string, endDate: string) => {
      setTransactionsCompanyLoading(true);
      setTransactionsPeriodLoading && setTransactionsPeriodLoading(true);
      setTransactionsPeriodLoadingLocal(true);
      setErrorServiceTransactionCompany(false);
      const nextPageUser = '';
      setHideNextPageCompany(true);

      try {
        const response = await getTransactions(
          endpoint,
          startDate,
          endDate,
          limitPerPage,
          nextPageUser
        );

        if (response.next) {
          const transactionsCached = await getTransactions(
            endpoint,
            startDate,
            endDate,
            limitPerPage,
            response.next
          );

          setTransactionsPeriodCompanyCached(transactionsCached);
          setNextPage(transactionsCached.next);
        } else {
          setHideNextPageCompany(false);
        }

        setTransactionsPeriodCompany && setTransactionsPeriodCompany(response);
        setTransactionsPeriodCompanyLocal(response);
        setTransactionsPeriodLoading && setTransactionsPeriodLoading(false);
        setTransactionsPeriodLoadingLocal(false);
        setTransactionsCompanyLoading(false);
      } catch (error) {
        setTransactionsCompanyLoading(false);
        setTransactionsPeriodLoading && setTransactionsPeriodLoading(false);
        setTransactionsPeriodLoadingLocal(false);
        setErrorServiceTransactionCompany(true);
      }
    },
    [setTransactionsPeriodLoading, setTransactionsPeriodCompany, endpoint]
  );

  useLayoutEffect(() => {
    if (!startDate) return;

    getTransactionsPeriod(startDate, endDate || startDate);
  }, [startDate, endDate, getTransactionsPeriod, endpoint]);

  const financialImpactType = (item: string) => {
    return item === 'NONE' ? 'line_through' : '';
  };

  return (
    <div className="company_transactions">
      <Table>
        <TableHead>
          <TableRow>
            {thead.map((columnTitle: string, key: number) =>
              renderTableHeader(columnTitle, key)
            )}
          </TableRow>
        </TableHead>
        {!errorServiceTransactionCompany &&
          transactionsPeriodCompanyLocal.items.length > 0 && (
            <TableBody>
              {transactionsPeriodCompanyLocal.items.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCol>
                      <Skeleton
                        width={80}
                        isLoading={transactionsPeriodLoadingLocal}
                      >
                        <Typography
                          tag="p"
                          color="var(--product-neutral-n500)"
                          className={`card_list_item_info--totally ${financialImpactType(
                            item.financialImpactType
                          )}`}
                          weight="p2"
                        >
                          {dayjs(item.effectiveAt).format('DD/MM/YYYY')}
                        </Typography>
                        <Typography
                          tag="p"
                          color="var(--product-neutral-n100)"
                          className={`card_list_item_info--totally ${financialImpactType(
                            item.financialImpactType
                          )}`}
                          weight="p3"
                        >
                          {dayjs(item.effectiveAt).format('HH:mm')}
                        </Typography>
                      </Skeleton>
                    </TableCol>
                    <TableCol className="td_icon">
                      <Skeleton
                        variant="circular"
                        width={33}
                        height={33}
                        isLoading={transactionsPeriodLoadingLocal}
                      >
                        <div className="company_icon_table">
                          <Icon size="xlarge">
                            {item.cardTransaction && (
                              <i className="fa-regular fa-credit-card"></i>
                            )}

                            {item.pixTransaction && (
                              <i className="fa-brands fa-pix"></i>
                            )}

                            {item.boletoTransaction && (
                              <i className="fa-regular fa-barcode"></i>
                            )}

                            {item.p2pTransaction && (
                              <i className="fa-solid fa-right-left"></i>
                            )}

                            {item.feeTransaction && (
                              <i className="fa-regular fa-file-invoice-dollar"></i>
                            )}

                            {item.billingTransaction && (
                              <i className="fa-solid fa-file-invoice"></i>
                            )}
                          </Icon>
                        </div>
                      </Skeleton>
                    </TableCol>
                    <TableCol>
                      <Skeleton
                        width={'95%'}
                        isLoading={transactionsPeriodLoadingLocal}
                      >
                        <Typography
                          tag="p"
                          color="var(--product-neutral-n500)"
                          className={`card_list_item_info--totally ${financialImpactType(
                            item.financialImpactType
                          )}`}
                          weight="p2"
                        >
                          {item.cardTransaction?.merchantName}
                          {item.pixTransaction && pixTransaction(item)}
                          {item.boletoTransaction && bankSlipPayment(item)}
                          {item.billingTransaction && billingPayment(item)}
                          {item.feeTransaction &&
                            item.feeTransaction.description}
                          {item.p2pTransaction && `Transferência interna`}
                        </Typography>
                        <Typography
                          tag="p"
                          color="var(--product-neutral-n100)"
                          className={`card_list_item_info--totally ${financialImpactType(
                            item.financialImpactType
                          )}`}
                          weight="p3"
                        >
                          {/* Cartão {item.card_number} */}
                          {item.cardTransaction && 'Cartão'}
                          {item.billingTransaction &&
                            `Pagamento via ${
                              typePaymentOptions[
                                item.billingTransaction.initiationType
                              ]
                            }`}
                        </Typography>
                      </Skeleton>
                    </TableCol>
                    <TableCol>
                      <Skeleton
                        width={'65%'}
                        isLoading={transactionsPeriodLoadingLocal}
                      >
                        <Typography
                          tag="p"
                          color={`var(${
                            item.financialImpactType === 'DEBIT'
                              ? '--product-danger-d500'
                              : '--product-neutral-n500'
                          })`}
                          className={`card_list_item_info--totally ${financialImpactType(
                            item.financialImpactType
                          )}`}
                          weight="p2"
                        >
                          {item.financialImpactType === 'NONE'
                            ? rejectBillingAmount(item)
                            : billingAmountAuthorized(item)}
                        </Typography>
                      </Skeleton>
                    </TableCol>
                    {columnActions && (
                      <TableCol>
                        <Skeleton
                          width={'65%'}
                          isLoading={transactionsPeriodLoadingLocal}
                        >
                          {columnActions(item)}
                        </Skeleton>
                      </TableCol>
                    )}
                  </TableRow>
                );
              })}
              {hideNextPageCompany &&
                !transactionsCompanyLoading &&
                !errorServiceTransactionCompany &&
                transactionsPeriodCompanyLocal.items.length > 0 && (
                  <ButtonMoreItems
                    colSpan={4}
                    onClick={() => {
                      walletId === 'default' &&
                        eventsHandler.clickButtonLoadMoreTransactionsAdminWalletStatement();
                      !walletId &&
                        eventsHandler.clickButtonLoadMoreTransactionsAdminWalletStatement();

                      getTransactionsPeriodCached();
                    }}
                    isLoadingMoreItems={isLoadingMoreItems}
                  />
                )}
            </TableBody>
          )}
      </Table>
      {emptyTransaction &&
        !errorServiceTransactionCompany &&
        !transactionsPeriodLoadingLocal && (
          <EmptyStateBlock details={emptyPeriodTransactions} />
        )}
      {errorServiceTransactionCompany && (
        <EmptyStateBlock details={errorState} />
      )}
    </div>
  );
};
