import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Alert,
  MyWallet,
  NewPix,
  PaymentConfirmation,
  PaymentVoucher,
} from '@components';
import { BankSlip } from '@interfaces/BankSlip.interface';
import { PixPayloadService } from '@interfaces/Pix.interface';
import {
  WalletCategory,
  WalletDetailsProps,
} from '@interfaces/Wallets.interfaces';
import { eventsHandler } from '@lib';
import { Typography } from '@portao3-web/ui';
import { useWalletQuery } from '@services/wallet/hooks';

interface DrawerPixProps {
  typePayment: string;
  openDrawerPix: boolean;
  back: (open: boolean) => void;
  walletId?: string;
  close: () => void;
  categoryType?: WalletCategory;
}

export const DrawerNewPix = ({
  typePayment,
  openDrawerPix,
  back,
  walletId = '',
  close,
}: DrawerPixProps) => {
  const { pathname } = useLocation();

  const [newPix, setNewPix] = useState(false);
  const organizationId = localStorage.getItem('organization') ?? '';
  const [confirmationPayment, setConfirmationPayment] = useState(false);
  const [paymentVoucher, setPaymentVoucher] = useState(false);
  const [selectCategory, setSelectCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState<WalletCategory>(
    {} as WalletCategory
  );
  const [dictType, setDictType] = useState('');

  const { data: wallet } = useWalletQuery({ walletId, organizationId });

  const [confirmPaymentPixBankSlip, setConfirmPaymentPixBankSlip] = useState<
    PixPayloadService | BankSlip
  >({} as PixPayloadService | BankSlip);

  useEffect(() => {
    walletId === 'default'
      ? setNewPix(openDrawerPix)
      : setSelectCategory(openDrawerPix);
  }, [openDrawerPix, walletId]);

  const closeNewPix = () => {
    setNewPix(false);
    setConfirmationPayment(true);
  };

  const backNewPayments = () => {
    pathname.includes('/wallets') &&
      eventsHandler.backButtonAreaPixTransferWallet();

    setNewPix(false);
    back(false);
    setConfirmationPayment(false);
  };

  const confirmPayment = (close: boolean) => {
    setConfirmationPayment(close);
    setPaymentVoucher(true);
  };

  const selectedCategory = (categoryType: WalletCategory) => {
    setCategorySelected(categoryType);
    if (categoryType.amount === 0) {
      return;
    }

    setSelectCategory(false);
    setNewPix(true);
  };

  const getBalance = (
    category: WalletCategory,
    wallet?: WalletDetailsProps
  ) => {
    if (category.category) {
      const balance = wallet?.balances?.find(
        (item) => item.category === category.category
      );
      return balance?.amount ?? 0;
    }
    return wallet?.totalBalance ?? 0;
  };

  return (
    <>
      {categorySelected.amount === 0 && (
        <Alert status="error">
          <Typography tag="p" weight="p2" color="var(--product-error-d500)">
            Você não possui saldo para fazer a transação!
          </Typography>
        </Alert>
      )}

      {selectCategory && (
        <div className="sidebar_myWallet">
          <MyWallet
            walletCategoryList={wallet?.balances ?? []}
            totalBalance={wallet?.totalBalance ?? 0}
            setSelectedCategory={(element) => selectedCategory(element)}
            isClickable={true}
          />
        </div>
      )}

      {newPix && (
        <NewPix
          close={closeNewPix}
          back={backNewPayments}
          confirmPayment={(confirmPixPayment) => {
            setConfirmPaymentPixBankSlip(confirmPixPayment);
          }}
          walletId={walletId}
          balance={getBalance(categorySelected, wallet)}
          setDictType={setDictType}
        />
      )}

      {confirmationPayment && (
        <PaymentConfirmation
          close={confirmPayment}
          back={backNewPayments}
          type={typePayment}
          paymentConfirm={confirmPaymentPixBankSlip}
          paymentVoucher={(paymentVoucher) => {
            setConfirmPaymentPixBankSlip(paymentVoucher);
          }}
          walletId={walletId}
          categoryType={categorySelected.category}
          dictType={dictType}
        />
      )}

      {paymentVoucher && (
        <PaymentVoucher
          type={typePayment}
          close={close}
          paymentVoucher={confirmPaymentPixBankSlip}
        />
      )}
    </>
  );
};
