import { endpoint, organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { environment } from '@environments/environment';
import {
  Transactions,
  TransactionsResponse,
} from '@interfaces/Transactions.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export interface FindTransactionListParams {
  startDate?: string | null;
  endDate?: string | null;
  limit?: number;
  next?: string;
  walletId: string | undefined;
  description: string | null;
  financialImpactType: string | null;
  transactionTypes: string[] | null;
}

export const findTransactionList = async (
  params: FindTransactionListParams
): Promise<TransactionsResponse> => {
  const { walletId, ...rest } = params;

  const paramsCopy = { ...rest };
  paramsCopy.limit = paramsCopy.limit || limitPerPage;
  removeEmptyObjectAttributes(paramsCopy);

  const { data } = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      walletId ? `${endpoint.wallets}/${walletId}/` : ''
    }${endpoint.transactions}`,
    { params: paramsCopy }
  );

  return data;
};

export interface FindTransactionParams {
  transactionId: string;
  walletId: string;
}

export const findTransaction = async ({
  transactionId,
  walletId,
}: FindTransactionParams): Promise<Transactions> => {
  const { data } = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/${endpoint.transactions}/${transactionId}`
  );

  return data;
};
