import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  ClearFiltersQuery,
  MultiSelectQuery,
  Pagination,
  SearchQuery,
} from '@components';
import {
  emptyInvoices,
  emptyInvoicesWithFilter,
  errorInvoices,
} from '@constants/emptyState';
import { regex } from '@constants/regex';
import { useQueryParams } from '@hooks';
import { InvoiceStatus } from '@interfaces/Invoices';
import { Button, Card, EmptyState, Skeleton } from '@portao3-web/ui';
import { useInfiniteBillingDealListQuery } from '@services/billingDeal/hooks/useInfiniteBillingDealListQuery';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { AccordionItem } from './components/AccordionItem';
import { initialStateInvoice } from './constants/initialStateInvoice';
import { useInfinityInvoicesCustomerQuery } from './hooks/useCustomersInvoicesQuery';

import './CustomersInvoices.styles.scss';

dayjs.extend(utc);

export const CustomerInvoices = () => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();
  const { customerId } = useParams();
  const [page, setPage] = useState(0);

  const status = queryParams.getAll('status');
  const invoiceQuery = queryParams.get('invoice');
  const dealId = queryParams.get('dealId') as string;

  const hasFilters = status.length || invoiceQuery || dealId;

  const { data: dealData, isLoading: isLoadingDeals } =
    useInfiniteBillingDealListQuery({
      limit: 500,
      customerId,
    });

  const {
    data: invoices,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch: getServiceInvoice,
  } = useInfinityInvoicesCustomerQuery({
    status,
    id:
      invoiceQuery && regex.uuid.test(invoiceQuery) ? invoiceQuery : undefined,
    description:
      invoiceQuery && !regex.uuid.test(invoiceQuery) ? invoiceQuery : undefined,
    dealId,
    customerId,
  });

  const statusOptions = [
    {
      label: t('invoices.invoiceStatus.CLOSED'),
      value: InvoiceStatus.CLOSED,
    },
    {
      label: t('invoices.invoiceStatus.PAID'),
      value: InvoiceStatus.PAID,
    },
    {
      label: t('invoices.invoiceStatus.OPEN'),
      value: InvoiceStatus.OPEN,
    },
  ];

  const dealOptions = useMemo(() => {
    return (
      dealData?.pages[0].items?.map((deal) => {
        return {
          label: deal.description,
          value: deal.id,
        };
      }) || []
    );
  }, [dealData]);

  return (
    <div className="customerInvoices">
      <div className="customerInvoices_accordion">
        <div className="mb-4 flex flex-wrap gap-3">
          <SearchQuery
            placeholder={t('invoices.id-invoice')}
            data-testid="search-invoice"
            query="invoice"
          />

          <MultiSelectQuery
            maxSelectedShow={0}
            query="status"
            title={t('general.status')}
            options={statusOptions}
          />

          <MultiSelectQuery
            query="dealId"
            title={t('general.deal')}
            isLoading={isLoadingDeals}
            options={dealOptions}
            type="single"
          />

          <ClearFiltersQuery queries={['status', 'invoice', 'dealId']} />
        </div>

        {(isFetching
          ? initialStateInvoice.items
          : invoices?.pages?.[page]?.items
        )?.map((invoice, index) => {
          const accordionId = `accordion-${index}`;
          return (
            <Skeleton
              height={60}
              isLoading={isFetching}
              className="mb-2"
              key={index}
            >
              <AccordionItem invoice={invoice} accordionId={accordionId} />
            </Skeleton>
          );
        })}

        {invoices?.pages?.[page]?.items?.length !== 0 && !isError && (
          <Pagination
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            page={page}
            setPage={setPage}
            pageCount={invoices?.pages.length}
            className="ml-auto"
          />
        )}

        {invoices?.pages?.[page]?.items?.length === 0 && !isError && (
          <div className="customerInvoices_empty">
            <Card>
              <EmptyState
                direction="column"
                title={emptyInvoices.title}
                subtitle={
                  hasFilters
                    ? emptyInvoicesWithFilter.subtitle
                    : emptyInvoices.subtitle
                }
                image={emptyInvoices.image}
              />
            </Card>
          </div>
        )}

        {isError && (
          <div className="customerInvoices_empty">
            <Card>
              <EmptyState
                direction="column"
                title={errorInvoices.title}
                subtitle={errorInvoices.subtitle}
                image={errorInvoices.image}
              >
                <Button
                  type="button"
                  size="large"
                  variant="primary"
                  data-testid="try-again"
                  onClick={() => getServiceInvoice()}
                >
                  {t('button.try-again')}
                </Button>
              </EmptyState>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};
