import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerRangeQuery, Pagination } from '@components';
import { ICONS } from '@constants/icons';
import { BatchPaymentStatus } from '@enums/BatchPayment.enum';
import { useQueryParams } from '@hooks/useQueryParams';
import { Button, TableDemo } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useInfiniteBatchListQuery } from '@services/batchPayment/hooks/useInfiniteBatchListQuery';
import { BatchStatusFilter } from '../batchStatusFilter';
import { useBatchesColumns } from './columns';

export const Batches = () => {
  const organizationId = localStorage.getItem('organization');

  const batchesColumns = useBatchesColumns();
  const { queryParams, setQueryParams } = useQueryParams();
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();

  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const status = queryParams.getAll('status') as BatchPaymentStatus[];

  const [page, setPage] = useState(0);

  const {
    data: batchData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteBatchListQuery({
    organizationId,
    endDate,
    startDate,
    status,
  });

  const batchList = useMemo(() => {
    return batchData?.pages?.[page]?.items?.map((batch) => ({
      ...batch,
      onRowClick: () =>
        openDrawer('batch-payment', {
          batchId: batch.id,
          walletId: batch.wallet,
          batchStatus: batch.status,
        }),
    }));
  }, [batchData, openDrawer, page]);

  const handleClearFilters = () => {
    setQueryParams({ status: undefined });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-center">
        <h2 className="text-h3 text-neutral-500 ">{t('general.batches')}</h2>
        <DatePickerRangeQuery />
      </div>

      <div className="flex gap-3 items-center">
        <BatchStatusFilter />
        {status.length > 0 && (
          <Button variant="tertiary" size="medium" onClick={handleClearFilters}>
            {t('general.clear-filters')} <i className={`${ICONS.x} fa-1x`} />
          </Button>
        )}
      </div>
      <TableDemo
        columns={batchesColumns}
        data={batchList}
        isLoading={isLoading || isFetchingNextPage}
        pinLastColumn
      />
      <Pagination
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        page={page}
        setPage={setPage}
        pageCount={batchData?.pages?.length}
        className="ml-auto"
      />
    </div>
  );
};
