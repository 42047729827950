import { createSentryClickBreadcrumb } from '@lib/sentry';

enum WALLET_STATEMENT_EVENT {
  NAVIGATE_BREADCRUMB_WALLETS = 'navigate_breadcrumb_wallets_walletStatement',
  VIEW_OPTIONS_EXPORT = 'view_options_export_walletStatement',
  SELECT_OPTION_TYPE_CSV = 'select_option_typeCsv_walletStatement',
  SELECT_OPTION_TYPE_XLSX = 'select_option_typeXlsx_walletStatement',
  SELECT_OPTION_TYPE_OFX = 'select_option_typeOfx_walletStatement',
  SELECT_DATEPICKER_RANGE = 'select_datepickerRange_walletStatement',
  SHOW_BUTTON_FUNDS_IN = 'show_button_fundsIn_walletStatement',
  HIDE_BUTTON_FUNDS_IN = 'hide_button_fundsIn_walletStatement',
  SHOW_BUTTON_FUNDS_OUT = 'show_button_fundsOut_walletStatement',
  HIDE_BUTTON_FUNDS_OUT = 'hide_button_fundsOut_walletStatement',
  OPEN_DRAWER_TRANSACTION_DETAILS = 'open_drawer_transactionDetails_walletStatement',
  CLICK_BUTTON_LOAD_MORE_TRANSACTIONS = 'click_button_loadMoreTransactions_walletStatement',
  CLOSE_DRAWER_TRANSACTION_DETAILS = 'close_drawer_transactionDetails_walletStatement',
  CLICK_INPUT_CUSTOM_FIELDS = 'click_input_customFields_walletStatement',
  DRAG_UPLOAD_TRANSACTION_DETAILS = 'drag_upload_transactionDetails_walletStatement',
  CLICK_UPLOAD_TRANSACTION_DETAILS = 'click_upload_transactionDetails_walletStatement',
  SUBMIT_BUTTON_TRANSACTION_DETAILS = 'submit_button_transactionDetails_walletStatement',
}

export const walletStatementEvents = {
  navigateBreadcrumbWalletsWalletStatement: () => {
    createSentryClickBreadcrumb(
      WALLET_STATEMENT_EVENT.NAVIGATE_BREADCRUMB_WALLETS
    );
  },
  viewOptionsExportWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.VIEW_OPTIONS_EXPORT);
  },
  selectOptionTypeCsvWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.SELECT_OPTION_TYPE_CSV);
  },
  selectOptionTypeXlsxWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.SELECT_OPTION_TYPE_XLSX);
  },
  selectOptionTypeOfxWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.SELECT_OPTION_TYPE_OFX);
  },
  selectDatepickerRangeWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.SELECT_DATEPICKER_RANGE);
  },
  showButtonFundsInWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.SHOW_BUTTON_FUNDS_IN);
  },
  hideButtonFundsInWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.HIDE_BUTTON_FUNDS_IN);
  },
  showButtonFundsOutWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.SHOW_BUTTON_FUNDS_OUT);
  },
  hideButtonFundsOutWalletStatement: () => {
    createSentryClickBreadcrumb(WALLET_STATEMENT_EVENT.HIDE_BUTTON_FUNDS_OUT);
  },
  openDrawerTransactionDetailsWalletStatement: () => {
    createSentryClickBreadcrumb(
      WALLET_STATEMENT_EVENT.OPEN_DRAWER_TRANSACTION_DETAILS
    );
  },
  clickButtonLoadMoreTransactionsWalletStatement: () => {
    createSentryClickBreadcrumb(
      WALLET_STATEMENT_EVENT.CLICK_BUTTON_LOAD_MORE_TRANSACTIONS
    );
  },
  closeDrawerTransactionDetailsWalletStatement: () => {
    createSentryClickBreadcrumb(
      WALLET_STATEMENT_EVENT.CLOSE_DRAWER_TRANSACTION_DETAILS
    );
  },
  clickInputCustomFieldsWalletStatement: () => {
    createSentryClickBreadcrumb(
      WALLET_STATEMENT_EVENT.CLICK_INPUT_CUSTOM_FIELDS
    );
  },
  dragUploadTransactionDetailsWalletStatement: () => {
    createSentryClickBreadcrumb(
      WALLET_STATEMENT_EVENT.DRAG_UPLOAD_TRANSACTION_DETAILS
    );
  },
  clickUploadTransactionDetailsWalletStatement: () => {
    createSentryClickBreadcrumb(
      WALLET_STATEMENT_EVENT.CLICK_UPLOAD_TRANSACTION_DETAILS
    );
  },
  submitButtonTransactionDetailsWalletStatement: () => {
    createSentryClickBreadcrumb(
      WALLET_STATEMENT_EVENT.SUBMIT_BUTTON_TRANSACTION_DETAILS
    );
  },
};
