import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  ContextualError,
  MyWallet,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { viewCategoryWallet } from '@enums/WalletEnum';
import { useQueryParams } from '@hooks/useQueryParams';
import { WalletCategory, WalletRole } from '@interfaces/Wallets.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import {
  Button,
  ErrorMessage,
  Input,
  Modal,
  Spinner,
  Typography,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import {
  useUpdateWalletBalanceMutation,
  useUserWalletQuery,
  useWalletQuery,
} from '@services/wallet/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { moneyMask } from '@utils/formatMasks';
import {
  WalletControllerProps,
  WalletValueForm,
} from './WalletControler.interface';
import './WalletController.styles.scss';

export const WalletController = ({
  onClose,
  setModalOpen,
}: WalletControllerProps) => {
  const { pathname } = useLocation();
  const { queryParams } = useQueryParams();
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();

  const organizationId = localStorage.getItem('organization') ?? '';
  const walletId = queryParams.get('walletId') ?? '';
  const userId = queryParams.get('userId') ?? '';

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<WalletCategory>(
    {} as WalletCategory
  );

  const { data: walletById } = useWalletQuery({ walletId, organizationId });
  const { data: walletByUser } = useUserWalletQuery({ userId, organizationId });

  const {
    mutate: updateWalletBalance,
    isPending: isUpdatingWalletBalance,
    customError,
  } = useUpdateWalletBalanceMutation();

  const wallet = walletByUser || walletById;

  const walletOwner =
    wallet?.shared &&
    wallet.shared?.find((item) => item.role === WalletRole.OWNER);
  let fullname = `${walletOwner?.firstName} ${walletOwner?.lastName}` || '';
  if (fullname.includes('undefined')) fullname = '-';

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    clearErrors,
    getValues,
    setValue,
  } = useForm<WalletValueForm>({
    mode: 'onBlur',
  });

  const resetFields = () => {
    reset();
    clearErrors();
  };

  useEffect(() => {
    reset();
    clearErrors();
    setSelectedCategory({} as WalletCategory);
  }, [reset, clearErrors]);

  useEffect(() => {
    setModalOpen(isDirty);
  }, [isDirty, setModalOpen]);

  useEffect(() => {
    if (walletId === 'default') {
      closeDrawer();
    }
  }, [walletId, closeDrawer]);

  const handleSelectedCategory = (elem: WalletCategory) => {
    resetFields();
    setSelectedCategory(elem);
  };

  const handleUpdateWalletValues = async () => {
    if (!wallet) return;

    updateWalletBalance(
      {
        walletId: wallet.id,
        payload: {
          category: selectedCategory.category,
          amount: Number(getValues('amount').replace(/\D/g, '')),
        },
      },
      {
        onSuccess: () => {
          customerEvents.walletBalanceUpdated({
            amount: Number(getValues('amount').replace(/\D/g, '')),
            id: wallet.id,
            type: selectedCategory.category,
          });

          closeDrawer();
        },
      }
    );
  };

  return (
    <>
      <Modal
        title="Alteração de saldo"
        open={openModal}
        closeModal={setOpenModal}
      >
        <div className="wallet_controller wallet_controller_modal">
          <Typography tag="p" weight="p1" color="#A5A5B8">
            Você está ajustando o saldo da categoria
            <span className="wallet_controller wallet_controller_modal_bold_text">
              {` ${viewCategoryWallet[selectedCategory.category]} `}
            </span>
            para{' '}
            <span className="wallet_controller wallet_controller_modal_bold_text">
              {` ${getValues('amount')} `}
            </span>
            na carteira de{' '}
            <span className="wallet_controller wallet_controller_modal_bold_text">
              {fullname}
            </span>
            .
          </Typography>
          <Typography
            tag="p"
            weight="p1"
            color="var(--product-neutral-n80)"
            className="wallet_controller wallet_controller_modal_last_text"
          >
            Você quer mesmo continuar?
          </Typography>
        </div>
        <footer className="footer_modal">
          <Button
            size="large"
            variant="tertiary"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Não
          </Button>
          <div style={{ marginLeft: 10 }}>
            <Button
              size="large"
              variant="primary"
              onClick={() => {
                setOpenModal(false);
                handleUpdateWalletValues();
              }}
            >
              Sim, prosseguir
            </Button>
          </div>
        </footer>
      </Modal>

      <SidebarSection
        numberSection={1}
        titleSection="Visualize ou ajuste o saldo por categoria"
        subTitleSection="Clique em uma categoria para aumentar ou reduzir o saldo."
      >
        <MyWallet
          walletCategoryList={wallet?.balances}
          totalBalance={wallet?.totalBalance}
          setSelectedCategory={(elem) => handleSelectedCategory(elem)}
          isClickable={true}
        />
      </SidebarSection>
      <form
        className="wallet_controller form_container_user_controller"
        onSubmit={handleSubmit(() => '')}
      >
        {selectedCategory && selectedCategory.category && (
          <div className="form_container_user_controller_container mt-4">
            <SidebarSection
              numberSection={2}
              titleSection="Altere os valores da categoria"
              subTitleSection={`Digite o novo valor do saldo da categoria ${
                viewCategoryWallet[selectedCategory.category]
              }`}
            >
              <Input
                type="tel"
                placeholder={calcCurrencyValue(selectedCategory.amount, 'BRL')}
                label="Digite o novo valor"
                id="amount"
                name="amount"
                data-testid="amount"
                register={register}
                error={!!errors['amount']}
                validationSchema={{
                  required: 'O valor é obrigatório',
                  validate: (value: string) => {
                    const money = moneyMask(value || '');
                    setValue('amount', money);
                  },
                }}
                required
              >
                {errors['amount']?.message && (
                  <ErrorMessage message={errors['amount'].message.toString()} />
                )}
              </Input>
              <div className="wallet_controller_buttons_action">
                <Button
                  onClick={() => {
                    pathname?.includes('/user') &&
                      eventsHandler.cancelButtonUpdateWalletBalanceAdminUsers();
                    pathname?.includes('/wallets') &&
                      eventsHandler.cancelButtonUpdateWalletBalanceWallet();

                    resetFields();
                  }}
                  variant="tertiary"
                  size="large"
                  type="reset"
                >
                  Cancelar
                </Button>
                <Button
                  size="large"
                  disabled={!isValid || !isDirty || isUpdatingWalletBalance}
                  className="form_container_user_controller_footer_button_submit"
                  onClick={() => {
                    pathname?.includes('/user') &&
                      eventsHandler.submitButtonUpdateWalletBalanceAdminUsers();
                    pathname?.includes('/wallets') &&
                      eventsHandler.submitButtonUpdateWalletBalanceWallet();
                    setOpenModal(true);
                  }}
                >
                  {isUpdatingWalletBalance ? (
                    <Spinner variant="secondary" />
                  ) : (
                    'Atualizar'
                  )}
                </Button>
              </div>
              <ContextualError error={customError} />
            </SidebarSection>
          </div>
        )}
      </form>
      <SidebarFooter>
        <Button
          size="large"
          className="form_container_user_controller_footer_button_submit"
          onClick={() => {
            resetFields();
            onClose();
            setModalOpen(true);
          }}
        >
          {t('general.finish')}
        </Button>
      </SidebarFooter>
    </>
  );
};
