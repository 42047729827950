import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ErrorCodes } from '@enums/ErrorCodes.enum';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import {
  WalletCategory,
  WalletDetailsProps,
} from '@interfaces/Wallets.interfaces';
import { Toastr } from '@portao3-web/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextualErrorType } from '../../../components/alerts';
import { updateWalletValues } from '../WalletServices';
import { walletKeys } from './walletKeys';

interface UpdateWalletValues {
  walletId: string;
  payload: WalletCategory;
}

export const useUpdateWalletBalanceMutation = () => {
  const organizationId = localStorage.getItem('organization') ?? '';

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [customError, setCustomError] = useState<ContextualErrorType>(null);

  const mutation = useMutation<
    WalletDetailsProps,
    ApiError,
    UpdateWalletValues,
    unknown
  >({
    mutationFn: ({ payload, walletId }: UpdateWalletValues) =>
      updateWalletValues(walletId, payload),
    onSuccess: (response, variables) => {
      Toastr.success('Saldo atualizado com sucesso!');
      queryClient.invalidateQueries({ queryKey: walletKeys.lists() });
      queryClient.setQueryData(
        walletKeys.detail({ walletId: variables.walletId, organizationId }),
        (oldData: WalletDetailsProps): WalletDetailsProps => ({
          ...oldData,
          balances: response.balances,
          totalBalance: response.totalBalance,
        })
      );
      setCustomError(null);
    },
    onError: (error) => {
      const errorCode = error?.response?.data
        ?.code as keyof typeof errorMessages;
      const traceId = error?.response?.data?.traceId;

      const errorMessages = {
        [ErrorCodes.ERR_BAD_REQUEST]: t('error.insuficient-default-funds'),
        default: t('error.default'),
      };

      setCustomError({
        message: errorMessages[errorCode] || errorMessages.default,
        traceId,
      });
    },
  });

  return { ...mutation, customError };
};
