import { HeaderForm } from '@components';
import { Button, InputOTP, Spinner, Toastr, Typography } from '@portao3-web/ui';
import { AuthContext } from '@providers';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NextStepProps } from '../../interfaces/NextStep.interface';
import { requestToken } from '../services/requestPasswordServices';

export const Token = ({ handleNextStep }: NextStepProps) => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resendTokenState, setResendTokenState] = useState(false);
  const [obscuredEmail, setObscuredEmail] = useState('');
  const { email, tokenRecoveryPassword } = useContext(AuthContext);

  const [token, setToken] = useState('');

  useEffect(() => {
    const { value } = email;
    if (value.includes('@')) {
      const [username, domain] = value.split('@');
      const cutOff = Math.min(4, Math.floor(username.length / 2));
      const hiddenName = '*'.repeat(username.length - cutOff);
      const shownName = cutOff === 0 ? '' : username.slice(-cutOff);
      const obscuredUsername = `${hiddenName} ${shownName}`;
      const obscured = `${obscuredUsername}@${domain}`;
      setObscuredEmail(obscured);
    }
  }, [email]);

  const validationCode = () => {
    if (token.length === 6) {
      tokenRecoveryPassword.setTokenRecoveryPassword(token);
      setLoading(true);
      handleNextStep();
    }
  };

  const resendToken = async () => {
    setResendTokenState(true);
    const data = {
      email: email.value,
    };

    try {
      await requestToken(data);

      email.setEmail(data.email);
      setResendTokenState(false);
      Toastr.success('Token enviado com sucesso!');
    } catch {
      setLoading(false);
      Toastr.error('Estamos em manutenção');
    }
  };

  return (
    <>
      <HeaderForm
        title="Solicitação enviada!"
        subTitle={`Enviamos um código para o e-mail abaixo: ${obscuredEmail}`}
      />

      <form className="authentication_content_right_form_inputs">
        <div className="authentication_submit">
          <div className="flex items-center mb-6 justify-center">
            <InputOTP
              key="input-mfa-create"
              length={6}
              disabled={loading}
              value={token}
              setValue={setToken}
            />
          </div>

          <Button
            onClick={validationCode}
            size="large"
            disabled={token.length < 6 || loading}
            data-testid="validationCode"
          >
            {loading ? <Spinner variant="secondary" /> : 'Validar código'}
          </Button>

          <Button
            onClick={() => history('/login')}
            size="large"
            disabled={loading}
            variant="secondary"
          >
            Cancelar
          </Button>
        </div>
      </form>

      <div className="authentication_not_account">
        <Typography tag="p">Não recebeu o código?</Typography>
        <span
          className="resend_code"
          onClick={resendToken}
          data-testid="resendToken"
        >
          {resendTokenState ? (
            <Spinner variant="primary" />
          ) : (
            `Solicite um novo em 30 segundos`
          )}
        </span>
      </div>
    </>
  );
};
