import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { PixKeyType } from '@interfaces/Pix.interface';
import api from '@services/api';
import {
  CreateDictResponse,
  PixDictParams,
  PixKey,
} from '../interfaces/interfaces';

export const createPixDict = async (
  walletId: string,
  payload: { type: PixKeyType; value: string }
): Promise<CreateDictResponse> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/pix-dict`,
    payload
  );

  const { data } = response;

  return data;
};

export const removePixDict = async (walletId: string, dictId: string) => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/pix-dict/${dictId}/remove`
  );

  const { data } = response;

  return data;
};

export async function getPixDict({
  walletId,
  pin,
}: PixDictParams): Promise<{ items: PixKey[] }> {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${endpoint.wallets}/${walletId}/pix-dict?status=ACTIVE`,
    {
      headers: { pin },
    }
  );

  const { data } = response;

  return data;
}
