import { HeaderPage } from '@components';
import { Tabs } from '@portao3-web/ui';
import { CustomFieldsTable } from './components/CustomFieldsTable';
import { Invoices } from './components/Invoices';
import { useDrawer } from '@providers';
import { BaseSyntheticEvent, useState, useEffect } from 'react';
import { CustomField } from '@interfaces/CustomFields.interfaces';
import { eventsHandler } from '@lib';
import { DrawerEditCustomField } from '@modules/drawer/drawerEditCustomField/DrawerEditCustomField';
import { useQueryParams } from '@hooks';

export const Preferences = () => {
  const { openDrawer } = useDrawer();
  const [changedElement, setChangedElement] = useState(false);
  const { queryParams, setQueryParams } = useQueryParams();

  const tabIndex = queryParams.get('tab');

  useEffect(() => {
    tabIndex === null && setQueryParams({ tab: 'invoices' });
  }, [tabIndex, setQueryParams]);

  const handleOpenEditPreferencesDrawer = (
    elem?: CustomField,
    event?: BaseSyntheticEvent
  ) => {
    eventsHandler.openDrawerCreateFieldAdminPreferences();

    if (event?.target.closest('[data-column-action]')) return;
    openDrawer(
      <DrawerEditCustomField
        field={elem}
        hasCustomFieldsUpdates={setChangedElement}
      />
    );
  };

  const handleTabChange = (tab: string) => {
    const activeTab = tabs.find((t) => t.id === tab);
    setQueryParams({ tab: activeTab?.id });
    setQueryParams({ query: undefined });
    setQueryParams({ status: undefined });
  };

  const tabs = [
    {
      id: 'invoices',
      label: 'Faturas',
      content: <Invoices />,
    },
    {
      id: 'customfields',
      label: 'Campos customizáveis',
      content: (
        <CustomFieldsTable
          editSelectedData={handleOpenEditPreferencesDrawer}
          hasCustomFieldsUpdates={changedElement}
        />
      ),
    },
  ];

  const tabParam = queryParams.get('tab');
  const selectedTabIndex = tabParam
    ? tabs.findIndex((tab) => tab.id === tabParam)
    : 0;

  return (
    <div className="flex flex-col">
      <HeaderPage
        title="Ajustes da organização"
        subTitle="Visualize suas faturas e outras configurações da sua organização."
      ></HeaderPage>

      <Tabs
        tabs={tabs}
        start={selectedTabIndex !== -1 ? selectedTabIndex : 0}
        onTabChange={handleTabChange}
        key={selectedTabIndex}
      ></Tabs>
    </div>
  );
};
