import { environment } from '@environments/environment';
import { CurrentUser } from '../../interfaces/User.interfaces';

export function identifyCustomerIo(user: CurrentUser) {
  if (!environment.production) return;

  const windowAlias: Window & typeof globalThis = window;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics = (windowAlias as any).analytics;

  try {
    analytics.identify(user.id, {
      id: user.id,
      email: user.email,
      username: `${user.firstName} ${user.lastName}`,
    });
  } catch (error) {
    console.error('Error on Identity', error);
  }
}

export function groupCustomerIo(user: CurrentUser) {
  if (!environment.production) return;

  const windowAlias: Window & typeof globalThis = window;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics = (windowAlias as any).analytics;

  try {
    analytics.group(user.realm.id, {
      name: user.realm.name,
    });
  } catch (error) {
    console.error('Error on Group', error);
  }
}

export const resetCustomerIoIdentity = () => {
  if (!environment.production) return;

  const windowAlias: Window & typeof globalThis = window;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics = (windowAlias as any).analytics;

  try {
    analytics.reset();
  } catch (error) {
    console.error('Error on reset Identity', error);
  }
};

export const trackCustomerIoEvent = <T>(name: string, traits?: T) => {
  if (!environment.production) return;

  const windowAlias: Window & typeof globalThis = window;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics = (windowAlias as any).analytics;

  try {
    analytics.track(name, traits);
  } catch (error) {
    console.error('Error on trackCustomer', error);
  }
};
