import { Transactions } from '@interfaces/Transactions.interfaces';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';

export const billingAmountAuthorized = (item: Transactions) => {
  return item.financialImpactType === 'DEBIT'
    ? calcCurrencyValue(-item.billingAmountAuthorized, 'BRL')
    : calcCurrencyValue(item.billingAmountAuthorized, 'BRL');
};

export const rejectBillingAmount = (item: Transactions) => {
  return calcCurrencyValue(item.billingAmount, 'BRL');
};
