import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { useQuery } from '@tanstack/react-query';
import { InvoicesDealResponse } from '@interfaces/Invoices.interfaces';
import { getInvoiceList } from '../services/InvoicesService';
import { invoiceKeys } from './InvoiceKeys';

export const useInvoiceQuery = () => {
  return useQuery<
    InvoicesDealResponse,
    ApiError,
    InvoicesDealResponse,
    unknown[]
  >({
    queryKey: invoiceKeys.list(),
    queryFn: () => getInvoiceList(),
  });
};
