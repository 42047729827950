import { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';

import { Alert } from '@components';
import { Option } from '@interfaces/CustomFields.interfaces';
import { eventsHandler } from '@lib';
import {
  Button,
  Dialog,
  ErrorMessage,
  Input,
  Typography,
} from '@portao3-web/ui';
import { useDrawerEditCustomField } from '../../contexts/DrawerEditCustomFieldContext';
import { orderOptions } from '../../utils/orderOptions';
import '../customFieldTypeSelection/CustomFieldTypeSelection.styles.scss';
import { EditOptionForm } from '../editOptionForm';

export const SelectField = () => {
  const isMounted = useRef(false);

  const {
    resetField,
    register,
    unregister,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useDrawerEditCustomField();

  const FIELD_TYPE = {
    SELECT: 'seleção única',
    MULTI_SELECT: 'multi-seleção',
  };

  const type = watch('type');
  const value = watch('values');

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    return () => {
      resetField('label');
      resetField('externalIdentifier');
      unregister('values');
    };
  }, [resetField, unregister]);

  const handleRemoveOption = (optionLabel: string) => {
    setValue(
      'values',
      orderOptions(
        value?.filter((item: Option) => {
          return item.label !== optionLabel;
        })
      )
    );
  };

  return (
    <div className="custom-field-config--flex" key={type}>
      <Input
        name="label"
        label="Nome do campo"
        placeholder="Ex: Atributos"
        register={register}
        validationSchema={{ required: 'Nome do campo é obrigatório' }}
        error={!!errors.label?.message}
      >
        {errors.label?.message && (
          <ErrorMessage message={errors.label.message} />
        )}
      </Input>
      <Input
        name="externalIdentifier"
        label="Identificador externo (Opcional)"
        placeholder="Ex: atributos"
        register={register}
      />
      <div className="custom-field-config_label">
        <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
          Opções
        </Typography>
        <Dialog variant="popover">
          <Dialog.Trigger
            trackUserAction={
              eventsHandler.createFieldNewAttributeAdminPreferences
            }
          >
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="custom-field-config_add-item"
            >
              Adicionar atributo
              <i className="fa-regular fa-plus fa-1x" />
            </Button>
          </Dialog.Trigger>
          <Dialog.Content>
            <EditOptionForm />
          </Dialog.Content>
        </Dialog>
      </div>
      <Controller
        name="values"
        control={control}
        rules={{
          required: true,
          validate: (value: Option[]) => value.length > 1,
        }}
        render={({ field, fieldState }) => {
          return (
            <>
              {field.value?.map((item: { label: string; value: string }) => (
                <div
                  className="custom-field-config_select-option"
                  key={item.label}
                >
                  <i className="fa-regular fa-grip fa-1x item-icon" />
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n600)"
                    className="option-label"
                  >
                    {item.label}
                  </Typography>
                  <div className="custom-field-config_select-option_button-container">
                    <Dialog variant="popover">
                      <Dialog.Trigger>
                        <Button
                          type="button"
                          variant="tertiary"
                          size="small"
                          className="option-action-button"
                          data-testid={`edit-option-${item.value}`}
                        >
                          <i className="fa-regular fa-pencil fa-1x item-icon" />
                        </Button>
                      </Dialog.Trigger>
                      <Dialog.Content>
                        <EditOptionForm defaultOption={item} />
                      </Dialog.Content>
                    </Dialog>
                    <Button
                      type="button"
                      variant="tertiary"
                      size="small"
                      className="option-action-button"
                      onClick={() => handleRemoveOption(item.label)}
                      data-testid={`remove-option-${item.value}`}
                    >
                      <i className="fa-regular fa-trash fa-1x item-icon" />
                    </Button>
                  </div>
                </div>
              ))}

              {fieldState.error && (
                <div style={{ marginTop: 16 }}>
                  <Alert status="error">
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      Você precisa adicionar pelo menos 2 atributos para
                      configurar o campo de{' '}
                      {FIELD_TYPE[type as keyof typeof FIELD_TYPE]}.
                    </Typography>
                  </Alert>
                </div>
              )}
            </>
          );
        }}
      />
      {!(value?.length > 0) && (
        <div className="custom-field-config_add-more-placeholder">
          <Typography
            tag="p"
            weight="p3"
            color="var(--product-neutral-n100)"
            className="add-items-placeholder-text"
          >
            Adicione no mínimo <span>2 atributos</span> para ser possível
            configurar sua lista de seleção única.
          </Typography>
          <Dialog position="bottom" variant="popover">
            <Dialog.Trigger
              trackUserAction={
                eventsHandler.createFieldEmptyNewAttributeAdminPreferences
              }
            >
              <Button type="button" variant="secondary" size="medium">
                Adicionar atributo
                <i className="fa-regular fa-plus fa-1x" />
              </Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <EditOptionForm />
            </Dialog.Content>
          </Dialog>
        </div>
      )}
    </div>
  );
};
