export enum TransactionsStatus {
  REQUESTED = 'REQUESTED',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  CONFIRMED = 'CONFIRMED',
}

export const viewTransactionsStatus = {
  REQUESTED: 'REQUESTED',
  CANCELLED: 'Cancelado',
  PAID: 'Pago',
  FAILED: 'Falha',
  REFUNDED: 'Extornado',
  CONFIRMED: 'Confirmado',
};

export enum TransactionType {
  CARD = 'CARD',
  PIX = 'PIX',
  BOLETO = 'BOLETO',
  FEE = 'FEE',
  P2P = 'P2P',
}
