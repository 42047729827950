import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Column, Grid, Row, Tag, Typography } from '@portao3-web/ui';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { Alert } from '../../../alerts';
import { BankSlipPaymentVoucherProps } from './BankSlipPaymentVoucher.interfaces';

export const BankSlipPaymentVoucher = ({
  data,
  customFields,
  showAlert = false,
}: BankSlipPaymentVoucherProps) => {
  const { t } = useTranslation();

  return (
    <>
      {showAlert && (
        <Alert status="success">
          <Typography tag="p" weight="p2" color="var(--product-success-s700)">
            {t('payment-voucher.bank-slip-success')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n100)">
            {t('payment-voucher.bank-slip-success-message', {
              value: calcCurrencyValue(data.txnUpdatedAmount, 'BRL'),
              destination: data.payee,
              date: dayjs(data.createdAt).utc(true).format('DD/MM/YYYY'),
              time: dayjs(data.createdAt).utc(true).format('HH:mm'),
            })}
          </Typography>
        </Alert>
      )}
      <div>
        <Grid className="paymentVoucher_info">
          <Row>
            <Column lg={12} md={12} sm={12}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
              >
                {t('general.barcode')}
              </Typography>
            </Column>
            <Column lg={12} md={12} sm={12}>
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                {data.barcode}
              </Typography>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.due-date')}
                </Typography>

                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {dayjs(data.dueDate).format('DD/MM/YYYY')}
                </Typography>
              </div>
            </Column>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.confirmation-date')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {dayjs(data.confirmedAt).format('DD/MM/YYYY')}
                </Typography>
              </div>
            </Column>
          </Row>
          <Row>
            <Column lg={12} md={12} sm={12}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
              >
                {t('general.bank')}
              </Typography>
            </Column>
            <Column lg={12} md={12} sm={12}>
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                {data.payee}
              </Typography>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.original-value')}
                </Typography>

                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {calcCurrencyValue(data.txnOriginalAmount, 'BRL')}
                </Typography>
              </div>
            </Column>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('general.discounts')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {calcCurrencyValue(data.txnDiscountAmount, 'BRL')}
                </Typography>
              </div>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('general.interest')}
                </Typography>

                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {calcCurrencyValue(data.txnInterestAmount, 'BRL')}
                </Typography>
              </div>
            </Column>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('general.fine')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {calcCurrencyValue(data.txnFineAmount, 'BRL')}
                </Typography>
              </div>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.max-value')}
                </Typography>

                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {calcCurrencyValue(data.txnMaxAmount, 'BRL')}
                </Typography>
              </div>
            </Column>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.min-value')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {calcCurrencyValue(data.txnMinAmount, 'BRL')}
                </Typography>
              </div>
            </Column>
          </Row>

          <Row>
            <Column lg={12} md={12} sm={12}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
              >
                {t('payment-voucher.receivable')}
              </Typography>
            </Column>
            <Column lg={12} md={12} sm={12}>
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                {calcCurrencyValue(data.txnUpdatedAmount, 'BRL')}
              </Typography>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.payee-name')}
                </Typography>

                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {data.payee}
                </Typography>
              </div>
            </Column>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.payee-document')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {data.payeeDocument}
                </Typography>
              </div>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.payer-name')}
                </Typography>

                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {data.payer}
                </Typography>
              </div>
            </Column>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.payer-document')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {data.payerDocument}
                </Typography>
              </div>
            </Column>
          </Row>
          {customFields &&
            customFields.map((elem, index) => (
              <Row key={index}>
                <Column lg={12} md={12} sm={12}>
                  <div>
                    <Typography
                      tag="p"
                      weight="p3"
                      color="var(--product-neutral-n80)"
                    >
                      {elem.label}
                    </Typography>

                    {elem.values.length > 1 ? (
                      <div className="bank-slip-custom-fields">
                        {elem.values.map((value, index) => {
                          return (
                            <Tag
                              key={index}
                              text={value}
                              textColor="var(--product-neutral-n500)"
                              showCloseButton={false}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <Typography
                        key={index}
                        tag="p"
                        weight="p2"
                        color="var(--product-neutral-n500)"
                      >
                        {elem.values[0]}
                      </Typography>
                    )}
                  </div>
                </Column>
              </Row>
            ))}
        </Grid>
      </div>
    </>
  );
};
