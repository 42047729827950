import { AxiosError } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Alert,
  CUSTOM_FIELDS_MAPPER,
  ContextualError,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { organization } from '@constants/endpoints';
import {
  CustomFieldLocation,
  CustomFieldType,
} from '@interfaces/CustomFields.interfaces';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { User } from '@interfaces/User.interfaces';
import {
  CreateWallet,
  WalletRole,
  WalletType,
} from '@interfaces/Wallets.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import {
  Autocomplete,
  Button,
  ErrorMessage,
  Input,
  Spinner,
  Toastr,
  Toggle,
  Typography,
} from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { useCustomFieldsQuery } from '@services/customFields/hooks';
import { useInfiniteUserListQuery } from '@services/user/hooks';
import { useUpdateWalletMutation } from '@services/wallet/hooks';
import { useCreateWalletMutation } from '@services/wallet/hooks/useCreateWalletMutation';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { FormData } from '../../DrawerEditWallet.interfaces';
import '../../DrawerEditWallet.styles.scss';
import { UserAccessCard } from '../userAccessCard/UserAccessCard';
import { GeneralProps } from './General.interface';

export const General = ({
  wallet,
  setHasConfirmationModal,
  setOpenConfirmationModal,
}: GeneralProps) => {
  const user: User = JSON.parse(
    localStorage.getItem('user') ?? JSON.stringify('')
  );

  const { isAdmin } = useUser();

  const { closeDrawer } = useDrawer();

  const formRef = useRef<HTMLFormElement>(null);

  const [userInput, setUserInput] = useState('');
  const [query, setQuery] = useState<string>('');
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [traceId, setTraceId] = useState<string>('');

  const location = useLocation();
  const isGeneralWallet = location.pathname.includes('/geral');

  const { mutate: updateWallet } = useUpdateWalletMutation();
  const { mutate: createWallet } = useCreateWalletMutation();

  const {
    data: userList,
    fetchStatus: fetchUserStatus,
    isFetching: isFetchingUsers,
    fetchNextPage: fetchMoreUsers,
    isFetchingNextPage: isFetchingMoreUsers,
  } = useInfiniteUserListQuery({ organization: organization(), query });

  const { data: customFields, error: customFieldsError } = useCustomFieldsQuery(
    {
      organization: localStorage.getItem('organization') || '',
      location: CustomFieldLocation.WALLET,
    }
  );

  const walletAccesses = wallet?.shared.map((user) => ({
    id: user.id,
    role: user.role,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  }));

  const form = useForm<FormData>({
    mode: 'onTouched',
    defaultValues: {
      pix: wallet?.settings?.payment?.pix ?? true,
      bankSlip: wallet?.settings?.payment?.bankSlip ?? true,
      card: wallet?.settings?.payment?.card ?? true,
      name: wallet?.name ?? '',
      users: walletAccesses ?? [
        {
          role: WalletRole.OWNER,
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        },
      ],
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = form;

  const watchAll = watch();

  const isOwner =
    isAdmin ||
    (wallet
      ? !!wallet?.shared.find(
          (walletUser) =>
            walletUser.id === user.id && walletUser.role === WalletRole.OWNER
        )
      : true);

  const isPersonal = wallet?.type === WalletType.PERSONAL;

  const handleSetUserInput = (value: string) => {
    setUserInput(value);
  };

  const addUser = (value: string) => {
    const newUser = userList?.find((user) => user.id === value);
    const userAlreadyAdded = watchAll.users.find(
      (user) => user.id === newUser?.id
    );

    if (newUser && !userAlreadyAdded) {
      setValue('users', [
        ...watchAll.users,
        {
          id: newUser?.id,
          role: WalletRole.USER,
          firstName: newUser?.firstName,
          lastName: newUser?.lastName,
          email: newUser?.email,
        },
      ]);
    }
  };

  const handleSetAccess = (id: string, role: WalletRole) => {
    const userIndex = watchAll.users.findIndex((user) => user.id === id);
    const updatedUsers = [...watchAll.users];
    updatedUsers[userIndex] = { ...updatedUsers[userIndex], role: role };
    setValue('users', updatedUsers);
  };

  const handleRemoveAccess = (id: string) => {
    const userIndex = watchAll.users.findIndex((user) => user.id === id);
    const updatedUsers = [...watchAll.users];
    updatedUsers.splice(userIndex, 1);
    setValue('users', updatedUsers);
  };

  const handleQueryUsers = async (value?: string) => {
    setQuery(value ?? '');
  };

  const submitText = () => {
    return wallet ? 'Salvar' : 'Concluir';
  };

  const submitForm = async (data: FormData) => {
    try {
      if (!wallet && !isGeneralWallet) {
        eventsHandler.submitButtonWalletCreationAdminWallets();
      }

      if (wallet && isGeneralWallet) {
        eventsHandler.submitButtonWalletCreationWallet();
      }

      setIsCreatingWallet(true);

      const shared = data.users.map((user) => ({
        id: user.id,
        role: user.role,
      }));

      const formattedCustomFields = data.customFields
        ? Object.entries(data.customFields).map(([key, value]) => {
            const field = customFields?.items?.find(
              (field) => field.identifier === key
            );

            const getMultiSelectValue = () => {
              if (value.length < 1) return [];
              if (typeof value === 'string') return [value];
              return value;
            };

            const getFieldValues = () => {
              const result = value;
              if (field?.type === CustomFieldType.NUMBER) {
                return [value.replace(/\D/g, '')];
              }
              return result ? [result] : [];
            };

            return {
              id: field?.id ?? '',
              label: field?.label ?? '',
              values:
                field?.type === CustomFieldType.MULTI_SELECT ||
                field?.type === CustomFieldType.CUSTOMER
                  ? getMultiSelectValue()
                  : field?.type === CustomFieldType.DATE
                    ? dayjs(value).toISOString()
                    : getFieldValues(),
              identifier: key,
              version: field?.version ?? 1,
            };
          })
        : [];

      const payload: CreateWallet = {
        name: data.name,
        type: WalletType.SHARED,
        settings: {
          payment: {
            bankSlip: data.bankSlip,
            card: data.card,
            pix: data.pix,
          },
        },
        shared: shared,
        customFields: formattedCustomFields,
      };

      if (wallet) {
        updateWallet(
          { walletId: wallet.id, payload },
          {
            onSuccess: (data) => {
              Toastr.success('Sua carteira foi atualizada com sucesso');

              customerEvents.walletUpdated({
                id: data.id,
                name: data.name,
                users: data.shared.length,
                bank_slip: data.settings.payment?.bankSlip ?? false,
                cards: data.settings.payment?.card ?? false,
                pix: data.settings.payment?.pix ?? false,
              });
              closeDrawer();
            },
          }
        );
      } else {
        createWallet(payload, {
          onSuccess: (data) => {
            customerEvents.walletCreated({
              bank_slip: data.settings.payment?.bankSlip ?? false,
              cards: data.settings.payment?.card ?? false,
              pix: data.settings.payment?.pix ?? false,
              name: data.name,
              users: data.shared.length,
            });
            Toastr.success('Sua carteira foi criada com sucesso');
            closeDrawer();
          },
        });
      }
    } catch (error) {
      const { response } = error as AxiosError;
      const errorInfo = response?.data as ErrorRequest;
      setTraceId(errorInfo?.traceId);
      const messages = (key: number) => {
        const errors: { [key: number]: string } = {
          400: 'Erro 400: Dados inválidos. Por favor, revise e tente novamente.',
          403: 'Erro 403: Acesso negado. Verifique suas permissões ou contate o suporte.',
        };

        return (
          errors[key] ??
          'Erro 500: Falha no servidor. Estamos trabalhando para resolver. Tente novamente mais tarde.'
        );
      };

      if (formRef.current?.parentElement?.scrollTo) {
        formRef.current.parentElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }

      setAlertMessage(messages(response?.status ?? 500));
      setIsCreatingWallet(false);
    }
  };

  const onTrackUserWalletPix = (isSelected: boolean) => {
    if (!isGeneralWallet) {
      if (isSelected) {
        eventsHandler.disableToggleWalletPixAdminWallets();
      } else {
        eventsHandler.enableToggleWalletPixAdminWallets();
      }
    }

    if (isGeneralWallet) {
      if (isSelected) {
        eventsHandler.disableToggleWalletPixWallet();
      } else {
        eventsHandler.enableToggleWalletPixWallet();
      }
    }
  };

  const onTrackUserWalletBoleto = (isSelected: boolean) => {
    if (!isGeneralWallet) {
      if (isSelected) {
        eventsHandler.enableToggleWalletBoletoAdminWallets();
      } else {
        eventsHandler.disableToggleWalletBoletoAdminWallets();
      }
    }

    if (isGeneralWallet) {
      if (isSelected) {
        eventsHandler.enableToggleWalletBoletoWallet();
      } else {
        eventsHandler.disableToggleWalletBoletoWallet();
      }
    }
  };

  const onTrackUserWalletCard = (isSelected: boolean) => {
    if (!isGeneralWallet) {
      if (isSelected) {
        eventsHandler.enableToggleWalletCardAdminWallets();
      } else {
        eventsHandler.disableToggleWalletCardAdminWallets();
      }
    }

    if (isGeneralWallet) {
      if (isSelected) {
        eventsHandler.enableToggleWalletCardWallet();
      } else {
        eventsHandler.disableToggleWalletCardWallet();
      }
    }
  };

  const autocompleteUserList = useMemo(() => {
    const formatedUsers: { [key: string]: string } = {};

    const filteredUserList = userList?.filter((user) =>
      watchAll.users.every((u) => u.id !== user.id)
    );

    filteredUserList?.forEach(
      ({ id, firstName, lastName }) =>
        (formatedUsers[id] = `${firstName} ${lastName}`)
    );

    return formatedUsers;
  }, [watchAll.users, userList]);

  useEffect(() => {
    handleQueryUsers();
  }, []);

  useEffect(() => {
    setHasConfirmationModal(isDirty);
  }, [isDirty, setHasConfirmationModal]);

  return (
    <form
      ref={formRef}
      className="new-wallet"
      onSubmit={handleSubmit(submitForm)}
    >
      {alertMessage && (
        <ContextualError error={{ message: alertMessage, traceId }} />
      )}
      <SidebarSection
        numberSection={1}
        titleSection="Identificação"
        subTitleSection="Defina o nome da sua carteira"
      >
        <Input
          type="text"
          placeholder="Ex: Marketing"
          label="Nome"
          id="name"
          name="name"
          data-testid="name"
          register={register}
          error={!!errors['name']}
          validationSchema={{
            required: 'O nome da carteira é obrigatória',
            pattern: {
              value: /^[a-zA-Z0-9 ]*$/,
              message: 'Caracteres especiais não são permitidos',
            },
          }}
          required
          disabled={!isOwner || isPersonal}
        >
          {errors['name']?.message && (
            <ErrorMessage message={errors['name'].message.toString()} />
          )}
        </Input>
      </SidebarSection>
      <SidebarSection
        numberSection={2}
        titleSection="Permissões"
        subTitleSection="Defina as regras de utilização da carteira"
      >
        <div className="new-wallet_toggle-container mb-4">
          <Toggle
            isSelected={watchAll.pix}
            onChange={(isSelected) => {
              onTrackUserWalletPix(isSelected);
              setValue('pix', isSelected);
            }}
            data-testid="pix"
            isDisabled={!isOwner}
            label="Pix"
            description="Possibilita os usuários realizarem pagamentos por Pix."
          />
          <Toggle
            isSelected={watchAll.bankSlip}
            onChange={(isSelected) => {
              onTrackUserWalletBoleto(isSelected);
              setValue('bankSlip', isSelected);
            }}
            data-testid="bankSlip"
            isDisabled={!isOwner}
            label="Boleto"
            description="Possibilita os usuários realizarem pagamentos por boleto."
          />
          <Toggle
            isSelected={watchAll.card}
            onChange={(isSelected) => {
              onTrackUserWalletCard(isSelected);
              setValue('card', isSelected);
            }}
            data-testid="card"
            isDisabled={!isOwner}
            label="Cartões"
            description="Possibilita os usuários criarem cartões"
          />
        </div>
      </SidebarSection>
      <SidebarSection
        numberSection={3}
        titleSection="Compartilhamento"
        subTitleSection="Selecione quem pode acessar esta carteira"
      >
        <div className="new-wallet_access mb-4">
          <Autocomplete
            label="Usuário"
            value={userInput}
            setValue={handleSetUserInput}
            placeholder="Busque por nome ou email"
            items={autocompleteUserList}
            selectItem={addUser}
            queryFn={handleQueryUsers}
            isFetching={isFetchingUsers}
            isFetchingNextPage={isFetchingMoreUsers}
            disabled={!isOwner || isPersonal}
            delay={500}
            fetchNextPage={
              fetchUserStatus === 'fetching' ? undefined : fetchMoreUsers
            }
            multiselect
          />
          <Typography tag="p" weight="p2" color="var(--product-neutral-n70)">
            Pessoas com acesso:
          </Typography>
          <div className="new-wallet_access_list">
            {watchAll.users.map((walletUser) => {
              const ownerCount = watchAll.users.filter(
                (user) => user.role === WalletRole.OWNER
              ).length;

              const isDisabled =
                !isOwner ||
                (ownerCount < 2 && walletUser.role === WalletRole.OWNER) ||
                watchAll.users.length < 2;

              return (
                <UserAccessCard
                  user={walletUser}
                  removeItem={handleRemoveAccess}
                  key={walletUser.id}
                  disabled={isDisabled}
                  selectItem={(role: string) => {
                    if (!isGeneralWallet) {
                      eventsHandler.viewOptionsWalletPermissionAdminWallets();
                    } else {
                      eventsHandler.viewOptionsWalletPermissionWallet();
                    }
                    handleSetAccess(walletUser.id, role as WalletRole);
                  }}
                />
              );
            })}
          </div>
        </div>
      </SidebarSection>

      {customFields?.items && customFields?.items.length > 0 && (
        <SidebarSection
          numberSection={4}
          titleSection="Informações complementares"
          subTitleSection=""
        >
          {customFieldsError ? (
            <Alert status="error">
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                Houve um problema ao carregar o campo customizável. Por favor,
                atualize a página e tente novamente.
              </Typography>
            </Alert>
          ) : (
            customFields?.items
              ?.slice()
              .reverse()
              .map((field) => {
                const Component =
                  CUSTOM_FIELDS_MAPPER[
                    field.type as keyof typeof CUSTOM_FIELDS_MAPPER
                  ];

                return Component ? (
                  <Component
                    form={form}
                    fieldSchema={field}
                    customFields={wallet?.customFields || []}
                    key={field.id}
                  />
                ) : null;
              })
          )}
        </SidebarSection>
      )}

      <SidebarFooter>
        <Button
          type="button"
          size="large"
          variant="tertiary"
          onClick={() => {
            setOpenConfirmationModal((prev) => !prev);
          }}
        >
          Fechar
        </Button>
        <Button
          type="submit"
          size="large"
          variant="primary"
          disabled={isCreatingWallet || !isOwner}
          data-testid="tab-geral"
        >
          {isCreatingWallet ? <Spinner variant="secondary" /> : submitText()}
        </Button>
      </SidebarFooter>
    </form>
  );
};
