import emptyImgCard from '@assets/img/empty-card.svg';
import emptyImgCustomFiled from '@assets/img/empty-state-custom-fields.svg';
import emptyInvoiceImg from '@assets/img/empty-state-invoice.svg';
import emptyWallet from '@assets/img/empty-wallet.svg';
import errorServiceInvoice from '@assets/img/error-service-invoice.svg';
import errorServiceImg from '@assets/img/error-service.svg';
import emptyTransactionsImg from '@assets/img/no-transactions.svg';
import emptyCollaboratorsImg from '@assets/img/user-not-found.svg';

export const emptyCards = {
  title: 'Nenhum cartão encontrado',
  subtitle: 'Você não possui cartão cadastrado.',
  image: emptyImgCard,
};

export const emptyCardTransactions = {
  title: 'Nenhuma transação encontrada',
  subtitle: 'Você não possui transação com este cartão.',
  image: emptyTransactionsImg,
};

export const emptyStatementTransactions = {
  title: 'Nenhuma transação encontrada',
  subtitle: 'Você não possui transação no período selecionado.',
  image: emptyTransactionsImg,
};

export const emptyStatementTransactionsWithFilter = {
  title: 'Nenhuma transação encontrada',
  subtitle: 'Você não possui transação com os filtros selecionados.',
  image: emptyTransactionsImg,
};

export const emptyInformation = {
  title: 'Nenhum detalhe por período encontrado',
  subtitle: 'Você não possui informações no período selecionado.',
  image: emptyTransactionsImg,
};

export const emptyPeriodTransactions = {
  title: 'Nenhuma transação por período encontrada',
  subtitle: 'Você não possui transações no período selecionado.',
  image: emptyTransactionsImg,
};

export const emptyCustomFields = {
  title: 'Nenhum campo customizável encontrado',
  subtitle: 'Você ainda não criou um campo customizável. Configure agora!',
  image: emptyImgCustomFiled,
};

export const errorState = {
  title: 'Oops! Algo deu errado',
  subtitle:
    'O serviço não está disponível no momento. Por favor, tente novamente mais tarde.',
  image: errorServiceImg,
};

export const emptyCollaborators = {
  title: 'Nenhum colaborador encontrado.',
  subtitle: 'Você não possui nenhum colaborador.',
  image: emptyCollaboratorsImg,
};

export const emptyCollaboratorsWithFilters = {
  title: 'Nenhum colaborador encontrado.',
  subtitle: 'Você não possui nenhum colaborador com os filtros selecionados.',
  image: emptyCollaboratorsImg,
};

export const emptyCustomers = {
  title: 'Nenhum cliente encontrado',
  subtitle: 'Você não possui nenhum cliente cadastrado.',
  image: emptyCollaboratorsImg,
};

export const noCustomersFound = {
  title: 'Nenhum cliente encontrado',
  subtitle:
    'Ops, não encontramos nenhum cliente de acordo com sua busca. Por favor, verifique o nome e tente novamente',
  image: emptyCollaboratorsImg,
};

export const emptyBillings = {
  title: 'Nenhuma cobrança encontrada',
  subtitle: 'Você não possui nenhuma cobrança no período selecionado.',
  image: emptyTransactionsImg,
};

export const emptyInvoices = {
  title: 'Nenhuma fatura disponível',
  subtitle:
    'Sua conta ainda não possui nenhuma fatura disponível para pagamento.',
  image: emptyInvoiceImg,
};

export const emptyInvoicesWithFilter = {
  title: 'Nenhuma fatura disponível',
  subtitle:
    'Sua conta ainda não possui nenhuma fatura disponível para pagamento com os filtros selecionados.',
  image: emptyInvoiceImg,
};

export const errorInvoices = {
  title: 'Serviço Indisponível',
  subtitle:
    'Ops! Ocorreu um erro ao tentar processar suas faturas. Por favor, tente novamente.',
  image: errorServiceInvoice,
};

export const emptyCustomersBillingDeals = {
  title: 'Nenhum acordo encontrado.',
  subtitle: 'Você não possui nenhum acordo cadastrado no período selecionado.',
  image: emptyCollaboratorsImg,
};

export const emptyCustomersBillingDealsWithFilter = {
  title: 'Nenhum acordo encontrado.',
  subtitle: 'Você não possui nenhum acordo com os filtros selecionados.',
  image: emptyCollaboratorsImg,
};

export const errorCustomersBillingDeals = {
  title: 'Serviço Indisponível',
  subtitle:
    'Ops! Ocorreu um erro ao tentar processar seus acordos. Por favor, tente novamente.',
  image: errorServiceInvoice,
};

export const emptyWalletsList = {
  title: 'Nenhuma carteira encontrada.',
  subtitle: 'Você não possui nenhuma carteira cadastrada.',
  image: emptyWallet,
};

export const emptyWalletsListWithFilter = {
  title: 'Nenhuma carteira encontrada.',
  subtitle: 'Você não possui nenhuma carteira com os filtros selecionados.',
  image: emptyWallet,
};
