import { adminCardsEvents } from './adminCardsEvents';
import { adminPreferencesEvents } from './adminPreferencesEvents';
import { adminStatementEvents } from './adminStatementEvents';
import { adminUsersEvents } from './adminUsersEvents';
import { adminAdminWalletStatementEvents } from './adminWalletStatementEvents';
import { adminWalletsEvents } from './adminWalletsEvents';
import { cardsWalletEvents } from './cardsWalletEvents';
import { memberGetMemberEvents } from './memberGetMemberEvents';
import { navbarGlobalEvents } from './navbarGlobalEvents';
import { topbarGlobalEvents } from './topbarGlobalEvents';
import { walletCompanyEvents } from './walletCompanyEvents';
import { walletEvents } from './walletEvents';
import { walletStatementEvents } from './walletStatementEvents';

export const eventsHandler = {
  ...adminCardsEvents,
  ...adminPreferencesEvents,
  ...adminStatementEvents,
  ...adminUsersEvents,
  ...adminWalletsEvents,
  ...cardsWalletEvents,
  ...navbarGlobalEvents,
  ...topbarGlobalEvents,
  ...walletCompanyEvents,
  ...walletEvents,
  ...walletStatementEvents,
  ...adminAdminWalletStatementEvents,
  ...memberGetMemberEvents,
};
