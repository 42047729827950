import { brazilianRealCurrency } from './formatCurrencyNumber';

export const cpfMask = (value: string) => {
  const masked = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return masked;
};

export const cnpjMask = (value: string | undefined) => {
  if (!value) return '';

  const masked = value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  );
  return masked;
};

export const cepMask = (value: string) => {
  const masked = value.replace(/(\d{5})(\d{3})/, '$1-$2');
  return masked;
};

export const phoneMask = (value: string | undefined) => {
  if (!value) return '';

  const cleanedValue = value.replace(/[^\d]/g, '');

  if (cleanedValue.length <= 10) {
    return cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  } else {
    return cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
};

// TODO improve to library or use a better mask
export const phoneDDIMask = (value: string) => {
  return value.replace(/[^+\d]/g, '');
};

export const moneyMask = (value: string | number) => {
  if (typeof value === 'number') {
    return brazilianRealCurrency.format(value / 100);
  }

  const money = Number(value.replace(/\D/g, '')) / 100;
  return brazilianRealCurrency.format(money);
};

export const removeMoneyMask = (value: string) => {
  const replacedValue = +value.replace(/\D/g, '');

  return replacedValue.toString();
};

export const percentMask = (value: string) => {
  const percent = +value.toString().replace(/\D/g, '');

  if (+percent > 10000) {
    return `100,00`;
  }

  return percent
    .toString()
    .padStart(3, '0')
    .replace(/^(\d+)(\d{2})$/, '$1,$2');
};

export const onlyNumbersMask = (value: string) => {
  return value.replace(/\D/g, '');
};
