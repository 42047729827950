import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { CardItem } from '@interfaces/Card.interfaces';
import { CardDetailsResponse } from '@interfaces/CardDetails.interface';
import api from '@services/api';

export const cardInformation = async ({
  cardId,
  walletId,
  pin,
}: {
  cardId: string;
  walletId: string;
  pin?: string;
}): Promise<CardDetailsResponse> => {
  const isWalletId = walletId || 'default';
  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWalletId}/cards/${cardId}/details`,
    { headers: { pin: pin || undefined } }
  );
  const { data } = response;

  return data;
};

export const updateCardName = async (
  name: string,
  cardId: string,
  walletId?: string
): Promise<CardItem> => {
  const isWalletId = walletId || 'default';

  const response = await api.put(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWalletId}/cards/${cardId}`,
    { name }
  );

  const { data } = response;

  return data;
};
