import { createSentryClickBreadcrumb } from '@lib/sentry';

enum ADMIN_STATEMENT_EVENT {
  VIEW_OPTIONS_EXPORT_ADMIN_STAGEMENT = 'view_options_export_adminStatement',
  SELECT_OPTION_TYPE_CSV_ADMIN_STAGEMENT = 'select_option_typeCsv_adminStatement',
  SELECT_OPTION_TYPE_XLSX_ADMIN_STAGEMENT = 'select_option_typeXlsx_adminStatement',
  SELECT_OPTION_TYPE_OFX_ADMIN_STAGEMENT = 'select_option_typeOfx_adminStatement',
  SELECT_DATE_PICKER_RANGE_ADMIN_STAGEMENT = 'select_datepicker_range_adminStatement',
  SHOW_BUTTON_FUNDS_IN_ADMIN_STAGEMENT = 'show_button_fundsIn_adminStatement',
  HIDE_BUTTON_FUNDS_IN_ADMIN_STAGEMENT = 'hide_button_fundsIn_adminStatement',
  SHOW_BUTTON_FUNDS_OUT_ADMIN_STAGEMENT = 'show_button_fundsOut_adminStatement',
  HIDE_BUTTON_FUNDS_OUT_ADMIN_STAGEMENT = 'hide_button_fundsOut_adminStatement',
  OPEN_DRAWER_TRANSACTION_DETAIL_ADMIN_STAGEMENT = 'open_drawer_transactionDetails_adminStatement',
  CLICK_BUTTON_LOAD_MORE_TRANSACTIONS_ADMIN_STAGEMENT = 'click_button_loadMoreTransactions_adminStatement',
  CLOSE_DRAWER_TRANSACTION_DETAIL_ADMIN_STAGEMENT = 'close_drawer_transactionDetails_adminStatement',
  CLICK_INPUT_CUSTOM_FIELDS_ADMIN_STAGEMENT = 'click_input_customFields_adminStatement',
  DRAG_UPLOAD_TRANSACTION_DETAILS_INVOICE_ADMIN_STAGEMENT = 'drag_upload_transactionDetailsInvoice_adminStatement',
  CLICK_UPLOAD_TRANSACTION_DETAILS_INVOICE_ADMIN_STAGEMENT = 'click_upload_transactionDetailsInvoice_adminStatement',
  SUBMIT_BUTTON_TRANSACTION_DETAILS_ADMIN_STAGEMENT = 'submit_button_transactionDetails_adminStatement',
}

export const adminStatementEvents = {
  viewOptionsExportAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.VIEW_OPTIONS_EXPORT_ADMIN_STAGEMENT
    );
  },
  selectOptionTypeCsvAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.SELECT_OPTION_TYPE_CSV_ADMIN_STAGEMENT
    );
  },
  selectOptionTypeXlsxAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.SELECT_OPTION_TYPE_XLSX_ADMIN_STAGEMENT
    );
  },
  selectOptionTypeOfxAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.SELECT_OPTION_TYPE_OFX_ADMIN_STAGEMENT
    );
  },
  selectDatePickerRangeAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.SELECT_DATE_PICKER_RANGE_ADMIN_STAGEMENT
    );
  },
  showButtonFundsInAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.SHOW_BUTTON_FUNDS_IN_ADMIN_STAGEMENT
    );
  },
  hideButtonFundsInAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.HIDE_BUTTON_FUNDS_IN_ADMIN_STAGEMENT
    );
  },
  showButtonFundsOutAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.SHOW_BUTTON_FUNDS_OUT_ADMIN_STAGEMENT
    );
  },
  hideButtonFundsOutAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.HIDE_BUTTON_FUNDS_OUT_ADMIN_STAGEMENT
    );
  },
  openDrawerTransactionDetailAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.OPEN_DRAWER_TRANSACTION_DETAIL_ADMIN_STAGEMENT
    );
  },
  clickButtonLoadMoreTransactionsAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.CLICK_BUTTON_LOAD_MORE_TRANSACTIONS_ADMIN_STAGEMENT
    );
  },
  closeDrawerTransactionDetailAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.CLOSE_DRAWER_TRANSACTION_DETAIL_ADMIN_STAGEMENT
    );
  },
  clickInputCustomFieldsAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.CLICK_INPUT_CUSTOM_FIELDS_ADMIN_STAGEMENT
    );
  },
  dragUploadTransactionDetailsInvoiceAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.DRAG_UPLOAD_TRANSACTION_DETAILS_INVOICE_ADMIN_STAGEMENT
    );
  },

  clickUploadTransactionDetailsInvoiceAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.CLICK_UPLOAD_TRANSACTION_DETAILS_INVOICE_ADMIN_STAGEMENT
    );
  },
  submitButtonTransactionDetailsAdminStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_STATEMENT_EVENT.SUBMIT_BUTTON_TRANSACTION_DETAILS_ADMIN_STAGEMENT
    );
  },
};
