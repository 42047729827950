import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { BatchPaymentStatusExtension, StatusTag } from '@components';
import { BatchPaymentStatus } from '@enums/BatchPayment.enum';
import { BatchPayment } from '@interfaces/BatchPayments.interfaces';
import { TableDate } from '@portao3-web/ui';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';

export const useBatchesColumns = (): ColumnDef<BatchPayment>[] => {
  const { t } = useTranslation();

  return [
    {
      accessorKey: 'createdAt',
      header: t('general.date'),
      cell: ({ cell }) => <TableDate date={cell.getValue<string>()} />,
    },
    {
      accessorKey: 'name',
      header: t('general.name'),
      meta: {
        width: 'auto',
      },
    },
    {
      accessorKey: 'status',
      header: t('general.status'),
      cell: ({ cell, row }) => {
        const { totalBatchAmount, totalExecutedAmount } = row.original;
        const status = cell.getValue<BatchPaymentStatus>();

        const partiallyPaid =
          totalExecutedAmount > 0 && totalExecutedAmount < totalBatchAmount;
        const paid = totalExecutedAmount === totalBatchAmount;

        return (
          <StatusTag
            status={
              paid
                ? BatchPaymentStatusExtension.PAID
                : partiallyPaid
                  ? BatchPaymentStatusExtension.PARTIALLY_PAID
                  : status
            }
            type="BATCH_PAYMENTS"
          />
        );
      },
    },
    {
      accessorKey: 'totalBatchAmount',
      header: t('general.total-amount'),
      cell: ({ cell }) => calcCurrencyValue(cell.getValue<number>(), 'BRL'),
    },
    {
      accessorKey: 'totalExecutedAmount',
      header: t('general.realized-amount'),
      cell: ({ getValue }) => {
        const payedAmount = getValue<number>();
        return (
          <span className="text-danger-500" style={{ font: 'inherit' }}>
            {calcCurrencyValue(payedAmount, 'BRL')}
          </span>
        );
      },
    },
  ];
};
