import { createSentryClickBreadcrumb } from '@lib/sentry';

enum ADMIN_WALLET_STATEMENT_EVENT {
  NAVIGATE_BREADCRUMB_WALLETS = 'navigate_breadcrumb_wallets_adminWalletStatement',
  VIEW_OPTIONS_EXPORT = 'view_options_export_adminWalletStatement',
  SELECT_OPTION_TYPE_CSV = 'select_option_typeCsv_adminWalletStatement',
  SELECT_OPTION_TYPE_XLSX = 'select_option_typeXlsx_adminWalletStatement',
  SELECT_OPTION_TYPE_OFX = 'select_option_typeOfx_adminWalletStatement',
  SELECT_DATEPICKER_RANGE = 'select_datepickerRange_adminWalletStatement',
  SHOW_BUTTON_FUNDS_IN = 'show_button_fundsIn_adminWalletStatement',
  HIDE_BUTTON_FUNDS_IN = 'hide_button_fundsIn_adminWalletStatement',
  SHOW_BUTTON_FUNDS_OUT = 'show_button_fundsOut_adminWalletStatement',
  HIDE_BUTTON_FUNDS_OUT = 'hide_button_fundsOut_adminWalletStatement',
  OPEN_DRAWER_TRANSACTION_DETAILS = 'open_drawer_transactionDetails_adminWalletStatement',
  CLICK_BUTTON_LOAD_MORE_TRANSACTIONS = 'click_button_loadMoreTransactions_adminWalletStatement',
  CLOSE_DRAWER_TRANSACTION_DETAILS = 'close_drawer_transactionDetails_adminWalletStatement',
  CLICK_INPUT_CUSTOM_FIELDS = 'click_input_customFields_adminWalletStatement',
  DRAG_UPLOAD_TRANSACTION_DETAILS = 'drag_upload_transactionDetails_adminWalletStatement',
  CLICK_UPLOAD_TRANSACTION_DETAILS = 'click_upload_transactionDetails_adminWalletStatement',
  SUBMIT_BUTTON_TRANSACTION_DETAILS = 'submit_button_transactionDetails_adminWalletStatement',
}

export const adminAdminWalletStatementEvents = {
  navigateBreadcrumbWalletsAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.NAVIGATE_BREADCRUMB_WALLETS
    );
  },
  viewOptionsExportAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.VIEW_OPTIONS_EXPORT
    );
  },
  selectOptionTypeCsvAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.SELECT_OPTION_TYPE_CSV
    );
  },
  selectOptionTypeXlsxAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.SELECT_OPTION_TYPE_XLSX
    );
  },
  selectOptionTypeOfxAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.SELECT_OPTION_TYPE_OFX
    );
  },
  selectDatepickerRangeAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.SELECT_DATEPICKER_RANGE
    );
  },
  showButtonFundsInAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.SHOW_BUTTON_FUNDS_IN
    );
  },
  hideButtonFundsInAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.HIDE_BUTTON_FUNDS_IN
    );
  },
  showButtonFundsOutAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.SHOW_BUTTON_FUNDS_OUT
    );
  },
  hideButtonFundsOutAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.HIDE_BUTTON_FUNDS_OUT
    );
  },
  openDrawerTransactionDetailsAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.OPEN_DRAWER_TRANSACTION_DETAILS
    );
  },
  clickButtonLoadMoreTransactionsAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.CLICK_BUTTON_LOAD_MORE_TRANSACTIONS
    );
  },
  closeDrawerTransactionDetailsAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.CLOSE_DRAWER_TRANSACTION_DETAILS
    );
  },
  clickInputCustomFieldsAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.CLICK_INPUT_CUSTOM_FIELDS
    );
  },
  dragUploadTransactionDetailsAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.DRAG_UPLOAD_TRANSACTION_DETAILS
    );
  },
  clickUploadTransactionDetailsAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.CLICK_UPLOAD_TRANSACTION_DETAILS
    );
  },
  submitButtonTransactionDetailsAdminWalletStatement: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLET_STATEMENT_EVENT.SUBMIT_BUTTON_TRANSACTION_DETAILS
    );
  },
};
