import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextualErrorType } from '@components';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { CreateUserPinPayload, createUserPin } from '../CreateUserPin';

export const useCreateUserPinMutation = () => {
  const [customError, setCustomError] = useState<ContextualErrorType>(null);
  const { t } = useTranslation();

  const mutation = useMutation<
    unknown,
    ApiError,
    CreateUserPinPayload,
    unknown
  >({
    mutationFn: (payload) => createUserPin(payload),
    onError: (error) => {
      setCustomError({
        message: t('error.default'),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
