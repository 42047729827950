import { eventsHandler } from '@lib';
import { Sidebar } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useState } from 'react';
import { WalletController } from './components/walletController/WalletController';

export const DrawerUpdateWallet = () => {
  const { closeDrawer } = useDrawer();

  const [hasModal, setHasModal] = useState<boolean>(false);

  const setModalOpen = (openModal: boolean) => {
    setHasModal(openModal);
  };

  const handleCloseDrawer = () => {
    eventsHandler.closeDrawerEditUserBalanceAdminUsers();
    closeDrawer();
  };

  return (
    <Sidebar
      title="Carteira do usuário"
      icon="fa-regular fa-wallet"
      hasModal={hasModal}
      open={true}
      onClose={handleCloseDrawer}
    >
      <WalletController
        setModalOpen={(value: boolean) => {
          setModalOpen(value);
        }}
        onClose={handleCloseDrawer}
      />
    </Sidebar>
  );
};
