import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { ContextualErrorType } from '@components';
import {
  BatchTransactionResponse,
  CreateBatchPayment,
} from '@interfaces/BatchPayments.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { createBatchPayment } from '../createBatchPayment';
import { useTranslation } from 'react-i18next';

export const useCreateBatchPaymentMutation = () => {
  const [customError, setCustomError] = useState<ContextualErrorType>(null);
  const { t } = useTranslation();

  const mutation = useMutation<
    BatchTransactionResponse,
    ApiError,
    CreateBatchPayment,
    unknown
  >({
    mutationFn: (payload: CreateBatchPayment) => createBatchPayment(payload),
    onError: (error) => {
      setCustomError({
        message: t('error.default'),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
