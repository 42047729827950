import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  ContextualError,
  ContextualErrorType,
  InDrawerAuthentication,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { useAuthentication } from '@hooks';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { Button, Skeleton, Typography } from '@portao3-web/ui';
import { useUser } from '@providers';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { Dict } from '../../interfaces/interfaces';
import { getPixDict } from '../../services';
import { AddKey } from '../addKey';
import { KeyCard } from '../keyCard';
import { PixKeyProps } from './ManagePixKey.interfaces';

export const ManagePixKey = ({ onBack, walletId }: PixKeyProps) => {
  const { t } = useTranslation();
  const { hasPin } = useUser();

  const [error, setError] = useState<ContextualErrorType>(null);
  const [dict, setDict] = useState<Dict | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    pin,
    isAuthentication,
    controller,
    setIsAuthentication,
    onAuthSuccess,
    onAuthError,
  } = useAuthentication();

  const isMounted = useRef(false);

  const getPixDictValue = async () => {
    try {
      setIsLoading(true);

      const data = await getPixDict({
        walletId,
        pin,
      });

      const activeDict = data.items.find((item) => item.status === 'ACTIVE');

      setDict(activeDict);
      onAuthSuccess();
      setError(null);
    } catch (error) {
      const errorService = error as ApiErrorPin;
      const errorInfo = errorService.response?.data as ErrorRequest;
      onAuthError(errorService);

      setError({
        message: t('error.default'),
        traceId: errorInfo?.traceId,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      if (hasPin) {
        setIsAuthentication(true);
      } else {
        getPixDictValue();
      }

      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMounted.current && (
        <>
          {!isAuthentication && (
            <>
              <div className="flex flex-col">
                <ContextualError error={error} />
                <Alert status="info">
                  <Typography
                    tag="p"
                    weight="p2"
                    color="var(--product-neutral-n500)"
                  >
                    {t('pix.drawer.key.limit-info')}
                  </Typography>
                </Alert>
                <Skeleton height={200} width={'94%'} isLoading={isLoading}>
                  {dict?.value ? (
                    <SidebarSection
                      titleSection={t('pix.drawer.key.use-title')}
                      subTitleSection={t('pix.drawer.key.use-subtitle')}
                      numberSection={1}
                    >
                      <KeyCard
                        pixKey={dict}
                        walletId={walletId}
                        setError={setError}
                        onSuccess={() => setDict(null)}
                      />
                    </SidebarSection>
                  ) : (
                    <AddKey
                      onCreate={setDict}
                      walletId={walletId}
                      setError={setError}
                    />
                  )}
                </Skeleton>
              </div>
              <SidebarFooter>
                <Button
                  type="button"
                  size="large"
                  variant="tertiary"
                  onClick={onBack}
                >
                  {t('button.back')}
                </Button>
                <Button type="button" size="large" variant="primary" disabled>
                  {t('button.finish')}
                </Button>
              </SidebarFooter>{' '}
            </>
          )}

          {isAuthentication && (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                getPixDictValue();
              }}
            >
              <InDrawerAuthentication
                isPending={isLoading}
                {...controller}
                onClose={() => {
                  onBack();
                  controller.onClose();
                }}
              />
            </form>
          )}
        </>
      )}
    </>
  );
};
