import { useMutation } from '@tanstack/react-query';

import { ContextualErrorType } from '@components';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadBilling, downloadInvoiceBankSlip } from '../downloadBilling';

interface DownloadBillingMutationProps {
  walletId: string;
  billingId: string;
}

interface DownloadInvoiceMutationProps {
  invoiceId: string;
  statementId?: string;
}

export const useDownloadBillingMutation = () => {
  return useMutation<unknown, ApiError, DownloadBillingMutationProps, unknown>({
    mutationFn: ({ walletId, billingId }: DownloadBillingMutationProps) =>
      downloadBilling(walletId, billingId),
  });
};

export const useDownloadInvoicesMutation = () => {
  const { t } = useTranslation();
  const [customError, setCustomError] = useState<ContextualErrorType>(null);

  const mutation = useMutation<
    unknown,
    ApiError,
    DownloadInvoiceMutationProps,
    unknown
  >({
    mutationFn: ({ invoiceId, statementId }: DownloadInvoiceMutationProps) =>
      downloadInvoiceBankSlip(invoiceId, statementId),
    onError: (error: ApiError) => {
      setCustomError({
        message: t('error.default'),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
