import { useAuthentication } from '@hooks';
import { CardDetailsResponse } from '@interfaces/CardDetails.interface';
import { customerEvents } from '@lib/customerIo';
import { DrawerAuthentication } from '@modules/drawer';
import {
  Button,
  Icon,
  MasterCard,
  Skeleton,
  Toastr,
  Typography,
} from '@portao3-web/ui';
import { useUser } from '@providers';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { cardInformation } from '@services/cards/CardDetailsServices';
import { formatCardNumber } from '@utils/formatCardNumber';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WalletCardDetailsProps } from './WalletCardDetails.interface';
import './WalletCardDetails.styles.scss';

export const WalletCardDetails = ({
  cardId,
  cardNumber,
  cardType,
  cardName,
  walletId,
  trackUserAction,
}: WalletCardDetailsProps) => {
  const { t } = useTranslation();
  const { hasPin } = useUser();

  const [loadingCard, setLoadingCard] = useState(false);
  const [showDetailsCard, setShowDetailsCard] = useState(false);
  const [cardDetails, setCardDetails] = useState<CardDetailsResponse>();

  const { pin, controller, authSubmit, onAuthSuccess, onAuthError } =
    useAuthentication();

  useEffect(() => {
    setShowDetailsCard(false);
    setCardDetails(undefined);
  }, [cardId]);

  const getCardDetails = async (cardId: number) => {
    showDetailsCard ? trackUserAction?.hide() : trackUserAction?.show();

    if (cardDetails === undefined) {
      return authSubmit(async () => {
        try {
          setLoadingCard(true);

          const cardDetails = await cardInformation({
            cardId: cardId.toString(),
            walletId,
            pin,
          });

          customerEvents.cardDetails({
            id: cardId.toString(),
            name: cardDetails?.name,
            type: cardType,
          });
          setCardDetails(cardDetails);
          setShowDetailsCard(true);
          setLoadingCard(false);
          onAuthSuccess();

          setShowDetailsCard(!showDetailsCard);
        } catch (error) {
          setShowDetailsCard(false);
          setLoadingCard(false);
          onAuthError(error as ApiErrorPin);

          if (!hasPin) {
            Toastr.error('Erro ao carregar dados!');
          }
        }
      });
    }
    setShowDetailsCard(!showDetailsCard);
  };

  return (
    <div className="walletCardDetails">
      <div className="walletCardDetails_info">
        {cardType !== 'EMBOSSED' && (
          <Skeleton width={'20%'} padding={12} isLoading={loadingCard}>
            <Button
              size="small"
              variant="secondary"
              onClick={() => getCardDetails(cardId)}
              data-testid="showDetailsCard"
            >
              {showDetailsCard ? 'Ocultar dados' : 'Exibir dados'}
              <Icon size="large">
                <i
                  className={`fa-regular ${
                    showDetailsCard ? 'fa-eye-slash' : 'fa-eye eye'
                  }`}
                ></i>
              </Icon>
            </Button>
          </Skeleton>
        )}
        <div className="walletCardDetails_logo">
          <div className="walletCardDetails_logo_type_card">
            <Skeleton width={'20%'} padding={12} isLoading={loadingCard}>
              <Typography tag="p" color="var(--product-neutral-n80)">
                {t(`general.${cardType.toLowerCase()}`, '')}
              </Typography>
            </Skeleton>
          </div>

          <MasterCard />
        </div>
      </div>

      <div className="walletCardDetails_user_date">
        <div className="walletCardDetails_name">
          <div>
            <Skeleton width={'60%'} padding={9} isLoading={loadingCard}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n100)"
              >
                Nome do cartão
              </Typography>
            </Skeleton>
            <Skeleton width={'60%'} padding={12} isLoading={loadingCard}>
              <Typography
                tag="p"
                weight="p1"
                color="var(--product-neutral-n700)"
                className="walletCardDetails_name_overflow"
                title={cardName}
              >
                {cardName}
              </Typography>
            </Skeleton>
          </div>

          <div>
            <Skeleton width={'60%'} padding={9} isLoading={loadingCard}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n100)"
              >
                Data de Expiração
              </Typography>
            </Skeleton>
            <Skeleton width={'60%'} padding={12} isLoading={loadingCard}>
              <Typography
                tag="p"
                weight="p1"
                color="var(--product-neutral-n700)"
              >
                {cardDetails && showDetailsCard
                  ? cardDetails.expiryDate
                  : '**/**'}
              </Typography>
            </Skeleton>
          </div>
        </div>

        <div className="walletCardDetails_name">
          <div>
            <Skeleton width={'60%'} padding={9} isLoading={loadingCard}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n100)"
              >
                Nº do cartão
              </Typography>
            </Skeleton>
            <Skeleton width={'60%'} padding={12} isLoading={loadingCard}>
              <Typography
                tag="p"
                weight="p1"
                color="var(--product-neutral-n700)"
              >
                {cardDetails && showDetailsCard
                  ? formatCardNumber(cardDetails.pan)
                  : `**** **** **** ${cardNumber}`}
              </Typography>
            </Skeleton>
          </div>
          <div>
            <Skeleton width={'60%'} padding={9} isLoading={loadingCard}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n100)"
              >
                Código de segurança
              </Typography>
            </Skeleton>
            <Skeleton width={'60%'} padding={12} isLoading={loadingCard}>
              <Typography
                tag="p"
                weight="p1"
                color="var(--product-neutral-n700)"
              >
                {cardDetails && showDetailsCard ? cardDetails.cvv : '***'}
              </Typography>
            </Skeleton>
          </div>
        </div>
      </div>

      <DrawerAuthentication
        {...controller}
        onSubmit={() => getCardDetails(cardId)}
        isPending={loadingCard}
      />
    </div>
  );
};
