import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SidebarSection } from '@components';
import { PixKeyType } from '@interfaces/Pix.interface';
import { customerEvents, eventsHandler } from '@lib';
import {
  Button,
  ErrorMessage,
  Input,
  Select,
  Spinner,
  Typography,
} from '@portao3-web/ui';
import { useUser } from '@providers';
import { useLocation } from 'react-router-dom';
import { PIX_KEY_MASKS, PIX_KEY_VALIDATIONS } from '../../constants';
import { useCreatePixDictMutation } from '../../services/useCreatePixDictMutation';
import { AddKeyProps, PixKeyFormProps } from './AddKey.interfaces';
import './AddKey.styles.scss';

export const AddKey = ({ walletId, setError, onCreate }: AddKeyProps) => {
  const { t } = useTranslation();
  const { organizations } = useUser();
  const { pathname } = useLocation();

  const [step, setStep] = useState<'add' | 'config'>('add');

  const form = useForm<PixKeyFormProps>();
  const keyType = form.watch('type');

  const { mutateAsync: createKey, isPending: creatingKey } =
    useCreatePixDictMutation(setError);

  const handleFormatNumber = (value: string) => {
    const formattedNumber =
      PIX_KEY_MASKS[keyType as keyof typeof PIX_KEY_MASKS]?.(value) ?? value;

    form.setValue('value', formattedNumber);
  };

  const onSubmit = async (data: PixKeyFormProps) => {
    pathname?.includes('/company') &&
      eventsHandler.submitButtonPixKeyTypeWalletCompany();
    pathname?.includes('/wallets') &&
      eventsHandler.submitButtonPixKeyTypeWallet();

    const formattedData = {
      type: data.type,
      value: data.value,
    };

    if (formattedData.type === PixKeyType.CNPJ) {
      formattedData.value = formattedData.value.replace(/[^\d]+/g, '');
    }

    // if (formattedData.type === PixKeyType.PHONE) {
    //   formattedData.value = formattedData.value.replace(/[^\d]+/g, '');
    //   formattedData.value = `+55${formattedData.value}`;
    // }

    if (formattedData.type === PixKeyType.EVP) {
      formattedData.value = crypto.randomUUID();
    }

    await createKey(
      {
        walletId,
        data: formattedData,
      },
      {
        onSuccess: (response) => {
          onCreate(response.dict);

          customerEvents.pixKeysCreated({
            type: formattedData.type,
            walletId,
          });

          setError(null);
        },
      }
    );
  };

  useEffect(() => {
    form.clearErrors('value');
  }, [keyType, form]);

  return (
    <SidebarSection
      titleSection={t('pix.drawer.key.create-title')}
      subTitleSection={t('pix.drawer.key.create-subtitle')}
      numberSection={1}
    >
      {step === 'add' && (
        <div className="new-pix-key">
          <div className="new-pix-key_content-wrapper">
            <Typography tag="p" weight="p3" color="var(--product-neutral-n100)">
              {t('pix.drawer.key.add-message-1')}{' '}
              <span className="strong-text">
                {t('pix.drawer.key.add-message-2')}
              </span>{' '}
              {t('pix.drawer.key.add-message-3')}
            </Typography>
            <Button
              type="button"
              size="medium"
              variant="secondary"
              className="add-button"
              onClick={() => {
                pathname?.includes('/company') &&
                  eventsHandler.createButtonNewPixKeyWalletCompany();
                pathname?.includes('/wallets') &&
                  eventsHandler.createButtonNewPixKeyWallet();

                setStep('config');
              }}
            >
              {t('pix.drawer.key.add-button')}
              <i className="fa-regular fa-plus fa-1x" />
            </Button>
          </div>
        </div>
      )}
      {step === 'config' && (
        <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
          <Select
            label={t('pix.drawer.key.select-key-type')}
            name="type"
            form={form}
            placeholder={t('pix.drawer.key.select-key-type')}
            options={Object.keys(PixKeyType).map((key) => ({
              label: t(`pix.drawer.key.type.${key}`, '') as string,
              value: key,
            }))}
            validationSchema={{ required: 'pix.error.required-key-type' }}
            onclick={() => {
              pathname?.includes('/company') &&
                eventsHandler.viewOptionsPixKeyTypesWalletCompany();
              pathname?.includes('/wallets') &&
                eventsHandler.viewOptionsPixKeyTypesWallet();

              form.setValue('value', '');
            }}
          />
          {keyType && keyType !== PixKeyType.EVP && (
            <Input
              label={t(`pix.drawer.key.type.${keyType}`)}
              name="value"
              register={form.register}
              type={keyType === PixKeyType.CNPJ ? 'tel' : 'text'}
              maxLength={keyType === PixKeyType.CNPJ ? 18 : 200}
              validationSchema={{
                required: t('pix.error.required-key-value'),
                validate: (value: string) => {
                  const validation = PIX_KEY_VALIDATIONS[keyType];

                  if (!validation?.regex.test(value)) {
                    return t(validation?.message, '');
                  }
                  if (keyType === PixKeyType.CNPJ) {
                    const cnpj = value.replace(/[^\d]+/g, '');
                    const currentOrg = organizations.find(
                      (org) =>
                        org.document === cnpj &&
                        org.id === localStorage.getItem('organization')
                    );
                    if (!currentOrg) {
                      return t('error.cnpj-conflict');
                    }
                  }
                  return true;
                },
              }}
              error={!!form.formState.errors['value']?.message}
              onChange={(event) => handleFormatNumber(event.target.value)}
            >
              {form.formState.errors['value']?.message && (
                <ErrorMessage
                  message={form.formState.errors['value'].message.toString()}
                />
              )}
            </Input>
          )}
          <div className="flex flex-row gap-4 self-end">
            <Button
              type="button"
              size="small"
              variant="secondary"
              onClick={() => {
                pathname?.includes('/company') &&
                  eventsHandler.cancelButtonNewPixKeyWalletCompany();
                pathname?.includes('/wallets') &&
                  eventsHandler.cancelButtonNewPixKeyWallet();

                setStep('add');
                form.reset();
              }}
            >
              {t('button.cancel')}
            </Button>
            <Button
              type="submit"
              size="small"
              variant="primary"
              disabled={creatingKey}
            >
              {creatingKey ? (
                <Spinner />
              ) : keyType === PixKeyType.EVP ? (
                t('pix.drawer.key.create-button-evp')
              ) : (
                t('pix.drawer.key.create-button')
              )}
            </Button>
          </div>
        </form>
      )}
    </SidebarSection>
  );
};
