import { useTranslation } from 'react-i18next';

import { useQueryParams } from '@hooks/useQueryParams';
import { Tabs } from '@portao3-web/ui';
import { HeaderPage } from '../../shared/components/headerPage';
import { StatementBody } from '../statement/components/StatementBody';
import { Batches } from './components';

export const Transactions = () => {
  const { t } = useTranslation();
  const { queryParams, setQueryParams } = useQueryParams();

  const tabs = [
    {
      id: '0',
      label: t('general.statement'),
      content: <StatementBody isGeneral />,
    },
    {
      id: '1',
      label: t('general.batches'),
      content: <Batches />,
    },
  ];

  const handleTabChange = (tab: string) => {
    const activeTab = tabs.find((t) => t.id === tab);
    setQueryParams({ tab: activeTab?.id });
    setQueryParams({ query: undefined });
    setQueryParams({ status: undefined });
  };

  return (
    <>
      <HeaderPage
        title={t('transactions.page-title')}
        subTitle={t('transactions.page-subtitle')}
      />

      <Tabs
        tabs={tabs}
        start={Number(queryParams.get('tab'))}
        onTabChange={handleTabChange}
      />
    </>
  );
};
