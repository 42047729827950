import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { Alert } from '@components';
import { regex } from '@constants/regex';
import { customerEvents, eventsHandler } from '@lib';
import {
  Button,
  ErrorMessage,
  Input,
  Modal,
  Spinner,
  Toastr,
  Typography,
} from '@portao3-web/ui';
import { updateCardName } from '@services/cards/CardDetailsServices';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import './ChaneNameModal.styles.scss';
import {
  ChangeCardNameForm,
  ChangeCardNameModalProps,
} from './ChangeNameModal.interfaces';

export const ChangeNameModal = ({
  setCard,
  card,
}: ChangeCardNameModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<ChangeCardNameForm>({
    defaultValues: {
      cardName: card.name,
    },
    mode: 'onChange',
  });

  const location = useLocation();
  const isWalletDefaultCards = location.pathname.includes('/default/cards');

  const handleClose = () => {
    if (isWalletDefaultCards) {
      eventsHandler.cancelModalEditCardNameCardsWallet();
    } else {
      eventsHandler.cancelModalEditCardNameAdminCards();
    }
    setCard(null);
  };

  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: (name: string) =>
      updateCardName(name, card.id.toString(), card.wallet),
    onSuccess: (response) => {
      Toastr.success('Nome do cartão alterado com sucesso!');
      handleClose();
      customerEvents.cardUpdated({
        id: response.id.toString(),
        name: response.name,
        type: response.type,
      });
      queryClient.invalidateQueries({ queryKey: ['card', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['card', { id: card.id }] });
    },
  });

  const onSubmit = async (data: ChangeCardNameForm) => {
    if (isWalletDefaultCards) {
      eventsHandler.submitModalEditCardNameCardsWallet();
    } else {
      eventsHandler.submitModalEditCardNameAdminCards();
    }
    await mutateAsync(data.cardName);
  };

  return (
    <Modal open={true} title="Editar apelido do cartão">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="change-name-modal-container"
      >
        <div className="change-name-modal-container_content">
          {error && (
            <Alert status="error">
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                {t('error.default')}
              </Typography>
            </Alert>
          )}
          <Typography tag="p" weight="p1" color="var(--product-neutral-n80)">
            Preencha o campo abaixo com o novo apelido do seu cartão
          </Typography>
          <Input
            label="Apelido"
            name="cardName"
            register={register}
            maxLength={25}
            showCounter
            error={!!errors['cardName']}
            validationSchema={{
              pattern: {
                value: regex.creditCardName,
                message:
                  'O apelido deve conter pelo menos duas palavras e não pode conter caracteres especiais',
              },
            }}
          >
            {errors['cardName']?.message && (
              <ErrorMessage message={errors['cardName'].message.toString()} />
            )}
          </Input>
        </div>
        <div className="change-name-modal-container_footer">
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="large"
            disabled={isPending || !isDirty || !isValid}
          >
            {isPending ? <Spinner /> : 'Salvar'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
