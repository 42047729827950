import { createSentryClickBreadcrumb } from '@lib/sentry';

enum CARDS_WALLET_EVENT {
  OPEN_DRAWER_CREATE_CARD_CARDS_WALLET = 'open_drawer_createCard_cardsWallet',
  SEARCH_BUTTON_CARD_LIST_CARDS_WALLET = 'search_button_cardList_cardsWallet',
  FILTER_BUTTON_CARD_LIST_CARDS_WALLET = 'filter_button_cardList_cardsWallet',
  SELECT_OPTION_CARD_LIST_CARDS_WALLET = 'select_option_cardList_cardsWallet',
  VIEW_OPTIONS_CARD_ACTIONS_CARDS_WALLET = 'view_options_cardActions_cardsWallet',
  SELECT_OPTION_CHANGE_CARD_NAME_CARDS_WALLET = 'select_option_changeCardName_cardsWallet',
  SELECT_OPTION_BLOCK_CARD_CARDS_WALLET = 'select_option_blockCard_cardsWallet',
  SELECT_OPTION_UNLOCK_CARD_CARDS_WALLET = 'select_option_unlockCard_cardsWallet',
  SHOW_BUTTON_CARD_DATA_CARDS_WALLET = 'show_button_cardData_cardsWallet',
  HIDE_BUTTON_CARD_DATA_CARDS_WALLET = 'hide_button_cardData_cardsWallet',
  CLOSE_DRAWER_CREATE_CARD_CARDS_WALLET = 'close_drawer_createCard_cardsWallet',
  SELECT_CARD_TYPE_PHYSICAL_CARDS_WALLET = 'select_cardType_physical_cardsWallet',
  SELECT_CARD_TYPE_VIRTUAL_CARDS_WALLET = 'select_cardType_virtual_cardsWallet',
  CANCEL_DRAWER_CARD_CREATION_CARDS_WALLET = 'cancel_drawer_cardCreation_cardsWallet',
  SUBMIT_DRAWER_CARD_CREATION_CARDS_WALLET = 'submit_drawer_cardCreation_cardsWallet',
  CANCEL_MODAL_EDIT_CARD_NAME_CARDS_WALLET = 'cancel_modal_editCardName_cardsWallet',
  SUBMIT_MODAL_EDIT_CARD_NAME_CARDS_WALLET = 'submit_modal_editCardName_cardsWallet',
  CANCEL_MODAL_BLOCK_CARD_CARDS_WALLET = 'cancel_modal_blockCard_cardsWallet',
  SUBMIT_MODAL_BLOCK_CARD_CARDS_WALLET = 'submit_modal_blockCard_cardsWallet',
  SUBMIT_MODAL_UNLOCK_CARD_CARDS_WALLET = 'submit_modal_unlockCard_cardsWallet',
}

export const cardsWalletEvents = {
  openDrawerCreateCardCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.OPEN_DRAWER_CREATE_CARD_CARDS_WALLET
    );
  },
  searchButtonCardListCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SEARCH_BUTTON_CARD_LIST_CARDS_WALLET
    );
  },
  filterButtonCardListCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.FILTER_BUTTON_CARD_LIST_CARDS_WALLET
    );
  },
  selectOptionCardListCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SELECT_OPTION_CARD_LIST_CARDS_WALLET
    );
  },
  viewOptionsCardActionsCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.VIEW_OPTIONS_CARD_ACTIONS_CARDS_WALLET
    );
  },
  selectOptionChangeCardNameCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SELECT_OPTION_CHANGE_CARD_NAME_CARDS_WALLET
    );
  },
  selectOptionBlockCardCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SELECT_OPTION_BLOCK_CARD_CARDS_WALLET
    );
  },
  selectOptionUnlockCardCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SELECT_OPTION_UNLOCK_CARD_CARDS_WALLET
    );
  },
  showButtonCardDataCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SHOW_BUTTON_CARD_DATA_CARDS_WALLET
    );
  },
  hideButtonCardDataCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.HIDE_BUTTON_CARD_DATA_CARDS_WALLET
    );
  },
  closeDrawerCreateCardCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.CLOSE_DRAWER_CREATE_CARD_CARDS_WALLET
    );
  },
  selectCardTypePhysicalCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SELECT_CARD_TYPE_PHYSICAL_CARDS_WALLET
    );
  },
  selectCardTypeVirtualCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SELECT_CARD_TYPE_VIRTUAL_CARDS_WALLET
    );
  },
  cancelDrawerCardCreationCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.CANCEL_DRAWER_CARD_CREATION_CARDS_WALLET
    );
  },
  submitDrawerCardCreationCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SUBMIT_DRAWER_CARD_CREATION_CARDS_WALLET
    );
  },
  cancelModalEditCardNameCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.CANCEL_MODAL_EDIT_CARD_NAME_CARDS_WALLET
    );
  },
  submitModalEditCardNameCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SUBMIT_MODAL_EDIT_CARD_NAME_CARDS_WALLET
    );
  },
  cancelModalBlockCardCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.CANCEL_MODAL_BLOCK_CARD_CARDS_WALLET
    );
  },
  submitModalBlockCardCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SUBMIT_MODAL_BLOCK_CARD_CARDS_WALLET
    );
  },
  submitModalUnlockCardCardsWallet: () => {
    createSentryClickBreadcrumb(
      CARDS_WALLET_EVENT.SUBMIT_MODAL_UNLOCK_CARD_CARDS_WALLET
    );
  },
};
