import { SidebarFooter } from '@components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef } from 'react';

import { TypePayment } from '@enums/typePayment.enum';
import { BankSlip } from '@interfaces/BankSlip.interface';
import { PixPayloadService } from '@interfaces/Pix.interface';
import { Button, Icon } from '@portao3-web/ui';
import { PixStatementProps } from './PaymentVoucher.interface';
import './PaymentVoucher.styles.scss';
import { BankSlipPaymentVoucher, PixPaymentVoucher } from './components';
dayjs.extend(utc);

export const PaymentVoucher = ({
  close,
  paymentVoucher,
  type,
  customFields,
}: PixStatementProps) => {
  const receiptRef = useRef<HTMLDivElement | null>(null);

  const downloadPdf = () => {
    if (receiptRef.current) {
      html2canvas(receiptRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 180;
        const imgHeight = (imgWidth * canvas.height) / canvas.width;
        const pageWidth = 210;
        const pageHeight = 297;
        const x = (pageWidth - imgWidth) / 2;
        const y = (pageHeight - imgHeight) / 2;
        const doc = new jsPDF();

        doc.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
        doc.save('reciboPix.pdf');
      });
    }
  };

  return (
    <div className="paymentVoucher" ref={receiptRef}>
      {type === TypePayment.PIX && (
        <PixPaymentVoucher
          {...{
            ...(paymentVoucher as PixPayloadService),
            createdAt: paymentVoucher.createdAt,
            endToEndId:
              (paymentVoucher as PixPayloadService).receipt?.endToEndId ?? '',
          }}
        />
      )}
      {type === TypePayment.BANK_SLIP && (
        <BankSlipPaymentVoucher
          data={paymentVoucher as BankSlip}
          customFields={customFields}
          showAlert
        />
      )}

      <SidebarFooter>
        <Button
          type="button"
          variant="tertiary"
          size="large"
          data-testid="download"
          onClick={downloadPdf}
        >
          Baixar comprovante
          <Icon size="xlarge">
            <i className="fa-regular fa-download"></i>
          </Icon>
        </Button>
        <Button
          size="large"
          data-testid="complete"
          onClick={() => {
            close(false);
          }}
        >
          Concluir
        </Button>
      </SidebarFooter>
    </div>
  );
};
