import { createSentryClickBreadcrumb } from '@lib/sentry';

enum ADMIN_WALLETS_EVENT {
  VIEW_OPTIONS_EXPORT_ADMIN_ADMIN_WALLETS = 'view_options_export_adminWallets',
  SELECT_OPTION_XLSX_TYPE_ADMIN_WALLETS = 'select_option_xlsxType_adminWallets',
  SELECT_OPTION_CSV_TYPE_ADMIN_WALLETS = 'select_option_csvType_adminWallets',
  OPEN_DRAWER_CREATE_WALLET_ADMIN_WALLETS = 'open_drawer_createWallet_adminWallets',
  CLOSE_DRAWER_CREATE_WALLET_ADMIN_WALLETS = 'close_drawer_createWallet_adminWallets',
  ENABLE_TOGGLE_WALLET_PIX_ADMIN_WALLETS = 'enable_toggle_walletPix_adminWallets',
  DISABLE_TOGGLE_WALLET_PIX_ADMIN_WALLETS = 'disable_toggle_walletPix_adminWallets',
  ENABLE_TOGGLE_WALLET_BOLETO_ADMIN_WALLETS = 'enable_toggle_walletBoleto_adminWallets',
  DISABLE_TOGGLE_WALLET_BOLETO_ADMIN_WALLETS = 'disable_toggle_walletBoleto_adminWallets',
  ENABLE_TOGGLE_WALLET_CARD_ADMIN_WALLETS = 'enable_toggle_walletCard_adminWallets',
  DISABLE_TOGGLE_WALLET_CARD_ADMIN_WALLETS = 'disable_toggle_walletCard_adminWallets',
  VIEW_OPTIONS_WALLET_PERMISSION_ADMIN_WALLETS = 'view_options_walletPermission_adminWallets',
  CLICK_INPUT_CUSTOM_FIELD_ADMIN_WALLETS = 'click_input_customField_adminWallets',
  SUBMIT_BUTTON_WALLET_CREATION_ADMIN_WALLETS = 'submit_button_walletCreation_adminWallets',
  SEARCH_BUTTON_WALLET_LIST_ADMIN_WALLETS = 'search_button_walletList_adminWallets',
  FILTER_BUTTON_WALLET_LIST_ADMIN_WALLETS = 'filter_button_walletList_adminWallets',
  VIEW_OPTIONS_WALLET_ACTIONS_ADMIN_WALLETS = 'view_options_walletActions_adminWallets',
  SELECT_OPTION_OPEN_WALLET_ADMIN_WALLETS = 'select_option_openWallet_adminWallets',
  SELECT_OPTION_MANAGE_WALLET_BALANCE_ADMIN_WALLETS = 'select_option_manageWalletBalance_adminWallets',
  SELECT_OPTION_EDIT_WALLET_ADMIN_WALLETS = 'select_option_editWallet_adminWallets',
  SELECT_OPTION_DISABLE_WALLET_ADMIN_WALLETS = 'select_option_disableWallet_adminWallets',
  SELECT_OPTION_RESET_WALLET_BALANCE_ADMIN_WALLETS = 'select_option_resetWalletBalance_adminWallets',
  LOAD_MORE_BUTTON_WALLET_LIST_ADMIN_WALLETS = 'load_more_button_walletList_adminWallets',
}

export const adminWalletsEvents = {
  viewOptionsExportAdminAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.VIEW_OPTIONS_EXPORT_ADMIN_ADMIN_WALLETS
    );
  },
  selectOptionXlsxTypeAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SELECT_OPTION_XLSX_TYPE_ADMIN_WALLETS
    );
  },
  selectOptionCsvTypeAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SELECT_OPTION_CSV_TYPE_ADMIN_WALLETS
    );
  },
  openDrawerCreateWalletAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.OPEN_DRAWER_CREATE_WALLET_ADMIN_WALLETS
    );
  },
  closeDrawerCreateWalletAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.CLOSE_DRAWER_CREATE_WALLET_ADMIN_WALLETS
    );
  },
  enableToggleWalletPixAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.ENABLE_TOGGLE_WALLET_PIX_ADMIN_WALLETS
    );
  },
  disableToggleWalletPixAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.DISABLE_TOGGLE_WALLET_PIX_ADMIN_WALLETS
    );
  },
  enableToggleWalletBoletoAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.ENABLE_TOGGLE_WALLET_BOLETO_ADMIN_WALLETS
    );
  },
  disableToggleWalletBoletoAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.DISABLE_TOGGLE_WALLET_BOLETO_ADMIN_WALLETS
    );
  },
  enableToggleWalletCardAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.ENABLE_TOGGLE_WALLET_CARD_ADMIN_WALLETS
    );
  },
  disableToggleWalletCardAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.DISABLE_TOGGLE_WALLET_CARD_ADMIN_WALLETS
    );
  },
  viewOptionsWalletPermissionAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.VIEW_OPTIONS_WALLET_PERMISSION_ADMIN_WALLETS
    );
  },
  clickInputCustomFieldAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.CLICK_INPUT_CUSTOM_FIELD_ADMIN_WALLETS
    );
  },
  submitButtonWalletCreationAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SUBMIT_BUTTON_WALLET_CREATION_ADMIN_WALLETS
    );
  },
  searchButtonWalletListAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SEARCH_BUTTON_WALLET_LIST_ADMIN_WALLETS
    );
  },
  filterButtonWalletListAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.FILTER_BUTTON_WALLET_LIST_ADMIN_WALLETS
    );
  },
  viewOptionsWalletActionsAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.VIEW_OPTIONS_WALLET_ACTIONS_ADMIN_WALLETS
    );
  },
  selectOptionOpenWalletAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SELECT_OPTION_OPEN_WALLET_ADMIN_WALLETS
    );
  },
  selectOptionManageWalletBalanceAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SELECT_OPTION_MANAGE_WALLET_BALANCE_ADMIN_WALLETS
    );
  },
  selectOptionEditWalletAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SELECT_OPTION_EDIT_WALLET_ADMIN_WALLETS
    );
  },
  selectOptionDisableWalletAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SELECT_OPTION_DISABLE_WALLET_ADMIN_WALLETS
    );
  },
  selectOptionResetWalletBalanceAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.SELECT_OPTION_RESET_WALLET_BALANCE_ADMIN_WALLETS
    );
  },
  loadMoreButtonWalletListAdminWallets: () => {
    createSentryClickBreadcrumb(
      ADMIN_WALLETS_EVENT.LOAD_MORE_BUTTON_WALLET_LIST_ADMIN_WALLETS
    );
  },
};
