import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { customerEvents } from '@lib/customerIo';
import { eventsHandler } from '@lib/eventsHandler';
import { Button, Icon, Modal, Spinner, Toastr } from '@portao3-web/ui';
import { useUser } from '@providers/UserProvider';
import {
  useMemberGetMemberIdMutation,
  useMemberGetMemberIdQuery,
} from '@services/user/hooks';
import { ContextualError } from '../alerts';

export const MemberGetMember = () => {
  const organizationId = localStorage.getItem('organization') ?? '';

  const { id } = useUser();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [animationLink, setAnimationLink] = useState(false);
  const [animationButton, setAnimationButton] = useState(false);

  const { data: memberGetMemberId } = useMemberGetMemberIdQuery({
    userId: id,
    organizationId,
  });

  const {
    mutate: createMGMId,
    isPending: isCreatingMGMId,
    customError,
  } = useMemberGetMemberIdMutation();

  const partnerUrl = `www.portao3.com.br/lp/referral?r=${memberGetMemberId?.id}`;

  const handleCopyLink = async (key: 'link' | 'button') => {
    eventsHandler.copyMemberGetMemberLink();

    if (key === 'link') {
      setAnimationLink(true);
      setTimeout(() => setAnimationLink(false), 3000);
    }

    if (key === 'button') {
      setAnimationButton(true);
      setTimeout(() => setAnimationButton(false), 3000);
    }

    try {
      await navigator.clipboard.writeText(partnerUrl);
      customerEvents.memberGetMemberLinkCopied({ link: partnerUrl });
    } catch (error) {
      Toastr.error(t('error.need-permission-clipboard'));
    }
  };

  const handleOpenModal = () => {
    eventsHandler.openModalMemberGetMember();
    setOpen(true);
  };

  const handleCreateMGMId = () => {
    eventsHandler.clickButtonCreateMemberGetMember();
    createMGMId(undefined, {
      onSuccess: () => {
        customerEvents.memberGetMemberLinkCreated();
      },
    });
  };

  return (
    <>
      <Button
        variant="tertiary"
        size="medium"
        onClick={handleOpenModal}
        className="text-primary-300 my-auto shrink-0"
      >
        <i className={`${ICONS['face-smile-plus']} fa-1x`} />
        {t('member-get-member.refer-now')}
      </Button>
      <Modal
        open={open}
        closeModal={setOpen}
        title={t('member-get-member.refer-program')}
      >
        <div className="flex w-[552px] flex-col">
          <div className="flex flex-col gap-8 p-6">
            <div className="flex flex-col gap-4">
              <p className="text-h4 text-primary">
                {t('member-get-member.refer-3')}
              </p>
              <p className="text-p1 text-neutral-300">
                {t('member-get-member.its-simple')}
              </p>
              <div className="grid grid-cols-[max-content_auto] gap-1 pl-2">
                <p className="text-p1 text-neutral-80">1.</p>
                <p className="text-p1 text-neutral-80">
                  {t('member-get-member.send-link')}
                </p>
                <p className="text-p1 text-neutral-80">2.</p>
                <p className="text-p1 text-neutral-80">
                  {t('member-get-member.they-sign-up')}
                </p>
                <p className="text-p1 text-neutral-80">3.</p>
                <p className="text-p1 text-neutral-80">
                  {t('member-get-member.discount')}
                </p>
              </div>
            </div>
            <ContextualError error={customError} />
            {memberGetMemberId && (
              <div className="flex flex-col gap-2">
                <p className="text-p1 text-neutral-300">
                  {t('member-get-member.share-now')}
                </p>
                <div className="border-neutral-40 grid grid-cols-[max-content_auto] items-center gap-x-2 rounded-md border border-solid p-3">
                  <div className="flex h-4 w-4 items-center justify-center">
                    <i className={`${ICONS.globe} text-p2 text-primary`} />
                  </div>
                  <p className="text-p2 text-neutral-500">
                    {t('member-get-member.copy-link-below')}
                  </p>
                  <button
                    className="col-start-2 grid grid-cols-[auto_max-content] justify-start gap-1"
                    onClick={() => handleCopyLink('link')}
                    disabled={animationLink}
                  >
                    <p
                      className="text-p3 text-neutral-90 truncate"
                      title={partnerUrl}
                    >
                      {partnerUrl}
                    </p>

                    <Icon size="medium">
                      {animationLink ? (
                        <i
                          className={`${ICONS.check} fa-1x text-success-500 text-p3`}
                        />
                      ) : (
                        <i
                          className={`${ICONS.copy} text-p3 text-neutral-90`}
                        />
                      )}
                    </Icon>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="border-neutral-40 flex justify-end gap-4 border-t border-solid py-3 px-6">
            <Button
              size="large"
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              {t('general.button.close')}
            </Button>

            {memberGetMemberId ? (
              <Button
                size="large"
                onClick={() => handleCopyLink('button')}
                className={clsx({ 'pointer-events-none': animationButton })}
              >
                <Icon size="medium">
                  {animationButton ? (
                    <i className={`${ICONS.check} fa-1x`} />
                  ) : (
                    <i className={`${ICONS.copy} fa-1x`} />
                  )}
                </Icon>
                {animationButton
                  ? t('member-get-member.copied-link')
                  : t('member-get-member.copy-link')}
              </Button>
            ) : (
              <Button
                size="large"
                onClick={handleCreateMGMId}
                className={clsx({ 'pointer-events-none': animationButton })}
                disabled={isCreatingMGMId}
              >
                {isCreatingMGMId ? (
                  <Spinner variant="secondary" size="small" />
                ) : (
                  <Icon size="medium">
                    <i className={`${ICONS.code} fa-1x`} />
                  </Icon>
                )}
                Gerar Link
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
