import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
  GeneralPresentationLogin,
  HeaderCard,
  StandardLoggedNavbar,
} from '@components';
import { UserLevelEnum } from '@enums/UserEnum';
import { Organization } from '@interfaces/Organization.interfaces';
import { customerEvents, resetCustomerIoIdentity } from '@lib';
import { Typography } from '@portao3-web/ui';
import { useUser } from '@providers';
import { getRoles } from '@services/roles';
import { cnpjMask } from '@utils/formatMasks';
import './Organizations.styles.scss';

export const Organizations = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { isRealmAdmin } = useUser();

  const getUserStorage = localStorage.getItem('user') || JSON.stringify('');
  const getOrganizations = JSON.parse(getUserStorage).organizations;
  const getCurrentUser = JSON.parse(getUserStorage);

  const { setRoles } = useUser();

  const setOrganization = async (organization: Organization) => {
    localStorage.setItem('organization', organization.id);

    try {
      if (getCurrentUser.realm.level === UserLevelEnum.USER) {
        const response = await getRoles();
        setRoles(response);
      }

      customerEvents.organizationSelected({
        id: organization.id,
        name: organization.tradingName,
      });

      history('/wallets?order=createdAt-DESC');
    } catch (error) {
      console.log(error);
      localStorage.clear();
      resetCustomerIoIdentity();
      history('/');
    }
  };

  return (
    <div className="organizations">
      <div className="authentication_content">
        <header className="authentication_img_header" />
        <GeneralPresentationLogin />
        <div className="authentication_content_right">
          <div className="fixed top-0 right-0 h-16 items-center">
            <StandardLoggedNavbar />
          </div>
          <div className="authentication_content_right_form">
            <div className="organizations_card">
              <HeaderCard title={t('general.my-companies')}>
                {isRealmAdmin && (
                  <Link
                    to={`/realms/${getCurrentUser.realm.id}/companies`}
                    className="settings-link font-p3"
                  >
                    {t('general.settings')}
                    <i className="fa-regular fa-cog fa-1x" />
                  </Link>
                )}
              </HeaderCard>
              <div className="organizations_scroll">
                <div className="organizations_most_used">
                  {getOrganizations?.map(
                    (organization: Organization, index: number) => {
                      return (
                        <div className="organizations_card" key={index}>
                          <button
                            className="organizations_item"
                            onClick={() => setOrganization(organization)}
                            data-testid={`organization-item-${index}`}
                          >
                            <div className="organizations_item_icon">
                              <i className="fa-solid fa-building icon"></i>
                            </div>
                            <Typography
                              tag="p"
                              className="organizations_card_text"
                            >
                              {organization.legalName}
                            </Typography>
                            <div className="organizations_item_info">
                              <Typography
                                tag="p"
                                className="organizations_card_tradingName"
                                color="var(--product-neutral-n80)"
                              >
                                {organization.tradingName}
                              </Typography>
                              <Typography
                                tag="p"
                                className="organizations_card_text"
                                color="var(--product-neutral-n80)"
                              >
                                {cnpjMask(organization.document)}
                              </Typography>
                            </div>
                          </button>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <Typography tag="p" className="authentication_copyright">
            © {new Date().getFullYear()} - Portão 3 - Todos os direitos
            reservados
          </Typography>
        </div>
      </div>
    </div>
  );
};
