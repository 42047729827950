import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { CurrentUser, UserResponse } from '@interfaces/User.interfaces';
import { findUsersByOrganization } from '@services/user';
import { userKeys } from './userKeys';

export interface UseInfiniteUserListProps {
  organization: string | null;
  query?: string;
  limit?: number;
}

export const useInfiniteUserListQuery = (
  { organization, query, limit }: UseInfiniteUserListProps,
  enabled = true
) => {
  return useInfiniteQuery<
    UserResponse,
    ApiError,
    CurrentUser[],
    unknown[],
    string
  >({
    queryKey: userKeys.orgList({ organization, query, limit }),
    queryFn: ({ pageParam }) =>
      findUsersByOrganization(query, pageParam, limit),
    getNextPageParam: (lastPage) => lastPage?.next,
    initialPageParam: '',
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    placeholderData: keepPreviousData,
    select: (data) => data.pages.flatMap((page) => page.items),
    meta: {
      errorMessage: 'Erro ao buscar usuários',
    },
    enabled: enabled && !!organization,
  });
};
