import { NewTicket, PaymentConfirmation, PaymentVoucher } from '@components';
import { BankSlip } from '@interfaces/BankSlip.interface';
import { CustomFieldView } from '@interfaces/CustomFields.interfaces';
import { PixPayloadService } from '@interfaces/Pix.interface';
import { eventsHandler } from '@lib';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface DrawerNewBankSlipProps {
  typePayment: string;
  openDrawerNewBankSlip: boolean;
  back: (open: boolean) => void;
  walletId?: string;
  close: () => void;
  categoryType?: string;
}

export const DrawerNewBankSlip = ({
  typePayment,
  openDrawerNewBankSlip,
  back,
  walletId,
  close,
  categoryType,
}: DrawerNewBankSlipProps) => {
  const { pathname } = useLocation();

  const [newPaymentTicket, setNewPaymentTicket] = useState(false);
  const [confirmationPayment, setConfirmationPayment] = useState(false);
  const [paymentVoucher, setPaymentVoucher] = useState(false);

  const [confirmPaymentPixBankSlip, setConfirmPaymentPixBankSlip] = useState<
    PixPayloadService | BankSlip
  >({} as PixPayloadService | BankSlip);
  const [customFieldState, setCustomFieldState] = useState<CustomFieldView[]>(
    []
  );

  const closeNewTicket = (close: boolean) => {
    setNewPaymentTicket(close);
    setConfirmationPayment(true);
  };

  const backNewPayments = () => {
    setNewPaymentTicket(false);
    back(false);
    setConfirmationPayment(false);
  };

  const confirmPayment = () => {
    setConfirmationPayment(false);
    setPaymentVoucher(true);
  };

  useEffect(() => {
    setNewPaymentTicket(openDrawerNewBankSlip);
  }, [openDrawerNewBankSlip]);

  return (
    <>
      {newPaymentTicket && (
        <NewTicket
          close={closeNewTicket}
          back={() => {
            pathname.includes('/company') &&
              eventsHandler.backButtonFundsOutBoletoWalletCompany();
            pathname.includes('/wallets') &&
              eventsHandler.backButtonFundsOutBoletoWallet();

            backNewPayments();
          }}
          confirmPayment={(confirmNewTicketPayment) => {
            setConfirmPaymentPixBankSlip(confirmNewTicketPayment);
          }}
          walletId={walletId}
        />
      )}
      {confirmationPayment && (
        <PaymentConfirmation
          close={confirmPayment}
          back={() => {
            pathname.includes('/company') &&
              eventsHandler.cancelButtonFundsOutBoletoWalletCompany();
            pathname.includes('/wallets') &&
              eventsHandler.cancelButtonFundsOutBoletoWallet();

            backNewPayments();
          }}
          type={typePayment}
          paymentConfirm={confirmPaymentPixBankSlip}
          paymentVoucher={(paymentVoucher) => {
            setConfirmPaymentPixBankSlip(paymentVoucher);
          }}
          walletId={walletId}
          categoryType={categoryType}
          setCustomFields={setCustomFieldState}
        />
      )}

      {paymentVoucher && (
        <PaymentVoucher
          type={typePayment}
          close={close}
          paymentVoucher={confirmPaymentPixBankSlip}
          customFields={customFieldState}
        />
      )}
    </>
  );
};
