import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { Customer } from '@interfaces/Customer.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { findCustomerList } from '../findCustomerList';
import { customerKeys } from './customerKeys';
import { ListResponse } from '@interfaces/Common.interfaces';

export const useInfiniteCustomerListQuery = (
  organizationId: string | null,
  query?: string,
  limit?: number
) => {
  return useInfiniteQuery<
    ListResponse<Customer>,
    ApiError,
    Customer[],
    unknown[],
    string
  >({
    queryKey: customerKeys.list(organizationId, query, limit),
    queryFn: ({ pageParam }) => findCustomerList(pageParam, query, limit),
    getNextPageParam: (lastPage) => lastPage?.next,
    initialPageParam: '',
    select: (data) => data.pages.flatMap((page) => page.items),
    enabled: !!organizationId,
  });
};

export const useInifinitClientsListQuery = (
  organizationId: string | null,
  query?: string
) => {
  return useInfiniteQuery<
    ListResponse<Customer>,
    ApiError,
    InfiniteData<ListResponse<Customer>>,
    unknown[],
    string
  >({
    queryKey: customerKeys.list(organizationId, query),
    queryFn: ({ pageParam }) => findCustomerList(pageParam, query),
    getNextPageParam: (lastPage) => lastPage?.next,
    initialPageParam: '',
    retry: 1,
    enabled: !!organizationId,
  });
};
