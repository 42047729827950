import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DatePickerRangeQuery, HeaderPage } from '@components';
import { useQueryParams } from '@hooks/useQueryParams';
import { useWalletQuery } from '@services/wallet/hooks';
import { capitalizeFirstLetter } from '@utils/CapitalizeFirstLetter';
import { StatementBody } from './components';
import { ExportStatementButton } from './components/ExportStatementButton';

export const Statement = () => {
  const organizationId = localStorage.getItem('organization');

  const { queryParams } = useQueryParams();
  const { walletId } = useParams();
  const { t } = useTranslation();

  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');

  const { data: wallet, isFetching } = useWalletQuery({
    organizationId,
    walletId,
  });

  const isWalletDefault = walletId === 'default';

  const breadCrumbElem = [
    {
      label: 'Carteiras',
      url: '/wallets',
      isDisabled: false,
    },
    {
      label: capitalizeFirstLetter(
        isWalletDefault ? t('general.company-account') : wallet?.name
      ),
      url: isWalletDefault ? `/company` : `/wallets/${walletId}/geral`,
      isDisabled: false,
    },
    {
      label: 'Extrato',
      url: ``,
      isDisabled: true,
    },
  ];

  return (
    <div className="flex flex-col">
      <HeaderPage
        title="Extrato"
        breadcrumbList={breadCrumbElem}
        isLoading={isFetching}
      >
        <DatePickerRangeQuery />

        <ExportStatementButton
          endDate={endDate}
          startDate={startDate}
          walletId={walletId}
        />
      </HeaderPage>

      <StatementBody />
    </div>
  );
};
