import { createSentryClickBreadcrumb } from '@lib/sentry';

enum ADMIN_PREFERENCES_EVENT {
  OPEN_DRAWER_CREATE_FIELD_ADMIN_PREFERENCES = 'open_drawer_createField_adminPreferences',
  SEARCH_BUTTON_FIELDS_LIST_ADMIN_PREFERENCES = 'search_button_fieldsList_adminPreferences',
  FILTER_BUTTON_FIELDS_LIST_ADMIN_PREFERENCES = 'filter_button_fieldsList_adminPreferences',
  VIEW_OPTIONS_INPUT_ACTIONS_ADMIN_PREFERENCES = 'view_options_inputActions_adminPreferences',
  SELECT_OPTION_EDIT_INPUT_ADMIN_PREFERENCES = 'select_option_editInput_adminPreferences',
  SELECT_OPTION_DELETE_INPUT_ADMIN_PREFERENCES = 'select_option_deleteInput_adminPreferences',
  CLOSE_DRAWER_CREATE_FIELD_ADMIN_PREFERENCES = 'close_drawer_createField_adminPreferences',
  ENABLE_FIELD_REQUIRED_FIELD_ADMIN_PREFERENCES = 'enable_field_requiredField_adminPreferences',
  DISABLE_FIELD_REQUIRED_FIELD_ADMIN_PREFERENCES = 'disable_field_requiredField_adminPreferences',
  ENABLE_CARD_DISPLAY_FIELD_TRANSACTIONS_ADMIN_PREFERENCES = 'enable_card_displayFieldTransactions_adminPreferences',
  DISABLE_CARD_DISPLAY_FIELD_TRANSACTIONS_ADMIN_PREFERENCES = 'disable_card_displayFieldTransactions_adminPreferences',
  ENABLE_CARD_DISPLAY_FIELD_WALLETS_ADMIN_PREFERENCES = 'enable_card_displayFieldWallets_adminPreferences',
  DISABLE_CARD_DISPLAY_FIELD_WALLETS_ADMIN_PREFERENCES = 'disable_card_displayFieldWallets_adminPreferences',
  SELECT_OPTION_FIELD_TYPE_ADMIN_PREFERENCES = 'select_option_fieldType_adminPreferences',
  CREATE_FIELD_NEW_ATTRIBUTE_ADMIN_PREFERENCES = 'create_field_newAttribute_adminPreferences',
  CREATE_FIELD_EMPTY_NEW_ATTRIBUTE_ADMIN_PREFERENCES = 'create_fieldEmpty_newAttribute_adminPreferences',
  SUBMIT_BUTTON_CREATE_FIELD_ADMIN_PREFERENCES = 'submit_button_createField_adminPreferences',
  CANCEL_BUTTON_CONFIRM_EXIT_DRAWER_ADMIN_PREFERENCES = 'cancel_button_confirmExitDrawer_adminPreferences',
  SUBMIT_BUTTON_CONFIRM_EXIT_DRAWER_ADMIN_PREFERENCES = 'submit_button_confirmExitDrawer_adminPreferences',
}

export const adminPreferencesEvents = {
  openDrawerCreateFieldAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.OPEN_DRAWER_CREATE_FIELD_ADMIN_PREFERENCES
    );
  },
  searchButtonFieldsListAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.SEARCH_BUTTON_FIELDS_LIST_ADMIN_PREFERENCES
    );
  },
  filterButtonFieldsListAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.FILTER_BUTTON_FIELDS_LIST_ADMIN_PREFERENCES
    );
  },
  viewOptionsInputActionsAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.VIEW_OPTIONS_INPUT_ACTIONS_ADMIN_PREFERENCES
    );
  },
  selectOptionEditInputAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.SELECT_OPTION_EDIT_INPUT_ADMIN_PREFERENCES
    );
  },
  selectOptionDeleteInputAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.SELECT_OPTION_DELETE_INPUT_ADMIN_PREFERENCES
    );
  },
  closeDrawerCreateFieldAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.CLOSE_DRAWER_CREATE_FIELD_ADMIN_PREFERENCES
    );
  },
  enableFieldRequiredFieldAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.ENABLE_FIELD_REQUIRED_FIELD_ADMIN_PREFERENCES
    );
  },
  disableFieldRequiredFieldAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.DISABLE_FIELD_REQUIRED_FIELD_ADMIN_PREFERENCES
    );
  },
  enableCardDisplayFieldTransactionsAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.ENABLE_CARD_DISPLAY_FIELD_TRANSACTIONS_ADMIN_PREFERENCES
    );
  },
  disableCardDisplayFieldTransactionsAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.DISABLE_CARD_DISPLAY_FIELD_TRANSACTIONS_ADMIN_PREFERENCES
    );
  },
  enableCardDisplayFieldWalletsAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.ENABLE_CARD_DISPLAY_FIELD_WALLETS_ADMIN_PREFERENCES
    );
  },
  disableCardDisplayFieldWalletsAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.DISABLE_CARD_DISPLAY_FIELD_WALLETS_ADMIN_PREFERENCES
    );
  },
  selectOptionFieldTypeAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.SELECT_OPTION_FIELD_TYPE_ADMIN_PREFERENCES
    );
  },
  createFieldNewAttributeAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.CREATE_FIELD_NEW_ATTRIBUTE_ADMIN_PREFERENCES
    );
  },
  createFieldEmptyNewAttributeAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.CREATE_FIELD_EMPTY_NEW_ATTRIBUTE_ADMIN_PREFERENCES
    );
  },
  submitButtonCreateFieldAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.SUBMIT_BUTTON_CREATE_FIELD_ADMIN_PREFERENCES
    );
  },
  cancelButtonConfirmExitDrawerAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.CANCEL_BUTTON_CONFIRM_EXIT_DRAWER_ADMIN_PREFERENCES
    );
  },
  submitButtonConfirmExitDrawerAdminPreferences: () => {
    createSentryClickBreadcrumb(
      ADMIN_PREFERENCES_EVENT.SUBMIT_BUTTON_CONFIRM_EXIT_DRAWER_ADMIN_PREFERENCES
    );
  },
};
