import {
  ContextualError,
  ContextualErrorType,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import {
  Button,
  ErrorMessage,
  Icon,
  Input,
  Spinner,
  Toastr,
} from '@portao3-web/ui';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FormPixQrCodeCopyPaste } from '../newPix/NewPix.interface';
import { pixPayment } from '../newPix/services/PixService';
import { PixCopyPasteProps } from './PixCopyPaste.interface';

export const PixCopyPaste = ({
  back,
  confirmPayment,
  close,
  setDictType,
  walletId,
}: PixCopyPasteProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [messageErrorService, setMessageErrorService] =
    useState<ContextualErrorType>(null);

  const form = useForm<FormPixQrCodeCopyPaste>({
    mode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = form;

  const submitFormPix = async (formData: FormPixQrCodeCopyPaste) => {
    setLoading(true);
    setMessageErrorService(null);

    setLoading(true);

    const form = {
      emv: formData.emv,
      initiationType: 'COPY_PASTE',
    };

    pathname?.includes('/company') &&
      eventsHandler.submitButtonPixIntentFundsOutWalletCompany();
    pathname?.includes('/wallets') &&
      eventsHandler.submitButtonPixIntentFundsOutWallet();

    try {
      const responsePix = await pixPayment(form, walletId);
      customerEvents.pixInitiated({
        type: 'QRCODE',
        dictType: responsePix?.qrCodeType,
        amount: responsePix.txnOriginalAmount,
        walletId: responsePix.walletId,
      });
      setDictType('COPY_PASTE');
      confirmPayment(responsePix);
      setLoading(false);
      close(false);
    } catch (error: unknown) {
      setLoading(false);

      const errorService = error as AxiosError;
      const errorInfo = errorService?.response?.data as ErrorRequest;

      setMessageErrorService({
        message: t(
          `error.pix-transaction.${errorInfo?.message}`,
          t('error.default')
        ),
        traceId: errorInfo?.traceId || '',
      });
    }
  };

  const pastePix = async () => {
    try {
      await navigator.clipboard.readText().then((text) => {
        form.setValue('emv', text);
        form.trigger('emv');

        pathname?.includes('/company') &&
          eventsHandler.clickButtonPasteKeyWalletCompany();
        pathname?.includes('/wallets') &&
          eventsHandler.clickButtonPasteKeyWallet();
      });
    } catch (error) {
      Toastr.error(t('error.need-permission-clipboard'));
    }
  };

  return (
    <>
      <ContextualError error={messageErrorService} />
      <form onSubmit={handleSubmit(submitFormPix)} data-testid="pix-copy-paste">
        <SidebarSection
          numberSection={1}
          titleSection={t('pix.copy-paste.title')}
          subTitleSection=""
        >
          <Input
            type="text"
            label="Pix copia e cola"
            placeholder="Cole o código copia e cola"
            id="emv"
            name="emv"
            register={register}
            data-testid="emv"
            error={!!errors['emv']}
            required
            validationSchema={{
              required: 'A chave Pix é obrigatória',
            }}
          >
            {errors['emv']?.message && (
              <ErrorMessage message={errors['emv'].message.toString()} />
            )}
          </Input>
          <div className="flex justify-end">
            <Button
              type="button"
              variant="secondary"
              size="small"
              onClick={() => pastePix()}
              data-testid="pastePix"
            >
              Colar chave
            </Button>
          </div>
        </SidebarSection>

        <SidebarFooter>
          {isDirty ? (
            <Button
              onClick={() => {
                setMessageErrorService(null);
                reset();
              }}
              variant="tertiary"
              size="large"
              type="reset"
              data-testid="resetForm"
            >
              {t('general.button.clear-input')}
              <Icon size="large">
                <i className="fa-regular fa-trash" />
              </Icon>
            </Button>
          ) : (
            <Button
              type="button"
              variant="tertiary"
              size="large"
              onClick={() => back()}
            >
              {t('general.button.back')}
            </Button>
          )}
          <Button
            type="submit"
            size="large"
            disabled={!isValid || loading}
            data-testid="submitForm"
            className="button_submit"
          >
            {loading ? (
              <Spinner variant="secondary" />
            ) : (
              t('general.button.continue')
            )}
          </Button>
        </SidebarFooter>
      </form>
    </>
  );
};
