import { useQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import {
  CustomFieldArrayProps,
  CustomFieldLocation,
} from '@interfaces/CustomFields.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { getCustomFields } from '@services/customFields';
import { customFieldKeys } from './customFieldKeys';

export interface UseCustomFieldListParams {
  organization: string;
  location?: CustomFieldLocation;
}

export const useCustomFieldsQuery = ({
  organization,
  location,
}: UseCustomFieldListParams) => {
  return useQuery<
    CustomFieldArrayProps,
    ApiError,
    CustomFieldArrayProps,
    unknown[]
  >({
    queryKey: customFieldKeys.list({ organization, location }),
    queryFn: () => getCustomFields(100, '', location),
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    enabled: !!organization,
  });
};
