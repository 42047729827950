import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  ClearFiltersQuery,
  DatePickerRangeQuery,
  EmptyStateBlock,
  MultiSelectQuery,
  Pagination,
  SearchQuery,
  TableColumnsFilter,
} from '@components';
import {
  emptyStatementTransactions,
  emptyStatementTransactionsWithFilter,
  errorState,
} from '@constants/emptyState';
import { TransactionType } from '@enums/TransactionsStatusEnum';
import { useQueryParams } from '@hooks/useQueryParams';
import { eventsHandler } from '@lib';
import { DrawerTransactionDetails } from '@modules/drawer';
import { ExportStatementButton, TransactionsWithId } from '@pages';
import { DetailsCard, TableDemo } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import {
  transactionKeys,
  useInfiniteTransactionListQuery,
} from '@services/transactions/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { useTranslation } from 'react-i18next';
import { useStatementColumns } from '../components/columns';

export const StatementBody = ({ isGeneral }: { isGeneral?: boolean }) => {
  const organizationId = localStorage.getItem('organization');

  const { queryParams } = useQueryParams();
  const { columns, filterColumnsProps } = useStatementColumns({
    isGeneral,
  });

  const { walletId } = useParams();
  const { openDrawer } = useDrawer();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const filters = {
    startDate: queryParams.get('startDate'),
    endDate: queryParams.get('endDate'),
    description: queryParams.get('description'),
    financialImpactType: queryParams.get('financialImpact'),
    transactionTypes: queryParams.getAll('transactionTypes'),
  };

  const hasFilters =
    filters.transactionTypes.length > 0 ||
    !!filters.financialImpactType ||
    !!filters.description;

  const [page, setPage] = useState(0);

  const {
    data: transactionsData,
    isLoading: isLoadingTransactions,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isError,
    isFetchingNextPage,
  } = useInfiniteTransactionListQuery({ ...filters, organizationId, walletId });

  const transactions: TransactionsWithId[] | undefined = useMemo(() => {
    return transactionsData?.pages?.[page]?.items?.map((transaction) => ({
      ...transaction,
      id: transaction._id,
      onRowClick:
        transaction.financialImpactType === 'NONE'
          ? undefined
          : () => {
              eventsHandler.openDrawerTransactionDetailsAdminWalletStatement();
              openDrawer(
                <DrawerTransactionDetails
                  openSidebar={true}
                  transactionId={transaction._id}
                  walletId={transaction.walletId}
                  updateCountFiles={() =>
                    queryClient.invalidateQueries({
                      queryKey: transactionKeys.all(),
                    })
                  }
                />
              );
            },
    }));
  }, [transactionsData, page, openDrawer, queryClient]);

  const typeTransactionOptions = [
    {
      label: t('general.bank-slip'),
      value: TransactionType.BOLETO,
    },
    {
      label: t('general.card'),
      value: TransactionType.CARD,
    },
    {
      label: t('general.pix'),
      value: TransactionType.PIX,
    },
    {
      label: t('general.transfer-between-wallets'),
      value: TransactionType.P2P,
    },
    {
      label: 'Taxa',
      value: TransactionType.FEE,
    },
  ];

  const financialImpactOptions = [
    {
      label: t('general.credit'),
      value: 'CREDIT',
    },
    {
      label: t('general.debit'),
      value: 'DEBIT',
    },
  ];

  return (
    <div className="flex flex-col">
      {isGeneral && (
        <div className="flex justify-between mb-4 items-center">
          <h2 className="text-h3 text-neutral-500">{t('general.statement')}</h2>

          <div className="flex gap-2">
            <DatePickerRangeQuery />

            <ExportStatementButton
              endDate={filters.endDate}
              startDate={filters.startDate}
            />
          </div>
        </div>
      )}

      <div className="mb-8 flex justify-between gap-6">
        <DetailsCard
          label="Total de entradas"
          headerIcon="fa-regular fa-arrow-up"
          loading={isLoadingTransactions}
          currencyValue={
            transactionsData
              ? calcCurrencyValue(transactionsData?.pages[0].input, 'BRL')
              : 'Erro ao carregar o valor'
          }
          valueType={transactionsData?.pages[0].input ? 'ENTRY' : 'AVAILABLE'}
          storageName="statementEntries"
        />
        <DetailsCard
          label="Total de saídas"
          headerIcon="fa-regular fa-arrow-down"
          loading={isLoadingTransactions}
          currencyValue={
            transactionsData
              ? calcCurrencyValue(transactionsData?.pages[0].output, 'BRL')
              : 'Erro ao carregar o valor'
          }
          valueType={transactionsData?.pages[0].output ? 'EXIT' : 'AVAILABLE'}
          storageName="statementExits"
        />
      </div>

      <div className="mb-4 flex flex-wrap gap-3">
        <SearchQuery placeholder={'Busque por descrição'} query="description" />

        <MultiSelectQuery
          onlyOptions
          query="transactionTypes"
          title={t('general.type')}
          options={typeTransactionOptions}
        />

        <MultiSelectQuery
          onlyOptions
          query="financialImpact"
          title={t('general.credit-debit')}
          type="single"
          options={financialImpactOptions}
        />

        <ClearFiltersQuery
          queries={['financialImpact', 'transactionTypes', 'description']}
        />

        <TableColumnsFilter {...filterColumnsProps} />
      </div>

      <TableDemo
        columns={columns}
        data={transactions}
        isLoading={isFetching || isFetchingNextPage}
        emptyState={
          <>
            {!isError && !hasFilters && (
              <EmptyStateBlock details={emptyStatementTransactions} />
            )}

            {!isError && hasFilters && (
              <EmptyStateBlock details={emptyStatementTransactionsWithFilter} />
            )}

            {isError && <EmptyStateBlock details={errorState} />}
          </>
        }
        pinLastColumn
      />

      <div className="mt-4 flex items-center justify-between">
        <Pagination
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          page={page}
          setPage={setPage}
          pageCount={transactionsData?.pages.length}
          className="ml-auto"
        />
      </div>
    </div>
  );
};
