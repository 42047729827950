export enum ValueTypeEnum {
  'AVAILABLE' = 'var(--product-neutral-n500)',
  'ENTRY' = 'var(--product-success-s500)',
  'EXIT' = 'var(--product-danger-d500)',
}

export interface DetailsCardProps {
  label: string;
  headerIcon: string;
  currencyValue: string;
  valueType?: 'AVAILABLE' | 'ENTRY' | 'EXIT';
  storageName?: string;
  loading?: boolean;
  trackUserActions?: {
    show: () => void;
    hide: () => void;
  };
}
