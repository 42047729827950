import clsx from 'clsx';
import {
  BaseSyntheticEvent,
  MutableRefObject,
  SyntheticEvent,
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import {
  DialogContextProps,
  DialogProps,
  PopoverRootProps,
  PopoverTriggerProps,
} from './Dialog.interface';
import './Dialog.styles.scss';
import { setDialogPosition } from './helpers/setDialogPosition';

const initialState: DialogContextProps = {
  isOpen: false,
  setIsOpen: () => null,
  containerRect: null,
  position: 'auto',
  variant: 'popover',
};

const DialogContext = createContext<DialogContextProps>(initialState);

const DialogRoot = ({
  isOpen,
  setIsOpen,
  position = 'auto',
  className,
  children,
  variant,
}: PopoverRootProps) => {
  const [internalIsOpen, internalSetIsOpen] = useState(false);
  const [rect, setRect] = useState<DOMRect | null>(null);

  const ref = useRef() as MutableRefObject<HTMLDivElement>;

  useLayoutEffect(() => {
    if (!ref.current) return;

    const throttle = (callbackFn: () => void, limit: number) => {
      let wait = false;
      return function () {
        if (!wait) {
          callbackFn();
          wait = true;
          setTimeout(() => (wait = false), limit);
        }
      };
    };

    const handleResize = () => setRect(ref.current?.getBoundingClientRect());
    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', throttle(handleResize, 10), true);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', throttle(handleResize, 10), true);
    };
  }, [ref, isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen?.(false);
        internalSetIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [setIsOpen, internalIsOpen]);

  return (
    <DialogContext.Provider
      value={{
        isOpen: isOpen === undefined ? internalIsOpen : isOpen,
        setIsOpen: setIsOpen === undefined ? internalSetIsOpen : setIsOpen,
        containerRect: rect,
        position,
        variant,
      }}
      aria-expanded={internalIsOpen}
    >
      <div
        className={clsx(
          'dialog_component dialog_component_container',
          className
        )}
        ref={ref}
        aria-expanded={isOpen}
      >
        {children}
      </div>
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  const context = useContext(DialogContext);

  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogRoot');
  }

  return context;
};

const Trigger = ({ children, trackUserAction }: PopoverTriggerProps) => {
  const { isOpen, setIsOpen, variant } = useDialog();

  const handleTrigger = () => {
    trackUserAction && trackUserAction();
    variant === 'popover' && setIsOpen((prev) => !prev);
  };

  const handleOpenTooltip = () => {
    variant === 'tooltip' && setIsOpen(true);
  };

  const handleCloseTooltip = () => {
    variant === 'tooltip' && setIsOpen(false);
  };

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation?.();
    children.props.onClick?.();
    handleTrigger();
  };

  return cloneElement(children, {
    onMouseEnter: handleOpenTooltip,
    onMouseLeave: handleCloseTooltip,
    onClick: handleClick,
    'aria-haspopup': true,
    'aria-expanded': isOpen,
  });
};

const Content = ({ className, children, ...props }: DialogProps) => {
  const { isOpen, containerRect, position } = useDialog();

  const dialogRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleDialogClick = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setDialogPosition({ containerRect, dialogRef, position });
  }, [isOpen, containerRect, position]);

  return isOpen
    ? createPortal(
        <div
          className={clsx(
            'dialog_component dialog_component_content',
            className
          )}
          ref={dialogRef}
          onClick={handleDialogClick}
          role="dialog"
          {...props}
        >
          {children}
        </div>,
        document.body
      )
    : null;
};

export const Dialog = Object.assign(DialogRoot, {
  Trigger,
  Content,
});
