import { createSentryClickBreadcrumb } from '@lib/sentry';

enum WALLET_COMPANY_EVENT {
  VIEW_OPTIONS_WALLET_TRANSACTION = 'view_options_walletTransaction_walletCompany',
  SELECT_OPTION_AREA_PIX = 'select_option_areaPix_walletCompany',
  SELECT_OPTION_FUNDS_OUT_BOLETO = 'select_option_fundsOutBoleto_walletCompany',
  SELECT_OPTION_FUNDS_IN = 'select_option_fundsIn_walletCompany',
  SELECT_DATEPICKER_RANGE = 'select_datepicker_range_walletCompany',
  SHOW_BUTTON_FUNDS_IN = 'show_button_fundsIn_walletCompany',
  HIDE_BUTTON_FUNDS_IN = 'hide_button_fundsIn_walletCompany',
  SHOW_BUTTON_FUNDS_OUT = 'show_button_fundsOut_walletCompany',
  HIDE_BUTTON_FUNDS_OUT = 'hide_button_fundsOut_walletCompany',
  SHOW_BUTTON_CARD_DATA = 'show_button_cardData_walletCompany',
  HIDE_BUTTON_CARD_DATA = 'hide_button_cardData_walletCompany',
  CLOSE_BUTTON_AREA_PIX_DRAWER = 'close_button_areaPixDrawer_walletCompany',
  CLICK_BUTTON_AREA_PIX_TRANSFER = 'click_button_areaPixTransfer_walletCompany',
  CLICK_BUTTON_AREA_PIX_FUNDS_IN = 'click_button_areaPixFundsIn_walletCompany',
  CLICK_BUTTON_AREA_PIX_KEYS = 'click_button_areaPixKeys_walletCompany',
  CLOOSE_BUTTON_AREA_PIX_DRAWER = 'cloose_button_areaPixDrawer_walletCompany',
  CLICK_TAB_AREA_PIX_DEFAULT = 'click_tab_areaPixDefault_walletCompany',
  CLICK_TAB_AREA_PIX_QR = 'click_tab_areaPixQR_walletCompany',
  CLICK_TAB_AREA_PIX_COPY_PASTE = 'click_tab_areaPixCopyPaste_walletCompany',
  CLICK_BUTTON_PASTE_KEY = 'click_button_pasteKey_walletCompany',
  BACK_BUTTON_AREA_PIX_TRANSFER = 'back_button_areaPixTransfer_walletCompany',
  SUBMIT_BUTTON_PIX_INTENT_FUNDS_OUT = 'submit_button_PixIntentFundsOut_walletCompany',
  CANCEL_BUTTON_PIX_FUNDS_OUT = 'cancel_button_PixFundsOut_walletCompany',
  SUBMIT_BUTTON_PIX_FUNDS_OUT = 'submit_button_PixFundsOut_walletCompany',
  CREATE_BUTTON_NEW_PIX_KEY = 'create_button_newPixKey_walletCompany',
  VIEW_OPTIONS_PIX_KEY_TYPES = 'view_options_pixKeyTypes_walletCompany',
  CANCEL_BUTTON_NEW_PIX_KEY = 'cancel_button_newPixKey_walletCompany',
  SUBMIT_BUTTON_PIX_KEY_TYPE = 'submit_button_pixKeyType_walletCompany',
  COPY_BUTTON_PIX_KEY = 'copy_button_pixKey_walletCompany',
  CLOSE_BUTTON_FUNDS_OUT_BOLETO = 'close_button_fundsOutBoleto_walletCompany',
  BACK_BUTTON_FUNDS_OUT_BOLETO = 'back_button_fundsOutBoleto_walletCompany',
  CLICK_BUTTON_FUNDS_OUT_BOLETO = 'click_button_fundsOutBoleto_walletCompany',
  CANCEL_BUTTON_FUNDS_OUT_BOLETO = 'cancel_button_fundsOutBoleto_walletCompany',
  SUBMIT_BUTTON_FUNDS_OUT_BOLETO = 'submit_button_fundsOutBoleto_walletCompany',
}

export const walletCompanyEvents = {
  viewOptionsWalletTransactionWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.VIEW_OPTIONS_WALLET_TRANSACTION
    );
  },
  selectOptionAreaPixWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.SELECT_OPTION_AREA_PIX);
  },
  selectOptionFundsOutBoletoWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.SELECT_OPTION_FUNDS_OUT_BOLETO
    );
  },
  selectOptionFundsInWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.SELECT_OPTION_FUNDS_IN);
  },
  selectDatepickerRangeWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.SELECT_DATEPICKER_RANGE);
  },
  showButtonFundsInWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.SHOW_BUTTON_FUNDS_IN);
  },
  hideButtonFundsInWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.HIDE_BUTTON_FUNDS_IN);
  },
  showButtonFundsOutWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.SHOW_BUTTON_FUNDS_OUT);
  },
  hideButtonFundsOutWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.HIDE_BUTTON_FUNDS_OUT);
  },
  showButtonCardDataWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.SHOW_BUTTON_CARD_DATA);
  },
  hideButtonCardDataWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.HIDE_BUTTON_CARD_DATA);
  },
  closeButtonAreaPixDrawerWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLOSE_BUTTON_AREA_PIX_DRAWER
    );
  },
  clickButtonAreaPixTransferWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLICK_BUTTON_AREA_PIX_TRANSFER
    );
  },
  clickButtonAreaPixFundsInWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLICK_BUTTON_AREA_PIX_FUNDS_IN
    );
  },
  clickButtonAreaPixKeysWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLICK_BUTTON_AREA_PIX_KEYS
    );
  },
  clooseButtonAreaPixDrawerWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLOOSE_BUTTON_AREA_PIX_DRAWER
    );
  },
  clickTabAreaPixDefaultWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLICK_TAB_AREA_PIX_DEFAULT
    );
  },
  clickTabAreaPixQRWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.CLICK_TAB_AREA_PIX_QR);
  },
  clickTabAreaPixCopyPasteWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLICK_TAB_AREA_PIX_COPY_PASTE
    );
  },
  clickButtonPasteKeyWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.CLICK_BUTTON_PASTE_KEY);
  },
  backButtonAreaPixTransferWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.BACK_BUTTON_AREA_PIX_TRANSFER
    );
  },
  submitButtonPixIntentFundsOutWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.SUBMIT_BUTTON_PIX_INTENT_FUNDS_OUT
    );
  },
  cancelButtonPixFundsOutWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CANCEL_BUTTON_PIX_FUNDS_OUT
    );
  },
  submitButtonPixFundsOutWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.SUBMIT_BUTTON_PIX_FUNDS_OUT
    );
  },
  createButtonNewPixKeyWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.CREATE_BUTTON_NEW_PIX_KEY);
  },
  viewOptionsPixKeyTypesWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.VIEW_OPTIONS_PIX_KEY_TYPES
    );
  },
  cancelButtonNewPixKeyWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.CANCEL_BUTTON_NEW_PIX_KEY);
  },
  submitButtonPixKeyTypeWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.SUBMIT_BUTTON_PIX_KEY_TYPE
    );
  },
  copyButtonPixKeyWalletCompany: () => {
    createSentryClickBreadcrumb(WALLET_COMPANY_EVENT.COPY_BUTTON_PIX_KEY);
  },
  closeButtonFundsOutBoletoWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLOSE_BUTTON_FUNDS_OUT_BOLETO
    );
  },
  backButtonFundsOutBoletoWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.BACK_BUTTON_FUNDS_OUT_BOLETO
    );
  },
  clickButtonFundsOutBoletoWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CLICK_BUTTON_FUNDS_OUT_BOLETO
    );
  },
  cancelButtonFundsOutBoletoWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.CANCEL_BUTTON_FUNDS_OUT_BOLETO
    );
  },
  submitButtonFundsOutBoletoWalletCompany: () => {
    createSentryClickBreadcrumb(
      WALLET_COMPANY_EVENT.SUBMIT_BUTTON_FUNDS_OUT_BOLETO
    );
  },
};
