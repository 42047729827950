import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import emptyImg from '@assets/img/empty-card.svg';
import errorService from '@assets/img/error-service.svg';
import {
  ChangeStatusModal,
  HeaderCard,
  HeaderPage,
  ItemPreview,
} from '@components';
import { textBlockCard, textUnblockCard } from '@constants/cardStatusModalText';
import { CardTypeEnum } from '@enums/Card.enum';
import { StatusDescriptionEnum, StatusEnum } from '@enums/Status.enum';
import { useAuthentication, useQueryParams } from '@hooks';
import { CardItem, CardProps } from '@interfaces/Card.interfaces';
import { CardDetailsResponse } from '@interfaces/CardDetails.interface';
import { StatusType } from '@interfaces/Status.type';
import { WalletStatus } from '@interfaces/Wallets.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import { DrawerAuthentication, DrawerCreateCard } from '@modules/drawer';
import {
  Button,
  Card,
  Column,
  Dialog,
  DropDownList,
  EmptyState,
  Icon,
  Row,
  Search,
  Skeleton,
  Status,
  Toastr,
  Typography,
} from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { cardInformation } from '@services/cards/CardDetailsServices';
import { cardKeys } from '@services/cards/hooks';
import { useInfiniteCardListQuery } from '@services/cards/hooks/useInfinityCardListQuery';
import { getBalance } from '@services/organization';
import { useWalletQuery } from '@services/wallet/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { capitalizeFirstLetter } from '@utils/CapitalizeFirstLetter';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { normalizeString } from '@utils/normalizeString';
import { useTranslation } from 'react-i18next';
import { EmptyCardProps } from './Cards.interface';
import './Cards.styles.scss';
import { CardDetails } from './components/cardDetails';
import { CardTransactionsTable } from './components/cardTransactionsTable/CardTransactionsTable';
import { ChangeNameModal } from './components/ChangeNameModal';
import { initialStateCardList } from './constants/cards';
import { blockOrUnblockCard } from './services/CardsService';

const EmptyCard: React.FC<EmptyCardProps> = ({
  title,
  subTitle,
  button,
  openSide,
  image,
}: EmptyCardProps) => {
  return (
    <div className="empty_card">
      <EmptyState image={image} title={title} subtitle={subTitle}>
        {button && (
          <Button
            data-testid="addCard"
            onClick={() => openSide && openSide()}
            size="large"
            variant="primary"
            className="user_management_empty_list_button"
          >
            Adicionar cartão
          </Button>
        )}
      </EmptyState>
    </div>
  );
};

const LoadingState = () =>
  initialStateCardList.map((card) => {
    return (
      <ItemPreview
        variant="card"
        data={card}
        key={card.id}
        id={String(card.id)}
        onClick={() => ''}
        showCardDetails={''}
        isLoading
      />
    );
  });

export const Cards = () => {
  const organizationId = localStorage.getItem('organization') ?? '';

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { walletId, userId = '' } = useParams();
  const { setQueryParams } = useQueryParams();
  const { openDrawer } = useDrawer();

  //FILTERS
  const filterMenuRef = useRef<HTMLDivElement | null>(null);
  const [showFilterCard, setShowFilterCard] = useState(false);
  const [cardStatus, setCardStatus] = useState<StatusType>('ACTIVE');
  const [loadingCancelCard, setloadingCancelCard] = useState(false);
  const [query, setQuery] = useState('');
  const [searchCard, setSearchCard] = useState('');
  const [needInvalidateCardsQuery, setNeedInvalidateCardsQuery] =
    useState(false);

  const [selectedCard, setSelectedCard] = useState<CardItem>();

  const [loadingBalance, setLoadingBalance] = useState(false);
  const [updateNameCard, setUpdateNameCard] = useState<CardItem | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [balance, setBalance] = useState<number>(0);

  const [errorServiceCancelCard, setErrorServiceCancelCard] = useState(false);

  const [loadingCardDetails, setLoadingCardDetails] = useState(false);
  const [cardDetails, setCardDetails] = useState<CardDetailsResponse>();

  const location = useLocation();

  const isWalletDefaultCards = location.pathname.includes('/default/cards');
  const [showDetailsCard, setShowDetailsCard] = useState(false);

  const { hasPin } = useUser();

  const { controller, authSubmit, onAuthError, onAuthSuccess, pin } =
    useAuthentication();

  const { data: wallet, fetchStatus } = useWalletQuery({
    organizationId,
    walletId: walletId ?? '',
  });

  const {
    data: cardData,
    isLoading: isLoadingCards,
    error: errorCards,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isError,
  } = useInfiniteCardListQuery({
    status: cardStatus,
    walletId,
    query,
  });

  const breadCrumbElem = [
    {
      label: 'Carteiras',
      url: '/wallets',
      isDisabled: false,
    },
    {
      label: capitalizeFirstLetter(
        walletId === 'default'
          ? t('general.company-account')
          : wallet?.name ?? ''
      ),
      url: walletId === 'default' ? `/company` : `/wallets/${walletId}/geral`,
      isDisabled: false,
    },
    {
      label: 'Cartões',
      url: ``,
      isDisabled: true,
    },
  ];

  const getCardDetails = async () => {
    if (!selectedCard) return;

    if (!showDetailsCard) {
      eventsHandler.showButtonCardDataAdminCards();
    } else {
      eventsHandler.hideButtonCardDataAdminCards();
    }

    if (cardDetails === undefined) {
      return authSubmit(async () => {
        setLoadingCardDetails(true);

        try {
          const cardDetails = await cardInformation({
            cardId: selectedCard.id.toString(),
            walletId: selectedCard.wallet,
            pin,
          });

          customerEvents.cardDetails({
            id: selectedCard.id.toString(),
            name: cardDetails?.name,
            type: selectedCard.type,
          });

          setCardDetails(cardDetails);
          setShowDetailsCard(true);
          setLoadingCardDetails(false);
          onAuthSuccess();
          setShowDetailsCard(!showDetailsCard);
        } catch (error) {
          onAuthError(error as ApiErrorPin);
          setShowDetailsCard(false);
          setLoadingCardDetails(false);
          setCardDetails(undefined);

          if (!hasPin) {
            Toastr.error('Erro ao carregar dados!');
          }
        }
      });
    }

    setShowDetailsCard(!showDetailsCard);
  };

  const openCardInfo = async (card: CardItem) => {
    customerEvents.cardViewed({
      id: card.id.toString(),
      name: card.name,
      type: card.type,
    });

    if (isWalletDefaultCards) {
      eventsHandler.selectOptionCardListCardsWallet();
    } else {
      eventsHandler.selectOptionCardListAdminCards();
    }

    setCardDetails(undefined);
    setShowDetailsCard(false);
    setSelectedCard(card);
    setBalance(0);
    setQueryParams({
      startDate: undefined,
      endDate: undefined,
      financialImpact: undefined,
    });
  };

  const closeCancelModal = async () => {
    const { status } = selectedCard as CardItem;

    if (status === StatusEnum.ACTIVE) {
      if (isWalletDefaultCards) {
        eventsHandler.cancelModalBlockCardCardsWallet();
      } else {
        eventsHandler.cancelModalBlockCardAdminCards();
      }
    }

    setOpenConfirmModal(false);
    setErrorServiceCancelCard(false);
  };

  const blockUnblockCard = async () => {
    const { id, wallet, status } = selectedCard as CardItem;

    const cardStatusText =
      status === StatusEnum.ACTIVE ? 'bloqueado' : 'desbloqueado';

    setloadingCancelCard(true);
    setErrorServiceCancelCard(false);

    if (status === StatusEnum.ACTIVE) {
      if (isWalletDefaultCards) {
        eventsHandler.submitModalBlockCardCardsWallet();
      } else {
        eventsHandler.submitModalBlockCardAdminCards();
      }
    }

    if (status === StatusEnum.BLOCKED) {
      if (isWalletDefaultCards) {
        eventsHandler.submitModalUnlockCardCardsWallet();
      } else {
        eventsHandler.submitModalUnlockCardAdminCards();
      }
    }

    try {
      await blockOrUnblockCard(
        id ?? 0,
        wallet,
        status === StatusEnum.ACTIVE ? 'block' : 'unblock'
      );
      Toastr.success(`Cartão ${cardStatusText} com sucesso!`);

      if (StatusEnum.ACTIVE === status) {
        customerEvents.cardBlocked({
          id: id.toString(),
          name: selectedCard?.name ?? '',
          type: selectedCard?.type as CardTypeEnum,
        });
      } else {
        customerEvents.cardUnblocked({
          id: id.toString(),
          name: selectedCard?.name ?? '',
          type: selectedCard?.type as CardTypeEnum,
        });
      }

      setNeedInvalidateCardsQuery(true);

      queryClient.setQueryData(
        cardKeys.list({
          status: cardStatus,
          walletId,
          query,
        }),
        (oldData: { pageParams: string[]; pages: CardProps[] }) => {
          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                items: page.items.filter((item) => item.id !== id),
              };
            }),
          };
        }
      );

      setloadingCancelCard(false);
      setOpenConfirmModal(false);
    } catch {
      setloadingCancelCard(false);
      setErrorServiceCancelCard(true);
    }
  };

  const textChangeStatusCardModal = () => {
    const actionLabel =
      selectedCard?.status === StatusEnum.ACTIVE ? 'bloquear' : 'desbloquear';

    const modalBody =
      selectedCard?.status === StatusEnum.ACTIVE
        ? textBlockCard
        : textUnblockCard;

    return {
      ...modalBody,
      modalTitle: `Deseja ${actionLabel} o cartão com o final ${selectedCard?.panMasked.slice(
        -4
      )}`,
    };
  };

  const invalidateCardListQuery = () => {
    if (needInvalidateCardsQuery) {
      queryClient.invalidateQueries({
        queryKey: cardKeys.lists(),
      });

      setNeedInvalidateCardsQuery(false);
    }
  };

  const clickItemToFilter = (status: StatusType) => {
    if (cardStatus !== status) {
      invalidateCardListQuery();

      setCardStatus(status);
    }
    setShowFilterCard(false);
  };

  const dropDownMenuList = [
    {
      icon: 'fa-solid fa-circle active',
      label: 'Ativo',
      action: () => clickItemToFilter('ACTIVE'),
    },
    {
      icon: 'fa-solid fa-circle cancel',
      label: 'Bloqueado',
      action: () => clickItemToFilter('BLOCKED'),
    },
  ];

  const renderCardActionsList = useCallback(() => {
    const { status, type } = selectedCard as CardItem;

    const list = [
      {
        label: t('cards.menu-actions.change-apelido'),
        action: () => {
          setUpdateNameCard(selectedCard ?? null);
          if (isWalletDefaultCards) {
            eventsHandler.selectOptionChangeCardNameCardsWallet();
          } else {
            eventsHandler.selectOptionChangeCardNameAdminCards();
          }
        },
      },
    ];

    if (status === StatusEnum.ACTIVE && type === CardTypeEnum.EMBOSSED) {
      list.push({
        label: t('cards.menu-actions.change-pin'),
        action: () =>
          openDrawer(
            <DrawerCreateCard
              cardId={selectedCard?.id.toString()}
              walletId={selectedCard?.wallet}
            />
          ),
      });
    }

    list.push({
      label: t(
        `cards.menu-actions.${
          status === StatusEnum.ACTIVE ? 'block-card' : 'unblock-card'
        }`
      ),
      action: () => {
        if (status === StatusEnum.ACTIVE) {
          if (isWalletDefaultCards) {
            eventsHandler.selectOptionBlockCardCardsWallet();
          } else {
            eventsHandler.selectOptionBlockCardAdminCards();
          }
        }

        if (status !== StatusEnum.ACTIVE) {
          if (isWalletDefaultCards) {
            eventsHandler.selectOptionUnlockCardCardsWallet();
          } else {
            eventsHandler.selectOptionUnlockCardAdminCards();
          }
        }

        setOpenConfirmModal(true);
      },
    });

    return list;
  }, [selectedCard, t, isWalletDefaultCards, openDrawer]);

  const handleSearchCards = (value: string) => {
    invalidateCardListQuery();

    setQuery(normalizeString(value.toLowerCase()));
  };

  const handleClearSearchCards = () => {
    invalidateCardListQuery();

    setSearchCard('');
  };

  useEffect(() => {
    if (selectedCard) {
      const updatedCard = cardData?.find((card) => card.id === selectedCard.id);
      if (updatedCard) {
        setSelectedCard(updatedCard);
      }
    }
  }, [cardData, selectedCard]);

  useEffect(() => {
    setSelectedCard(undefined);
    setCardStatus('ACTIVE');
  }, [walletId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      showFilterCard &&
        filterMenuRef.current &&
        !filterMenuRef.current.contains(event.target as Node) &&
        setShowFilterCard(!showFilterCard);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFilterCard]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getBallanceWallet = async (walletId: string) => {
      setLoadingBalance(true);
      try {
        const balance = await getBalance(walletId);
        setBalance(balance.totalBalance);
        setLoadingBalance(false);
      } catch {
        setLoadingBalance(false);
        Toastr.error('Erro ao carregar o saldo disponível da carteira!');
      }
    };

    selectedCard && getBallanceWallet(selectedCard.wallet);

    return () => {
      source.cancel();
    };
  }, [selectedCard, userId, walletId]);

  return (
    <>
      <div className="card_page">
        <HeaderPage
          title={t(walletId ? 'cards.title' : 'cards.title-org')}
          subTitle={t(walletId ? '' : 'cards.subtitle-org', '')}
          breadcrumbList={walletId ? breadCrumbElem : []}
          isLoading={fetchStatus === 'fetching'}
        >
          <Button
            onClick={() => {
              eventsHandler.openDrawerCreateCardAdminCards();
              openDrawer('create-card', { walletId });
            }}
            size={'large'}
            data-testid="newCard"
            disabled={wallet?.status === WalletStatus.DEACTIVATED}
          >
            Novo
            <Icon size="large">
              <i className="fa-solid fa-plus" />
            </Icon>
          </Button>
        </HeaderPage>

        <Row>
          <Column lg={4} md={12}>
            <Card>
              <Search
                variant="animated"
                value={searchCard}
                setValue={setSearchCard}
                label={t(
                  `cards.${walletId ? 'cards-list-title' : 'all-cards-title'}`
                )}
                queryFn={handleSearchCards}
                onClear={handleClearSearchCards}
                delay={500}
                trackUserAction={() => {
                  if (isWalletDefaultCards) {
                    eventsHandler.searchButtonCardListCardsWallet();
                  } else {
                    eventsHandler.searchButtonCardListAdminCards();
                  }
                }}
                actions={
                  <div ref={filterMenuRef}>
                    <Button
                      data-testid="buttonFilter"
                      size="medium"
                      variant="tertiary"
                      className="card_page_button_finder"
                      onClick={() => {
                        if (isWalletDefaultCards) {
                          eventsHandler.filterButtonCardListCardsWallet();
                        } else {
                          eventsHandler.filterButtonCardListAdminCards();
                        }

                        setShowFilterCard(!showFilterCard);
                      }}
                    >
                      <Icon size="xlarge">
                        <i className="fa-regular fa-filter" />
                      </Icon>
                    </Button>
                    {showFilterCard && <DropDownList list={dropDownMenuList} />}
                  </div>
                }
              />

              <div className="card_list">
                {!isLoadingCards &&
                  !isError &&
                  !!cardData &&
                  cardData?.length < 1 && (
                    <div className="card_list_empty">
                      <EmptyCard
                        title={t('cards.empty-state-block.title')}
                        subTitle={t('cards.empty-state-block.subtitle')}
                        button={true}
                        openSide={() => openDrawer('create-card', { walletId })}
                        image={emptyImg}
                      />
                    </div>
                  )}

                {cardData &&
                  (!isFetching || isFetchingNextPage) &&
                  cardData.map((card) => {
                    return (
                      <ItemPreview
                        variant="card"
                        data={card}
                        key={card.id}
                        id={String(card.id)}
                        onClick={() => {
                          openCardInfo(card);
                        }}
                        showCardDetails={selectedCard?.id.toString()}
                        isLoading={isLoadingCards}
                      />
                    );
                  })}

                {(isLoadingCards || isFetching) && !isFetchingNextPage && (
                  <LoadingState />
                )}

                {cardData && hasNextPage && (
                  <div className="button_more_item">
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={() => fetchNextPage()}
                      isLoading={isFetchingNextPage}
                    >
                      Carregar mais
                    </Button>
                  </div>
                )}

                {errorCards && (
                  <div className="card_list_empty">
                    <EmptyCard
                      title="Oops! Algo deu errado"
                      subTitle="O serviço não está disponível no momento. Por favor, tente novamente mais tarde."
                      button={false}
                      openSide={() => openDrawer('create-card', { walletId })}
                      image={errorService}
                    />
                  </div>
                )}
              </div>
            </Card>
          </Column>
          <Column lg={8} md={12}>
            <Card>
              <HeaderCard>
                <div className="card_details_actions">
                  <div className="flex flex-row gap-4">
                    <Typography tag="h3" color="var(--product-neutral-n500)">
                      {t('cards.card-details-title')}
                    </Typography>

                    {selectedCard && (
                      <Status status={selectedCard?.status}>
                        {StatusDescriptionEnum[selectedCard?.status]}
                      </Status>
                    )}
                  </div>

                  {selectedCard && (
                    <div className="flex gap-2">
                      {
                        <>
                          {selectedCard.type !== 'EMBOSSED' && (
                            <Button
                              size="medium"
                              variant="secondary"
                              onClick={() => getCardDetails()}
                              data-testid="showDetailsCard"
                            >
                              {showDetailsCard
                                ? 'Ocultar dados'
                                : 'Exibir dados'}
                              <Icon size="large">
                                <i
                                  className={`fa-regular ${
                                    showDetailsCard
                                      ? 'fa-eye-slash'
                                      : 'fa-eye eye'
                                  }`}
                                ></i>
                              </Icon>
                            </Button>
                          )}
                        </>
                      }

                      <Dialog variant="popover">
                        <Dialog.Trigger
                          trackUserAction={() => {
                            if (isWalletDefaultCards) {
                              eventsHandler.viewOptionsCardActionsCardsWallet();
                            } else {
                              eventsHandler.viewOptionsCardActionsAdminCards();
                            }
                          }}
                        >
                          <Button
                            data-testid="button-card-actions"
                            size="medium"
                            variant="secondary"
                            className="wallet-row_button_finder !w-8"
                            data-column-action
                          >
                            <Icon size="xlarge">
                              <i className="fa-regular fa-ellipsis-vertical" />
                            </Icon>
                          </Button>
                        </Dialog.Trigger>
                        <Dialog.Content>
                          <DropDownList list={renderCardActionsList()} />
                        </Dialog.Content>
                      </Dialog>
                    </div>
                  )}
                </div>
              </HeaderCard>

              <div className="card_details">
                {selectedCard && (
                  <div className="card_list_details">
                    <div>
                      <CardDetails
                        showDetailsCard={showDetailsCard}
                        cardDetails={cardDetails}
                        isLoading={loadingCardDetails}
                        cardNumber={selectedCard.panMasked.slice(-4)}
                        cardType={selectedCard.type}
                        walletId={selectedCard.wallet}
                        ownerName={selectedCard.ownerName}
                      />
                    </div>
                    <div>
                      <Typography
                        tag="p"
                        weight="p1"
                        color="var(--product-neutral-n500)"
                      >
                        Saldo disponível:
                      </Typography>

                      <div className="card_list_details_limits">
                        <div className="card_list_details_limits_total">
                          <Typography
                            tag="p"
                            weight="p3"
                            color="var(--product-neutral-n300)"
                          >
                            Saldo total
                          </Typography>
                          <Skeleton width={80} isLoading={loadingBalance}>
                            <Typography
                              tag="p"
                              weight="p1"
                              color="var(--product-neutral-n700)"
                            >
                              {calcCurrencyValue(balance, 'BRL')}
                            </Typography>
                          </Skeleton>
                        </div>
                      </div>
                    </div>

                    <CardTransactionsTable
                      cardId={
                        selectedCard?.id
                          ? selectedCard?.id.toString()
                          : undefined
                      }
                      walletId={walletId}
                    />
                  </div>
                )}

                {!selectedCard && (
                  <div className="card_list_empty">
                    <EmptyCard
                      title="Nenhum cartão selecionado"
                      subTitle="Você não possui cartão selecionado"
                      button={false}
                      image={emptyImg}
                    />
                  </div>
                )}
              </div>
            </Card>
          </Column>
        </Row>
      </div>

      {openConfirmModal && (
        <ChangeStatusModal
          open={openConfirmModal}
          closeModal={closeCancelModal}
          changeStatus={blockUnblockCard}
          modalText={textChangeStatusCardModal()}
          errorService={errorServiceCancelCard}
          isLoading={loadingCancelCard}
        />
      )}

      {updateNameCard && (
        <ChangeNameModal card={updateNameCard} setCard={setUpdateNameCard} />
      )}

      <DrawerAuthentication
        {...controller}
        onSubmit={getCardDetails}
        isPending={loadingCardDetails}
      />
    </>
  );
};
