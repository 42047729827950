import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { realm } from '@constants/endpoints';
import { ICONS } from '@constants/icons';
import { eventsHandler } from '@lib';
import { LoggedNavbar } from '@portao3-web/ui';
import { useAuth, useDrawer, useUser } from '@providers';
import { useTranslation } from 'react-i18next';
import { MemberGetMember } from '../memberGetMember';

export const StandardLoggedNavbar = () => {
  const { isRealmAdmin, email, firstName, isAdmin, hasPin, hasMfa } = useUser();
  const { openDrawer } = useDrawer();

  const { logout } = useAuth();
  const history = useNavigate();
  const { t } = useTranslation();
  const pathname = window?.location.pathname;

  const hasAllSecurity = hasPin && hasMfa;
  const hasNotification = !hasAllSecurity && pathname !== '/organizations';

  const dropDownMenuList = useMemo(() => {
    const items = [
      {
        icon: ICONS.shield,
        label: t('auth.security-and-limits'),
        action: () => openDrawer('security'),
        notification: !hasAllSecurity,
      },
      {
        icon: ICONS.buildingCircleArrowRight,
        label: t('general.my-organizations'),
        action: () => history('/organizations'),
        trackUserAction: () => eventsHandler.viewOptionsAvatarGlobalTopbar(),
      },
      {
        icon: ICONS.billing,
        label: t('general.invoices'),
        action: () => history(`/preferences?tab=invoices`),
        trackUserAction: () => eventsHandler.clickButtonChangeOrgGlobalTopbar(),
      },
      {
        icon: ICONS.cog,
        label: t('general.organizations-settings'),
        action: () => history(`/preferences?tab=customfields`),
        trackUserAction: () => eventsHandler.clickButtonChangeOrgGlobalTopbar(),
      },
      {
        icon: ICONS.billingShield,
        label: t('general.enterprise-settings'),
        action: () => history(`/${realm()}/companies`),
        trackUserAction: () => eventsHandler.clickButtonChangeOrgGlobalTopbar(),
      },
      {
        icon: ICONS.arrowBrackets,
        label: t('general.logout'),
        action: () => logout(),
        trackUserAction: () => eventsHandler.clickButtonLogoutGlobalTopbar(),
      },
    ];

    if (!isRealmAdmin) {
      items.splice(4, 1);
    }

    if (!isAdmin) {
      items.splice(2, 1);
      items.splice(2, 1);
    }

    return items;
  }, [isRealmAdmin, history, logout, t, isAdmin, openDrawer, hasAllSecurity]);

  const dropDownOrganizationsMenuList = useMemo(() => {
    const items = [
      {
        icon: ICONS.billingShield,
        label: t('general.enterprise-settings'),
        action: () => history(`/${realm()}/companies`),
        trackUserAction: () => eventsHandler.clickButtonChangeOrgGlobalTopbar(),
      },
      {
        icon: ICONS.arrowBrackets,
        label: t('general.logout'),
        action: () => logout(),
        trackUserAction: () => eventsHandler.clickButtonLogoutGlobalTopbar(),
      },
    ];

    if (!isRealmAdmin) {
      items.splice(0, 1);
    }

    return items;
  }, [history, isRealmAdmin, logout, t]);

  const dropDownMenu =
    pathname === '/organizations'
      ? dropDownOrganizationsMenuList
      : dropDownMenuList;

  return (
    <div className="ml-auto flex gap-4 px-8">
      <MemberGetMember />
      <LoggedNavbar
        hasNotification={hasNotification}
        dropDownMenuList={dropDownMenu}
        notificationsList={[]}
        avatarImage={''}
        user={{
          name: firstName,
          email,
        }}
      />
    </div>
  );
};
