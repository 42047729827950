import { useTranslation } from 'react-i18next';

import { BatchPaymentStatus } from '@enums/BatchPayment.enum';
import { useQueryParams } from '@hooks';
import { Option } from '@interfaces/CustomFields.interfaces';
import { TableMultiSelectFilter } from '@portao3-web/ui';

export const BatchStatusFilter = () => {
  const { t } = useTranslation();
  const { queryParams, setQueryParams } = useQueryParams();

  const options: Option[] = [
    {
      label: t('status.PAID'),
      value: BatchPaymentStatus.COMPLETED_SUCCESSFULLY,
    },
    {
      label: t('status.PARTIALLY_PAID'),
      value: BatchPaymentStatus.COMPLETED_WITH_ERROR,
    },
    {
      label: t('status.PROCESSING'),
      value: BatchPaymentStatus.PROCESSING,
    },
    {
      label: t('status.ERROR'),
      value: BatchPaymentStatus.ERROR,
    },
  ];

  return (
    <TableMultiSelectFilter
      selection={queryParams.getAll('status')}
      setSelection={(value) => setQueryParams({ status: value })}
      title={t('general.status')}
      labels={{
        clearFilters: t('general.clear-filters'),
        notFound: t('general.not-found'),
        selected: t('general.selected', {
          count: queryParams.getAll('status').length,
        }),
      }}
      options={options}
    />
  );
};
