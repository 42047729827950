import { useEffect, useRef, useState } from 'react';

import { useAuthentication } from '@hooks';
import { customerEvents, eventsHandler } from '@lib';
import { DrawerAuthentication } from '@modules/drawer';
import { Button, Icon, Skeleton, Toastr, Typography } from '@portao3-web/ui';
import { useUser } from '@providers';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { cardInformation } from '@services/cards/CardDetailsServices';
import { formatCardNumber } from '@utils/formatCardNumber';
import { useTranslation } from 'react-i18next';
import {
  CardDetailsProps,
  CardDetailsResponse,
} from '../../interfaces/CardDetails.interface';
import './CardDetails.styles.scss';

interface CopyComponentProps {
  children: React.ReactNode | React.ReactNode[];
  showDetailsCard: boolean;
  cardDetails: CardDetailsResponse | undefined;
  id: string;
}

export const CopyComponent = ({
  children,
  cardDetails,
  showDetailsCard,
  id,
}: CopyComponentProps) => {
  const [copyFeedbackVisible, setCopyFeedbackVisible] = useState(false);
  const { t } = useTranslation();
  const textElement = useRef<HTMLSpanElement>(null);

  const copyToClipboard = async () => {
    const elem = textElement.current?.textContent;

    if (!elem) {
      Toastr.error(t('error.text-not-found'));

      return;
    }

    try {
      await navigator.clipboard.writeText(elem.replaceAll(' ', '')).then(() => {
        setCopyFeedbackVisible(true);
        setTimeout(() => {
          setCopyFeedbackVisible(false);
        }, 1000);
      });
    } catch (error) {
      Toastr.error(t('error.need-permission-clipboard'));
    }
  };

  return (
    <div
      onClick={() =>
        cardDetails && showDetailsCard ? copyToClipboard() : null
      }
      className="copy_component"
      data-testid={id}
    >
      <Typography
        tag="p"
        className={`card_number ${
          cardDetails && showDetailsCard ? `card_number--show-details` : ''
        }`}
        color="var(--product-neutral-n500)"
        weight="p2"
      >
        <span ref={textElement} className="span">
          {children}
        </span>
      </Typography>
      <div className="p-relative">
        <Typography
          tag="p"
          className={`card_number ${
            cardDetails && showDetailsCard ? `card_number--show-details` : ''
          }`}
          color="var(--product-neutral-n500)"
          weight="p2"
        >
          {cardDetails && showDetailsCard && (
            <i className="fa-regular fa-clone copy"></i>
          )}
          {copyFeedbackVisible && (
            <div className="copy_feedback--cardNumber">
              <i className="fa-regular fa-clone copy"></i>
            </div>
          )}
        </Typography>
      </div>
    </div>
  );
};

export const CardDetails = ({
  cardId,
  cardNumber,
  cardType,
  verticalMode = false,
  showTypeCard = true,
  walletId = 'default',
  trackUserActions,
}: CardDetailsProps) => {
  const { t } = useTranslation();
  const { hasPin } = useUser();

  const [showDetailsCard, setShowDetailsCard] = useState(false);
  const [loadingCardDetails, setLoadingCardDetails] = useState(false);
  const [cardDetails, setCardDetails] = useState<CardDetailsResponse>();

  useEffect(() => {
    setShowDetailsCard(false);
    setCardDetails(undefined);
  }, [cardId]);

  const { controller, authSubmit, onAuthError, onAuthSuccess, pin } =
    useAuthentication();

  const getCardDetails = async (cardId: number) => {
    if (!showDetailsCard) {
      eventsHandler.showButtonCardDataAdminCards();
    } else {
      eventsHandler.hideButtonCardDataAdminCards();
    }

    showDetailsCard ? trackUserActions?.hide() : trackUserActions?.show();

    if (cardDetails === undefined) {
      return authSubmit(async () => {
        setLoadingCardDetails(true);

        try {
          const cardDetails = await cardInformation({
            cardId: cardId.toString(),
            walletId,
            pin,
          });

          customerEvents.cardDetails({
            id: cardId.toString(),
            name: cardDetails?.name,
            type: cardType,
          });
          setCardDetails(cardDetails);
          setShowDetailsCard(true);
          setLoadingCardDetails(false);
          onAuthSuccess();
          setShowDetailsCard(!showDetailsCard);
        } catch (error) {
          onAuthError(error as ApiErrorPin);
          setShowDetailsCard(false);
          setLoadingCardDetails(false);

          if (!hasPin) {
            Toastr.error('Erro ao carregar dados!');
          }
        }
      });
    }

    setShowDetailsCard(!showDetailsCard);
  };

  const cardDetailsClass = verticalMode ? 'card_details_vertical_mode' : '';

  return (
    <div className={`card_details_show_details ${cardDetailsClass}`}>
      <div className="box_details_card">
        {cardType !== 'EMBOSSED' && (
          <div className="button-nowrap">
            <Button
              size="medium"
              variant="secondary"
              onClick={() => getCardDetails(cardId)}
              data-testid="showDetailsCard"
            >
              {showDetailsCard ? 'Ocultar dados' : 'Exibir dados'}
              <Icon size="large">
                <i
                  className={`fa-regular ${
                    showDetailsCard ? 'fa-eye-slash' : 'fa-eye eye'
                  }`}
                ></i>
              </Icon>
            </Button>
          </div>
        )}
        <div className="card_details_show_details_info_card card_details_vertical_mode_info_card">
          <Typography tag="p" className="card_number_text" weight="p3">
            Nº do cartão
          </Typography>
          <Skeleton width={160} isLoading={loadingCardDetails || !cardNumber}>
            <CopyComponent
              cardDetails={cardDetails}
              showDetailsCard={showDetailsCard}
              id="card-pan"
            >
              {cardDetails && showDetailsCard
                ? formatCardNumber(cardDetails.pan)
                : `**** **** **** ${cardNumber}`}
            </CopyComponent>
          </Skeleton>
        </div>
        <div className="card_details_show_details_info_card card_details_vertical_mode_info_card">
          <Typography tag="p" className="card_number_text" weight="p3">
            Data de expiração
          </Typography>
          <Skeleton width={30} isLoading={loadingCardDetails || !cardNumber}>
            <CopyComponent
              cardDetails={cardDetails}
              showDetailsCard={showDetailsCard}
              id="card-expiryDate"
            >
              {cardDetails && showDetailsCard
                ? cardDetails.expiryDate
                : '**/**'}
            </CopyComponent>
          </Skeleton>
        </div>
        <div className="card_details_show_details_info_card card_details_vertical_mode_info_card">
          <Typography tag="p" className="card_number_text" weight="p3">
            Código de Segurança
          </Typography>
          <div className="card_details_show_details_info_card_cvv card_details_vertical_mode_info_card">
            <Skeleton width={1} isLoading={loadingCardDetails || !cardNumber}>
              <CopyComponent
                cardDetails={cardDetails}
                showDetailsCard={showDetailsCard}
                id="card-cvv"
              >
                {cardDetails && showDetailsCard ? cardDetails.cvv : '***'}
              </CopyComponent>
            </Skeleton>
          </div>
        </div>
        {showTypeCard && (
          <div className="card_details_show_details_info_card card_details_vertical_mode_info_card">
            <Typography tag="p" className="card_number_text" weight="p3">
              Tipo
            </Typography>
            <Skeleton width={35} isLoading={!cardNumber}>
              <div className="card_details_show_details_info_card_cvv">
                <Typography tag="p" className="card_number" weight="p2">
                  {t(`general.${cardType.toLowerCase()}`, '')}
                </Typography>
              </div>
            </Skeleton>
          </div>
        )}

        <DrawerAuthentication
          {...controller}
          onSubmit={() => getCardDetails(cardId)}
          isPending={loadingCardDetails}
        />
      </div>
    </div>
  );
};
