import { useEffect, useState } from 'react';
import { Button } from '../button/Button';
import { Icon } from '../icon/Icon';
import { Skeleton } from '../skeleton/Skeleton';
import { Typography } from '../typography/Typography';
import { DetailsCardProps, ValueTypeEnum } from './DetailsCard.interface';
import './DetailsCard.styles.scss';

export const DetailsCard = ({
  label,
  headerIcon,
  currencyValue,
  valueType = 'AVAILABLE',
  loading = false,
  storageName = '',
  trackUserActions,
}: DetailsCardProps) => {
  const [showValue, setShowValue] = useState<boolean>(false);

  const handleCurrencyValue = () => {
    showValue ? trackUserActions?.hide() : trackUserActions?.show();
    setShowValue(!showValue);
    localStorage.setItem(storageName, JSON.stringify(!showValue));
  };

  useEffect(() => {
    if (storageName && storageName !== '') {
      const storageData = localStorage.getItem(storageName);
      setShowValue(storageData && JSON.parse(storageData));
    }
  }, [storageName]);

  return (
    <div className="details_card">
      <div className="details_card_header">
        <Typography tag="p" weight="p1" color="var(--product-neutral-n80)">
          {label}
        </Typography>
        <Icon size="xlarge">
          <i
            data-testid="header-icon"
            className={headerIcon}
            style={{ color: 'var(--product-primary-p500)' }}
          />
        </Icon>
      </div>
      <div className="details_card_value details_card_value--spacing">
        <Skeleton width={'25%'} isLoading={loading}>
          <Typography
            tag="h3"
            color={ValueTypeEnum[valueType as keyof typeof ValueTypeEnum]}
          >
            {showValue ? currencyValue : '*****'}
          </Typography>
        </Skeleton>
        <Button
          size={'small'}
          variant="tertiary"
          className="details_card_button"
          onClick={handleCurrencyValue}
          data-testid="showValueButton"
          data-bs-placement="bottom"
          title={`${showValue ? 'Ocultar' : 'Exibir'} valor`}
        >
          <Icon size="xlarge">
            <i
              data-testid="show-hide-value-icon"
              className={`fa-regular ${showValue ? 'fa-eye-slash' : 'fa-eye'}`}
              color="var(--product-neutral-n100)"
            />
          </Icon>
        </Button>
      </div>
    </div>
  );
};
