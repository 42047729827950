import { createSentryClickBreadcrumb } from '@lib/sentry';

enum ADMIN_CARDS_EVENT {
  OPEN_DRAWER_CREATE_CARD_ADMIN_CARDS = 'open_drawer_createCard_adminCards',
  SEARCH_BUTTON_CARD_LIST_ADMIN_CARDS = 'search_button_cardList_adminCards',
  FILTER_BUTTON_CARD_LIST_ADMIN_CARDS = 'filter_button_cardList_adminCards',
  SELECT_OPTION_CARD_LIST_ADMIN_CARDS = 'select_option_cardList_adminCards',
  VIEW_OPTIONS_CARD_ACTIONS_ADMIN_CARDS = 'view_options_cardActions_adminCards',
  SELECT_OPTION_CHANGE_CARD_NAME_ADMIN_CARDS = 'select_option_changeCardName_adminCards',
  SELECT_OPTION_BLOCK_CARD_ADMIN_CARDS = 'select-option_blockCard_adminCards',
  SELECT_OPTION_UNLOCK_CARD_ADMIN_CARDS = 'option_unlockCard_adminCards',
  SHOW_BUTTON_CARD_DATA_ADMIN_CARDS = 'show_button_cardData_adminCards',
  HIDE_BUTTON_CARD_DATA_ADMIN_CARDS = 'hide_button_cardData_adminCards',
  CLOSE_DRAWER_CREATE_CARD_ADMIN_CARDS = 'close_drawer_createCard_adminCards',
  SELECT_CARD_TYPE_PHYSICAL_ADMIN_CARDS = 'select_cardType_physical_adminCards',
  SELECT_CARD_TYPE_VIRTUAL_ADMIN_CARDS = 'select_cardType_virtual_adminCards',
  CANCEL_DRAWER_CARD_CREATION_ADMIN_CARDS = 'cancel_drawer_cardCreation_adminCards',
  SUBMIT_DRAWER_CARD_CREATION_ADMIN_CARDS = 'submit_drawer_cardCreation_adminCards',
  CANCEL_MODAL_EDIT_CARD_NAME_ADMIN_CARDS = 'cancel_modal_editCardName_adminCards',
  SUBMIT_MODAL_EDIT_CARD_NAME_ADMIN_CARDS = 'submit_modal_editCardName_adminCards',
  CANCEL_MODAL_BLOCK_CARD_ADMIN_CARDS = 'cancel_modal_blockCard_adminCards',
  CANCEL_MODAL_UNLOCK_CARD_ADMIN_CARDS = 'cancel_modal_unlockCard_adminCards',
  SUBMIT_MODAL_BLOCK_CARD_ADMIN_CARDS = 'submit_modal_blockCard_adminCards',
  SUBMIT_MODAL_UNLOCK_CARD_ADMIN_CARDS = 'submit_modal_unlockCard_adminCards',
}

export const adminCardsEvents = {
  openDrawerCreateCardAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.OPEN_DRAWER_CREATE_CARD_ADMIN_CARDS
    );
  },
  searchButtonCardListAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SEARCH_BUTTON_CARD_LIST_ADMIN_CARDS
    );
  },
  filterButtonCardListAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.FILTER_BUTTON_CARD_LIST_ADMIN_CARDS
    );
  },
  selectOptionCardListAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SELECT_OPTION_CARD_LIST_ADMIN_CARDS
    );
  },
  viewOptionsCardActionsAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.VIEW_OPTIONS_CARD_ACTIONS_ADMIN_CARDS
    );
  },
  selectOptionChangeCardNameAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SELECT_OPTION_CHANGE_CARD_NAME_ADMIN_CARDS
    );
  },
  selectOptionBlockCardAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SELECT_OPTION_BLOCK_CARD_ADMIN_CARDS
    );
  },
  selectOptionUnlockCardAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SELECT_OPTION_UNLOCK_CARD_ADMIN_CARDS
    );
  },
  showButtonCardDataAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SHOW_BUTTON_CARD_DATA_ADMIN_CARDS
    );
  },
  hideButtonCardDataAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.HIDE_BUTTON_CARD_DATA_ADMIN_CARDS
    );
  },
  closeDrawerCreateCardAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.CLOSE_DRAWER_CREATE_CARD_ADMIN_CARDS
    );
  },
  selectCardTypePhysicalAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SELECT_CARD_TYPE_PHYSICAL_ADMIN_CARDS
    );
  },
  selectCardTypeVirtualAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SELECT_CARD_TYPE_VIRTUAL_ADMIN_CARDS
    );
  },
  cancelDrawerCardCreationAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.CANCEL_DRAWER_CARD_CREATION_ADMIN_CARDS
    );
  },
  submitDrawerCardCreationAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SUBMIT_DRAWER_CARD_CREATION_ADMIN_CARDS
    );
  },
  cancelModalEditCardNameAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.CANCEL_MODAL_EDIT_CARD_NAME_ADMIN_CARDS
    );
  },
  submitModalEditCardNameAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SUBMIT_MODAL_EDIT_CARD_NAME_ADMIN_CARDS
    );
  },
  cancelModalBlockCardAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.CANCEL_MODAL_BLOCK_CARD_ADMIN_CARDS
    );
  },

  submitModalBlockCardAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SUBMIT_MODAL_BLOCK_CARD_ADMIN_CARDS
    );
  },
  submitModalUnlockCardAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.SUBMIT_MODAL_UNLOCK_CARD_ADMIN_CARDS
    );
  },
  cancelModalUnlockCardAdminCards: () => {
    createSentryClickBreadcrumb(
      ADMIN_CARDS_EVENT.CANCEL_MODAL_UNLOCK_CARD_ADMIN_CARDS
    );
  },
};
