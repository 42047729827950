import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import {
  BatchPayment,
  FindBatchPaymentsListParams,
} from '@interfaces/BatchPayments.interfaces';
import { ListResponse } from '@interfaces/Common.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { findBatchList } from '../findBatchList';
import { batchPaymentKeys } from './batchPaymentKeys';

export interface UseInfiniteBatchListParams
  extends Omit<FindBatchPaymentsListParams, 'next'> {
  organizationId: string | null;
}

export const useInfiniteBatchListQuery = (
  params: UseInfiniteBatchListParams
) => {
  const { organizationId, ...rest } = params;

  return useInfiniteQuery<
    ListResponse<BatchPayment>,
    ApiError,
    InfiniteData<ListResponse<BatchPayment>>,
    unknown[],
    string
  >({
    queryKey: batchPaymentKeys.list({ organizationId, ...rest }),
    queryFn: ({ pageParam }) => findBatchList({ ...rest, next: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next && lastPage.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    enabled: !!organizationId,
  });
};
