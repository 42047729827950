// vendors
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ChangeStatusModal,
  ClearFiltersQuery,
  EmptyStateBlock,
  HeaderPage,
  MultiSelectQuery,
  Pagination,
  SearchQuery,
} from '@components';
import {
  emptyCollaborators,
  emptyCollaboratorsWithFilters,
  errorState,
} from '@constants/emptyState';
import { StatusEnum } from '@enums/Status.enum';
import { UserLevelEnum } from '@enums/UserEnum';
import { useAuthentication } from '@hooks';
import { useQueryParams } from '@hooks/useQueryParams';
import { DrawerAuthentication } from '@modules/drawer';
import { Button, TableDemo, Toastr } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import {
  useDeactivateUserMutation,
  useInfiniteRealmUserListQuery,
} from '@services/user/hooks';
import { TableColumnsFilter } from '../../shared/components/tableColumnsFilter/TableColumnsFilter';
import './RealmUsers.styles.scss';
import { useRealmUsersColumns } from './useRealmUsersColumns';

const getDeactivateUserText = (fullName: string) => {
  return {
    actionButtonLabel: 'Sim, excluir',
    confirmation: 'Deseja mesmo continuar?',
    disclaimer: `O usuário ${fullName} será definitivamente excluído da plataforma, e seus acessos serão bloqueados e todo o saldo de suas carteiras serão zerados e transferidos para a conta empresarial.`,
    modalTitle: 'Desativar usuário',
    warn: {
      title: 'Esta é uma ação permanente!',
      description:
        'Caso você prossiga com a exclusão deste usuário, não será possível revertê-la.',
    },
  };
};

export const RealmUsers = () => {
  const { t } = useTranslation();
  const { realmId } = useParams();
  const { queryParams } = useQueryParams();
  const { openDrawer } = useDrawer();
  const queryClient = useQueryClient();
  const {
    pin,
    controller,
    authSubmit,
    onAuthSuccess,
    onAuthError,
    isAuthentication,
  } = useAuthentication();

  const [page, setPage] = useState<number>(0);

  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState({
    open: false,
    userId: '',
    fullName: '',
  });

  const closeDeactivateUserModal = () => {
    setShowDeactivateUserModal({
      open: false,
      userId: '',
      fullName: '',
    });
  };

  const statusOptions = [
    {
      label: t('status.active'),
      value: StatusEnum.ACTIVE,
    },
    {
      label: t('status.creating'),
      value: StatusEnum.CREATING,
    },
    {
      label: t('status.deactivated'),
      value: StatusEnum.DEACTIVATED,
    },
  ];

  const securityStatusOptions = [
    {
      label: t('status.active'),
      value: 'true',
    },
    {
      label: t('general.pending'),
      value: 'false',
    },
  ];

  const levelOptions = [
    {
      label: t('general.admin'),
      value: UserLevelEnum.ADMIN,
    },
    {
      label: t('general.collaborator'),
      value: UserLevelEnum.USER,
    },
  ];

  const filters = {
    status: queryParams.getAll('status') as StatusEnum[],
    name: queryParams.get('username') ?? undefined,
    hasMfa: queryParams.get('mfa'),
    hasPin: queryParams.get('pin'),
    level: queryParams.get('access') as UserLevelEnum,
  };

  const hasFilters = Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return !!value;
  });

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isError,
  } = useInfiniteRealmUserListQuery({
    realmId,
    ...filters,
  });

  const {
    mutate: deactivateUser,
    isPending: isDeactivatingUser,
    error: deactivateUserError,
  } = useDeactivateUserMutation();

  const { columns, filterColumnsProps } = useRealmUsersColumns(
    setShowDeactivateUserModal
  );

  const handleDeactivateUser = () => {
    deactivateUser(
      {
        userId: showDeactivateUserModal.userId,
        pin,
      },
      {
        onSuccess: () => {
          Toastr.success(t('realm.user-deactivated'));

          queryClient.refetchQueries({
            queryKey: ['realm', 'users'],
          });
          queryClient.refetchQueries({
            queryKey: ['user', { userId: showDeactivateUserModal.userId }],
          });
          closeDeactivateUserModal();
          onAuthSuccess();
        },
        onError: (error) => {
          onAuthError(error);
        },
      }
    );
  };

  return (
    <>
      <HeaderPage title={t('general.team')} subTitle={t('realm.team-subtitle')}>
        <Button
          type="button"
          size="large"
          variant="primary"
          key="edit-realm-user"
          onClick={() => openDrawer('edit-realm-user')}
          data-testid="add-user-button"
        >
          {t('realm.add-user')}
          <i className="fa-regular fa-plus fa-1x" />
        </Button>
      </HeaderPage>

      <div className="mb-4 flex flex-wrap gap-3">
        <SearchQuery placeholder={t('general.username')} query="username" />

        <MultiSelectQuery
          onlyOptions
          query="access"
          title={t('general.access')}
          type="single"
          options={levelOptions}
        />

        <MultiSelectQuery
          onlyOptions
          query="status"
          title={t('general.status')}
          options={statusOptions}
        />

        <MultiSelectQuery
          onlyOptions
          query="pin"
          title={'PIN'}
          type="single"
          options={securityStatusOptions}
        />

        <MultiSelectQuery
          onlyOptions
          query="mfa"
          title={t('general.mfa')}
          type="single"
          options={securityStatusOptions}
        />

        <ClearFiltersQuery
          queries={['status', 'username', 'access', 'mfa', 'pin']}
        />

        <TableColumnsFilter {...filterColumnsProps} />
      </div>

      <TableDemo
        data={data?.pages[page].items}
        columns={columns}
        pinLastColumn
        isLoading={isFetching}
        emptyState={
          <>
            {!isError && !hasFilters && (
              <EmptyStateBlock
                details={emptyCollaborators}
                button
                buttonLabel="Adicionar colaborador"
                buttonAction={() => {
                  openDrawer('edit-realm-user');
                }}
              />
            )}

            {!isError && hasFilters && (
              <EmptyStateBlock details={emptyCollaboratorsWithFilters} />
            )}

            {isError && <EmptyStateBlock details={errorState} />}
          </>
        }
      />

      {!isError && !isLoading && (
        <Pagination
          className="justify-end mt-4 ml-auto"
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          setPage={setPage}
          page={page}
          pageCount={data?.pages.length}
        />
      )}

      {showDeactivateUserModal.open && !isAuthentication && (
        <ChangeStatusModal
          open={showDeactivateUserModal.open}
          closeModal={closeDeactivateUserModal}
          changeStatus={() => authSubmit(handleDeactivateUser)}
          modalText={getDeactivateUserText(showDeactivateUserModal.fullName)}
          errorService={!!deactivateUserError}
          isLoading={isDeactivatingUser}
        />
      )}

      <DrawerAuthentication
        {...controller}
        onSubmit={handleDeactivateUser}
        isPending={isDeactivatingUser}
        onClose={() => {
          controller?.onClose();
          closeDeactivateUserModal();
        }}
      />
    </>
  );
};
