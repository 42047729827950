import { useMutation } from '@tanstack/react-query';

import { PixPayloadService } from '@interfaces/Pix.interface';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { confirmationPayloadRequest } from '../PaymentConfirmation.interface';
import { confirmationPix } from '../services/PaymentConfirmationService';

interface UsePaymentConfirmationMutatePayload {
  payload: confirmationPayloadRequest;
  walletId?: string;
  pin?: string;
}

export const usePixPaymentConfirmationMutation = () => {
  return useMutation<
    PixPayloadService,
    ApiErrorPin,
    UsePaymentConfirmationMutatePayload,
    unknown
  >({
    mutationFn: ({
      payload,
      walletId,
      pin,
    }: UsePaymentConfirmationMutatePayload) =>
      confirmationPix(payload, walletId, pin),
  });
};
