import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cancelCardAction } from '@constants/cardStatusModalText';
import { ICONS } from '@constants/icons';
import { eventsHandler } from '@lib';
import { Sidebar } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { DrawerCreateCardProps } from './DrawerCreateCard.interfaces';
import { SidebarCard } from './components/SidebarCard';
import { SidebarPin } from './components/SidebarPin';
import { SidebarPinProps } from './components/SidebarPin/SidebarPin.interfaces';

export const DrawerCreateCard = ({
  cardId,
  walletId,
}: DrawerCreateCardProps) => {
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();

  const [closeSidebar, setCloseSidebar] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [pinProps, setPinProps] = useState<Omit<
    SidebarPinProps,
    'actionModal'
  > | null>(
    walletId && cardId
      ? {
          walletId,
          cardId,
        }
      : null
  );

  return (
    <Sidebar
      title={t('general.new-card')}
      icon={ICONS['credit-card']}
      open={true}
      hasModal={closeSidebar}
      onClose={() => {
        eventsHandler.closeDrawerCreateCardAdminCards();
        setActionModal(false);
        setCloseSidebar(false);
        closeDrawer();
      }}
      modalText={cancelCardAction}
      actionModal={actionModal}
    >
      {pinProps?.cardId && pinProps?.walletId ? (
        <SidebarPin
          actionModal={setActionModal}
          cardId={pinProps?.cardId}
          walletId={pinProps?.walletId}
        />
      ) : (
        <SidebarCard
          actionModal={setActionModal}
          setCloseSidebar={setCloseSidebar}
          setPinProps={setPinProps}
        />
      )}
    </Sidebar>
  );
};
