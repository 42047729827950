import {
  Breadcrumb as BreadcrumbItem,
  Breadcrumbs,
  Link,
} from 'react-aria-components';

import { BreadcrumbProps } from './Breadcrumb.interfaces';
import './Breadcrumb.styles.scss';
import { Skeleton } from '../skeleton/Skeleton';

export const Breadcrumb = ({ items, isLoading = false }: BreadcrumbProps) => {
  const lastItemIndex = items.length - 1;

  return (
    <Skeleton width={'300px'} isLoading={isLoading}>
      <Breadcrumbs className="breadcrumb">
        {items.map(({ label, isDisabled, url, trackUserAction }, index) => {
          const isLastItem = index === lastItemIndex;

          return (
            <BreadcrumbItem className="breadcrumb_item" key={url}>
              <Link
                className="breadcrumb_item_link breadcrumb_typography"
                href={url}
                isDisabled={isDisabled || isLastItem}
                data-current={isLastItem}
                onPress={() => trackUserAction?.()}
              >
                {label}
              </Link>
              {!isLastItem && (
                <span className="breadcrumb_item_separator breadcrumb_typography">
                  /
                </span>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumbs>
    </Skeleton>
  );
};
