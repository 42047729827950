export const customerKeys = {
  all: () => ['customer'],
  details: () => [...customerKeys.all(), 'detail'],
  detail: (customerId: string | undefined, organizationId: string | null) => [
    ...customerKeys.details(),
    { customerId, organizationId },
  ],
  lists: () => [...customerKeys.all(), 'list'],
  list: (organizationId: string | null, query?: string, limit?: number) => [
    ...customerKeys.lists(),
    { organizationId, query, limit },
  ],
};
