import { Entries } from '@interfaces/Invoices.interfaces';
import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import { ListResponse } from '@interfaces/Common.interfaces';

export const getInvoiceEntries = async (
  invoiceId: string
): Promise<ListResponse<Entries>> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }/${invoiceId}/entries`
  );
  const { data } = response;

  return data;
};
