import clsx from 'clsx';
import { Dispatch, HTMLAttributes, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';

interface PaginationProps {
  hasNextPage: boolean;
  fetchNextPage: () => Promise<unknown>;
  isFetchingNextPage: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageCount: number | undefined;
  className?: HTMLAttributes<HTMLDivElement>['className'];
}

export const Pagination = ({
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  page,
  setPage,
  pageCount = 0,
  className,
}: PaginationProps) => {
  const isLastPage = page === pageCount - 1 && !hasNextPage;
  const isFirstPage = page === 0;

  const { t } = useTranslation();

  const handleSetNextPage = async () => {
    if (page === pageCount - 1) await fetchNextPage();
    setPage((prev) => prev + 1);
  };

  const handleSetPreviousPage = async () => {
    setPage((prev) => prev - 1);
  };

  useEffect(() => {
    if (page > pageCount) {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  return (
    <div className={clsx('flex gap-3', className)}>
      <button
        type="button"
        className={clsx(
          'hover:bg-neutral-30 flex h-8 cursor-pointer items-center gap-1 rounded-md px-3 text-neutral-500',
          'disabled:pointer-events-none disabled:bg-none disabled:text-neutral-50'
        )}
        disabled={isFirstPage}
        onClick={handleSetPreviousPage}
      >
        <i className={`${ICONS['chevron-left']} text-p1`} />
        <span className="text-p1">{t('general.previous')}</span>
      </button>
      <button
        type="button"
        className={clsx(
          'hover:bg-neutral-30 flex h-8 cursor-pointer items-center gap-1 rounded-md px-3 text-neutral-500',
          'disabled:pointer-events-none disabled:bg-none disabled:text-neutral-50'
        )}
        disabled={isLastPage || isFetchingNextPage || pageCount === 0}
        onClick={handleSetNextPage}
      >
        <span className="text-p1">{t('general.next')}</span>
        <i className={`${ICONS['chevron-right']} text-p1`} />
      </button>
    </div>
  );
};
