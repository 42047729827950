import { createSentryClickBreadcrumb } from '@lib/sentry';

enum ADMIN_USERS_EVENT {
  OPEN_DRAWER_EDIT_USER_BALANCE_ADMIN_USERS = 'open_drawer_editUserBalance_adminUsers',
  CLOSE_DRAWER_EDIT_USER_BALANCE_ADMIN_USERS = 'close_drawer_editUserBalance_adminUsers',
  SELECT_OPTION_WALLET_CATEGORY_ADMIN_USERS = 'select_option_walletCategory_adminUsers',
  SUBMIT_BUTTON_UPDATE_WALLET_BALANCE_ADMIN_USERS = 'submit_button_updateWalletBalance_adminUsers',
  CANCEL_BUTTON_UPDATE_WALLET_BALANCE_ADMIN_USERS = 'cancel_button_updateWalletBalance_adminUsers',
}

export const adminUsersEvents = {
  openDrawerEditUserBalanceAdminUsers: () => {
    createSentryClickBreadcrumb(
      ADMIN_USERS_EVENT.OPEN_DRAWER_EDIT_USER_BALANCE_ADMIN_USERS
    );
  },
  closeDrawerEditUserBalanceAdminUsers: () => {
    createSentryClickBreadcrumb(
      ADMIN_USERS_EVENT.CLOSE_DRAWER_EDIT_USER_BALANCE_ADMIN_USERS
    );
  },
  selectOptionWalletCategoryAdminUsers: () => {
    createSentryClickBreadcrumb(
      ADMIN_USERS_EVENT.SELECT_OPTION_WALLET_CATEGORY_ADMIN_USERS
    );
  },
  submitButtonUpdateWalletBalanceAdminUsers: () => {
    createSentryClickBreadcrumb(
      ADMIN_USERS_EVENT.SUBMIT_BUTTON_UPDATE_WALLET_BALANCE_ADMIN_USERS
    );
  },
  cancelButtonUpdateWalletBalanceAdminUsers: () => {
    createSentryClickBreadcrumb(
      ADMIN_USERS_EVENT.CANCEL_BUTTON_UPDATE_WALLET_BALANCE_ADMIN_USERS
    );
  },
};
