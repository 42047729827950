import {
  Button,
  Card,
  DropDownList,
  Icon,
  Dialog,
  Search,
  Skeleton,
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableRow,
  Tag,
  Toastr,
  Typography,
} from '@portao3-web/ui';

import {
  initialState,
  initialStateModal,
  textDeleteCustomField,
  thead,
} from '../../constantes/preferences';
import { renderTableHeader } from '@utils/renderTableHeader';
import './CustomFieldsTable.styles.scss';
import { CustomFieldsTableProps } from './CustomFieldsTable.interface';
import {
  CustomFieldTypesText,
  CustomFieldLocationText,
  CustomField,
  CustomFieldLocation,
  CustomFieldStatus,
  CustomFieldPostParams,
} from '@interfaces/CustomFields.interfaces';
import {
  ButtonMoreItems,
  ChangeStatusModal,
  EmptyStateBlock,
} from '@components';
import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { emptyCustomFields } from '@constants/emptyState';
import { limitPerPage } from '@constants/limitPerPage';
import { getCustomFields, updateCustomField } from '@services/customFields';
import { eventsHandler } from '@lib';

export const CustomFieldsTable = ({
  editSelectedData,
  hasCustomFieldsUpdates,
}: CustomFieldsTableProps) => {
  const isMounted = useRef(false);
  const [deleteModal, setDeleteModal] = useState(initialStateModal);
  const [isLoading, setIsLoading] = useState(false);

  const [customFieldsList, setCustomFieldsList] = useState(initialState);
  const [customFieldsListCached, setCustomFieldsListCached] = useState(
    [] as CustomField[]
  );
  const [nextPageCustomFields, setNextPageCustomFields] = useState('');
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState(false);
  const [hideNextPage, setHideNextPage] = useState(false);
  const [errorServiceCustomFields, setErrorServiceCustomFields] =
    useState(false);
  const filterMenuRef = useRef<HTMLDivElement | null>(null);

  const [showFilterCustomField, setShowFilterCustomField] = useState(false);
  const [searchCustomField, setSearchCustomField] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(
    '' as CustomFieldLocation
  );

  const dropDownMenuList = [
    {
      label: 'Todos',
      action: () => {
        isMounted.current = false;
        setSelectedFilter('' as CustomFieldLocation);
      },
    },
    {
      label: 'Carteiras',
      action: () => {
        isMounted.current = false;
        setSelectedFilter(CustomFieldLocation.WALLET);
      },
    },
    {
      label: 'Transações',
      action: () => {
        isMounted.current = false;
        setSelectedFilter(CustomFieldLocation.TRANSACTION);
      },
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      showFilterCustomField &&
        filterMenuRef.current &&
        !filterMenuRef.current.contains(event.target as Node) &&
        setShowFilterCustomField((prev) => !prev);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFilterCustomField]);

  const getRegisteredCustomFields = useCallback(async () => {
    try {
      const { items, nextPage } = await getCustomFields(
        limitPerPage,
        nextPageCustomFields,
        selectedFilter,
        searchCustomField
      );

      if (nextPage) {
        const cachedData = await getCustomFields(
          limitPerPage,
          nextPage,
          selectedFilter,
          searchCustomField
        );
        setHideNextPage(true);
        setCustomFieldsListCached(cachedData.items);
        setNextPageCustomFields(cachedData.nextPage);
      } else {
        setHideNextPage(false);
      }
      setCustomFieldsList(items);
    } catch (error) {
      console.log(error);
      setErrorServiceCustomFields(true);
    }
  }, [nextPageCustomFields, searchCustomField, selectedFilter]);

  useEffect(() => {
    if (!isMounted.current || hasCustomFieldsUpdates) {
      getRegisteredCustomFields();
      isMounted.current = true;
    }
  }, [getRegisteredCustomFields, hasCustomFieldsUpdates]);

  const getCustomFieldsCached = async () => {
    setCustomFieldsList((prevData) => [...prevData, ...customFieldsListCached]);
    setIsLoadingMoreItems(true);

    if (nextPageCustomFields) {
      const result = await getCustomFields(
        limitPerPage,
        nextPageCustomFields,
        selectedFilter,
        searchCustomField
      );
      setCustomFieldsListCached(result.items);
      setNextPageCustomFields(result.nextPage);
      setIsLoadingMoreItems(false);
    } else {
      setIsLoadingMoreItems(false);
      setHideNextPage(false);
    }
  };

  const deleteCustomField = (elem: CustomField) => {
    setCustomFieldsList((prevList) =>
      prevList.filter((data) => data.id !== elem.id)
    );
  };

  const deleteSelectedCustomField = async () => {
    setIsLoading(true);
    try {
      const field = {
        label: deleteModal.selectedItem.label,
        required: deleteModal.selectedItem.required,
        identifier: deleteModal.selectedItem.identifier,
        externalIdentifier: deleteModal.selectedItem.externalIdentifier,
        location: deleteModal.selectedItem.location,
        status: CustomFieldStatus.DELETED,
      };

      await updateCustomField(
        deleteModal.selectedItem.id,
        field as CustomFieldPostParams
      );

      deleteCustomField(deleteModal.selectedItem);
      Toastr.success(
        `O campo personalizado "${deleteModal.selectedItem.identifier}" foi excluído com sucesso!`
      );
    } catch (error) {
      console.error(error);
    }
    setDeleteModal({ open: false, selectedItem: {} as CustomField });
    setIsLoading(false);
  };

  return (
    <div className="custom_fields_table">
      <Card>
        <Search
          variant="animated"
          value={searchCustomField}
          setValue={setSearchCustomField}
          label="Campos cadastrados"
          queryFn={() => {
            isMounted.current = false;
            getRegisteredCustomFields();
          }}
          trackUserAction={eventsHandler.searchButtonFieldsListAdminPreferences}
          onClear={() => setSearchCustomField('')}
          delay={500}
          actions={
            <div className="flex gap-3 items-center">
              <div ref={filterMenuRef}>
                <Button
                  data-testid="buttonFilterCustomFields"
                  size="medium"
                  variant="tertiary"
                  className="wallet_button_finder"
                  onClick={() => {
                    setShowFilterCustomField((prev) => !prev);
                    eventsHandler.filterButtonFieldsListAdminPreferences();
                  }}
                >
                  <Icon size="xlarge">
                    <i className="fa-regular fa-filter" />
                  </Icon>
                </Button>
                {showFilterCustomField && (
                  <DropDownList list={dropDownMenuList} />
                )}
              </div>
              <Button onClick={() => editSelectedData()} size="large">
                Novo
                <Icon size="large">
                  <i className="fa-solid fa-plus" />
                </Icon>
              </Button>
            </div>
          }
        />
        <div className="custom_fields_table">
          <ChangeStatusModal
            open={deleteModal.open}
            closeModal={() => setDeleteModal(initialStateModal)}
            changeStatus={deleteSelectedCustomField}
            modalText={{
              ...textDeleteCustomField,
              modalTitle: `Deseja excluir o campo "${deleteModal.selectedItem.label}"`,
            }}
            errorService={false}
            isLoading={isLoading}
          />
          <Table>
            <TableHead>
              <TableRow>
                {thead.map((columnTitle: string, key: number) =>
                  renderTableHeader(columnTitle, key)
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {customFieldsList.map((item, index) => {
                return (
                  <TableRow
                    className="custom_fields_table_row"
                    key={index}
                    onClick={(event) =>
                      item.id !== '' &&
                      editSelectedData(item, event as BaseSyntheticEvent)
                    }
                  >
                    <TableCol>
                      <Skeleton width={80} isLoading={item.id === ''}>
                        <Typography
                          tag="p"
                          color="var(--product-neutral-n500)"
                          weight="p2"
                        >
                          {item.identifier}
                        </Typography>
                      </Skeleton>
                    </TableCol>
                    <TableCol>
                      <Skeleton width={80} isLoading={item.id === ''}>
                        <div className="custom_fields_table_tags">
                          {item.location.map((elem, index) => {
                            return (
                              <Tag
                                key={index}
                                text={CustomFieldLocationText[elem]}
                                textColor="var(--product-primary-p600)"
                                showCloseButton={false}
                              />
                            );
                          })}
                        </div>
                      </Skeleton>
                    </TableCol>
                    <TableCol>
                      <Skeleton width={'65%'} isLoading={item.id === ''}>
                        <Typography
                          tag="p"
                          color="var(--product-neutral-n500)"
                          weight="p2"
                        >
                          {CustomFieldTypesText[item?.type]}
                        </Typography>
                      </Skeleton>
                    </TableCol>
                    <TableCol>
                      <Skeleton width={'65%'} isLoading={item.id === ''}>
                        <Typography
                          tag="p"
                          color="var(--product-neutral-n500)"
                          weight="p2"
                        >
                          {item.required ? 'Sim' : 'Não'}
                        </Typography>
                      </Skeleton>
                    </TableCol>
                    <TableCol>
                      <Skeleton width={'65%'} isLoading={item.id === ''}>
                        <Dialog variant="popover">
                          <Dialog.Trigger
                            trackUserAction={() => {
                              eventsHandler.viewOptionsInputActionsAdminPreferences();
                            }}
                          >
                            <Button
                              data-testid="buttonSelectActions"
                              size="medium"
                              variant="tertiary"
                              className="wallet-row_button_finder"
                              data-column-action
                            >
                              <Icon size="xlarge">
                                <i className="fa-regular fa-ellipsis-vertical" />
                              </Icon>
                            </Button>
                          </Dialog.Trigger>
                          <Dialog.Content>
                            <DropDownList
                              list={[
                                {
                                  label: 'Editar',
                                  action: () => {
                                    editSelectedData(item);
                                    eventsHandler.selectOptionEditInputAdminPreferences();
                                  },
                                },
                                {
                                  label: 'Excluir',
                                  action: () => {
                                    setDeleteModal({
                                      open: true,
                                      selectedItem: item,
                                    });
                                    eventsHandler.selectOptionDeleteInputAdminPreferences();
                                  },
                                },
                              ]}
                            />
                          </Dialog.Content>
                        </Dialog>
                      </Skeleton>
                    </TableCol>
                  </TableRow>
                );
              })}
              {hideNextPage &&
                !errorServiceCustomFields &&
                customFieldsList.length > 0 && (
                  <ButtonMoreItems
                    colSpan={12}
                    onClick={getCustomFieldsCached}
                    isLoadingMoreItems={isLoadingMoreItems}
                  />
                )}
            </TableBody>
          </Table>
          {customFieldsList.length <= 0 && (
            <EmptyStateBlock details={emptyCustomFields} />
          )}
        </div>
      </Card>
    </div>
  );
};
