import { useQueryParams } from '@hooks';
import { DatePickerWithRange } from '@portao3-web/ui';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { DateRange } from 'react-day-picker';

export const DatePickerRangeQuery = ({ size }: { size?: 'md' | 'lg' }) => {
  const { setQueryParams, queryParams } = useQueryParams();

  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');

  const startOfDate = (start: string | Date) =>
    dayjs(start).startOf('day').subtract(3, 'hours').toDate();
  const endOfDate = (end: string | Date) =>
    dayjs(end).endOf('day').subtract(3, 'hours').toDate();

  const defaultDate = {
    from: startDate
      ? dayjs(startDate).add(3, 'hours').toDate()
      : dayjs().subtract(30, 'day').toDate(),
    to: endDate
      ? endOfDate(dayjs(endDate).toDate())
      : endOfDate(dayjs().toDate()),
  };

  const handleChangeDate = (date: DateRange | undefined) => {
    if (!date?.from || !date.to) return;

    setQueryParams({
      startDate: startOfDate(date?.from).toISOString(),
      endDate: endOfDate(date?.to).toISOString(),
    });
  };

  useEffect(() => {
    if (!startDate && !endDate) {
      setQueryParams({
        startDate: startOfDate(
          dayjs().subtract(30, 'day').toDate()
        ).toISOString(),
        endDate: endOfDate(dayjs().toDate()).toISOString(),
      });
    }
  }, [startDate, endDate, setQueryParams]);

  return (
    <DatePickerWithRange
      size={size}
      defaultDate={defaultDate}
      onChangeDate={handleChangeDate}
    />
  );
};
