import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  Alert,
  BankSlipPaymentVoucher,
  CUSTOM_FIELDS_MAPPER,
  ContextualError,
  ContextualErrorType,
  InDrawerAuthentication,
  SidebarFooter,
  SidebarSection,
} from '@components';

import { ErrorMessages } from '@enums/ErrorCodes.enum';
import { WalletBalanceCategory } from '@enums/WalletEnum';
import { useAuthentication } from '@hooks';
import { BankSlip } from '@interfaces/BankSlip.interface';
import {
  CustomFieldLocation,
  CustomFieldType,
} from '@interfaces/CustomFields.interfaces';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { UserResponse } from '@interfaces/User.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import {
  Button,
  Column,
  Grid,
  Icon,
  Row,
  Skeleton,
  Spinner,
  Typography,
} from '@portao3-web/ui';
import { useCustomFieldsQuery } from '@services/customFields/hooks';
import { userKeys } from '@services/user/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { cnpjMask, cpfMask } from '@utils/formatMasks';
import dayjs from 'dayjs';
import { TypePayment } from '../../enums/typePayment.enum';
import { FormPix } from '../newPix/NewPix.interface';
import {
  CreditPartyProps,
  DebitPartyProps,
} from '../paymentVoucher/PaymentVoucher.interface';
import { PaymentConfirmationProps } from './PaymentConfirmation.interface';
import './PaymentConfirmation.styles.scss';
import { useBankSlipPaymentConfirmationMutation } from './hooks/useBankSlipPaymentConfirmationMutation';
import { usePixPaymentConfirmationMutation } from './hooks/usePixPaymentConfirmationMutation';
import {
  cancelBankSlipService,
  cancelPixService,
} from './services/PaymentConfirmationService';

const MyAccount = ({ accountName, debitParty }: DebitPartyProps) => {
  return (
    <>
      <Row>
        <Column lg={12} md={12} sm={12}>
          <div>
            <Typography
              tag="p"
              weight="p1"
              color="var(--product-neutral-n500)"
              className="accountName"
            >
              {accountName}
            </Typography>

            <Typography
              tag="p"
              weight="p2"
              color="var(--product-neutral-n60)"
              className="accountName"
            >
              Conta de onde sairá o valor
            </Typography>
          </div>
        </Column>
      </Row>
      <Row>
        <Column lg={6} md={6} sm={6}>
          <div>
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              De
            </Typography>

            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {debitParty.name}
            </Typography>
          </div>
        </Column>
        <Column lg={6} md={6} sm={6}>
          <div>
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              CPF/CNPJ
            </Typography>

            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {debitParty.document.length === 14
                ? cnpjMask(debitParty.document)
                : cpfMask(debitParty.document)}
            </Typography>
          </div>
        </Column>
      </Row>
    </>
  );
};

const DestinyAccount = ({ accountName, creditParty }: CreditPartyProps) => {
  return (
    <>
      <Row>
        <Column lg={12} md={12} sm={12}>
          <div>
            <Typography
              tag="p"
              weight="p1"
              color="var(--product-neutral-n500)"
              className="accountName"
            >
              {accountName}
            </Typography>
            <Typography
              tag="p"
              weight="p2"
              color="var(--product-neutral-n60)"
              className="accountName"
            >
              Conta que receberá o valor
            </Typography>
          </div>
        </Column>
      </Row>
      <Row>
        <Column lg={12} md={12} sm={12}>
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            Para
          </Typography>
        </Column>
        <Column lg={12} md={12} sm={12}>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {creditParty.name}
          </Typography>
        </Column>
      </Row>

      <Row>
        <Column lg={6} md={6} sm={6}>
          <div>
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              CPF/CNPJ
            </Typography>

            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {creditParty.document.length === 14
                ? cnpjMask(creditParty.document)
                : cpfMask(creditParty.document)}
            </Typography>
          </div>
        </Column>
        <Column lg={6} md={6} sm={6}>
          <div>
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              Chave PIX
            </Typography>

            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {creditParty.key}
            </Typography>
          </div>
        </Column>
      </Row>
    </>
  );
};

export const PaymentConfirmation = ({
  back,
  close,
  type,
  paymentConfirm,
  paymentVoucher,
  walletId,
  categoryType,
  setCustomFields,
  dictType,
}: PaymentConfirmationProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [loadingCancelPix, setLoadingCancelPix] = useState(false);
  const [contextualError, setContextualError] =
    useState<ContextualErrorType | null>(null);
  const inputPaymentValue = useRef<HTMLInputElement | null>(null);

  const {
    controller,
    authSubmit,
    pin,
    onAuthError,
    onAuthSuccess,
    isAuthentication,
  } = useAuthentication();

  const {
    mutate: confirmPixPaymentMutate,
    isPending: isPendingPixConfirmation,
  } = usePixPaymentConfirmationMutation();

  const {
    mutate: confirmBankSlipPaymentMutate,
    isPending: isPendingBankSlipConfirmation,
  } = useBankSlipPaymentConfirmationMutation();

  const errorMessage = {
    [ErrorMessages.INSUFFICIENT_FUNDS]: t(
      'error.insuficient-transaction-funds'
    ),
    [ErrorMessages.LIMIT_EXCEEDED]: t('error.limit-exceeded'),
    [ErrorMessages.REJECTED_BY_FRAUD]: (
      <div className="flex flex-row">
        <p className="text-p2 text-neutral-500">
          {t('error.rejected-by-fraud-p1')}{' '}
          <a
            className="text-p2 font-semibold text-blue-600 underline"
            href="https://www.notion.so/portao3/Manual-V2-Clientes-ADS-94ce9a7ec1e54dd99bf9df13f3319d2c?pvs=4#acbf202f28b54d04a2922d86ffc8f708"
            target="_blank"
            rel="noreferrer"
          >
            {t('error.rejected-by-fraud-link')}
          </a>{' '}
          {t('error.rejected-by-fraud-p2')}
        </p>
      </div>
    ),
  };

  const { data: customFields, error: customFieldsError } = useCustomFieldsQuery(
    {
      organization: localStorage.getItem('organization') || '',
      location: CustomFieldLocation.TRANSACTION,
    }
  );

  const form = useForm<FormPix>({
    mode: 'onBlur',
    defaultValues: {
      amount: calcCurrencyValue(0, 'BRL'),
    },
  });
  const { register, handleSubmit, setValue } = form;
  const { ref, ...rest } = register('amount');

  const pixPayment = async (
    formData: FormPix,
    confirmationPaymentId: string
  ) => {
    pathname?.includes('/company') &&
      eventsHandler.submitButtonPixIntentFundsOutWalletCompany();
    pathname?.includes('/wallets') &&
      eventsHandler.submitButtonPixIntentFundsOutWallet();

    const formattedCustomFields = formData.customFields
      ? Object.entries(formData.customFields).map(([key, value]) => {
          const field = customFields?.items?.find(
            (field) => field.identifier === key
          );

          const getMultiSelectValue = () => {
            if (value.length < 1) return [];
            if (typeof value === 'string') return [value];
            return value;
          };

          const getFieldValues = () => {
            const result = value;
            if (field?.type === CustomFieldType.NUMBER) {
              return [value.replace(/\D/g, '')];
            }
            return result ? [result] : [];
          };

          return {
            id: field?.id ?? '',
            label: field?.label ?? '',
            values:
              field?.type === CustomFieldType.MULTI_SELECT
                ? getMultiSelectValue()
                : field?.type === CustomFieldType.DATE
                  ? dayjs(value).toISOString()
                  : getFieldValues(),
            identifier: key,
            version: field?.version ?? 1,
          };
        })
      : [];

    setCustomFields && setCustomFields(formattedCustomFields);

    const payload = {
      amount:
        paymentConfirm.txnUpdatedAmount !== 0
          ? paymentConfirm.txnUpdatedAmount
          : Number(formData.amount.replace(/\D/g, '')),
      category: categoryType || WalletBalanceCategory.FLEX_INTERNATIONAL,
      id: confirmationPaymentId,
      customFields: formattedCustomFields,
    };

    confirmPixPaymentMutate(
      { payload, walletId, pin: pin || undefined },
      {
        onSuccess: (response) => {
          onAuthSuccess();
          paymentVoucher(response);

          if (response.qrCodeType) {
            customerEvents.pixConfirmed({
              amount: response.txnOriginalAmount,
              type: 'QRCODE',
              dictType: response.qrCodeType,
              walletId: response.walletId,
            });
          } else {
            customerEvents.pixConfirmed({
              amount: response.txnOriginalAmount,
              type: response.initiationType,
              dictType: dictType,
              walletId: response.walletId,
            });
          }

          refetchWalletData();
          setLoading(false);
          close(false);

          queryClient.setQueryData(
            userKeys.current(),
            (oldData: UserResponse) => {
              return {
                ...oldData,
                pinErrorsCount: 0,
              };
            }
          );
        },
        onError: (error) => {
          setLoading(false);
          const isAuthError = onAuthError(error);
          if (isAuthError) return;

          setContextualError({
            message: t(
              `error.pix-transaction.${error?.response?.data?.message}`,
              t('error.default')
            ),
            traceId: error?.response?.data?.traceId,
          });
        },
      }
    );
  };

  const bankSlipPayment = async (
    formData: FormPix,
    confirmationPaymentId: string
  ) => {
    pathname?.includes('/company') &&
      eventsHandler.clickButtonFundsOutBoletoWalletCompany();
    pathname?.includes('/wallets') &&
      eventsHandler.clickButtonFundsOutBoletoWallet();

    const formattedCustomFields = formData.customFields
      ? Object.entries(formData.customFields).map(([key, value]) => {
          const field = customFields?.items?.find(
            (field) => field.identifier === key
          );

          const getMultiSelectValue = () => {
            if (value.length < 1) return [];
            if (typeof value === 'string') return [value];
            return value;
          };

          const getFieldValues = () => {
            const result = value;
            if (field?.type === CustomFieldType.NUMBER) {
              return [value.replace(/\D/g, '')];
            }
            return result ? [result] : [];
          };

          return {
            id: field?.id ?? '',
            label: field?.label ?? '',
            values:
              field?.type === CustomFieldType.MULTI_SELECT
                ? getMultiSelectValue()
                : getFieldValues(),
            identifier: key,
            version: field?.version ?? 1,
          };
        })
      : [];

    const payload = {
      amount: paymentConfirm.txnUpdatedAmount,
      category: categoryType || WalletBalanceCategory.FLEX_INTERNATIONAL,
      id: confirmationPaymentId,
      customFields: formattedCustomFields,
    };

    confirmBankSlipPaymentMutate(
      { payload, walletId, pin: pin || undefined },
      {
        onSuccess: (response) => {
          onAuthSuccess();

          customerEvents.boletoConfirmed({
            txnOriginalAmount: response.txnOriginalAmount,
            dueDate: response.dueDate,
            txnUpdatedAmount: response.txnUpdatedAmount,
            walletId: response.walletId,
          });
          paymentVoucher(response);
          setLoading(false);
          refetchWalletData();
          close(false);
        },
        onError: (error) => {
          setLoading(false);
          const isAuthError = onAuthError(error);
          if (isAuthError) return;

          setContextualError({
            message:
              errorMessage[
                error?.response?.data?.message as keyof typeof errorMessage
              ] ?? t('error.default'),
            traceId: error?.response?.data?.traceId,
          });
        },
      }
    );
  };

  const submitFormPix = async (formData: FormPix) => {
    setLoading(true);
    setContextualError(null);

    if (type === TypePayment.PIX) {
      pixPayment(formData, paymentConfirm._id);
    } else {
      bankSlipPayment(formData, paymentConfirm._id);
    }
  };

  const cancelPix = async () => {
    setLoadingCancelPix(true);
    try {
      if (type === TypePayment.PIX) {
        await cancelPixService(paymentConfirm._id, walletId);
      } else {
        await cancelBankSlipService(paymentConfirm._id, walletId);
      }
      setLoadingCancelPix(false);
      back();
    } catch (error) {
      const errorService = error as AxiosError;
      const errorInfo = errorService.response?.data as ErrorRequest;
      setLoadingCancelPix(false);

      setContextualError({
        message: t('error.default'),
        traceId: errorInfo?.traceId,
      });
    }
  };

  const renderCustomFields = () => {
    return customFieldsError ? (
      <Alert status="error">
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {t('error.loadCustomFields')}
        </Typography>
      </Alert>
    ) : (
      customFields?.items
        ?.slice()
        .reverse()
        .map((field) => {
          const Component =
            CUSTOM_FIELDS_MAPPER[
              field.type as keyof typeof CUSTOM_FIELDS_MAPPER
            ];

          return (
            Component && (
              <Component form={form} fieldSchema={field} key={field.id} />
            )
          );
        })
    );
  };

  const changeInputSize = (value: string) => {
    const newSize = 10 + value.length * 10;
    return newSize < 90 ? 90 : newSize;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setValue(
      'amount',
      calcCurrencyValue(Number(value.replace(/\D/g, '')), 'BRL')
    );

    if (inputPaymentValue.current) {
      inputPaymentValue.current.style.width = `${changeInputSize(value)}px`;
    }
  };

  const focusInput = () => {
    if (inputPaymentValue.current) {
      inputPaymentValue.current.focus();
    }
  };

  const refetchWalletData = () => {
    queryClient.refetchQueries({
      queryKey: [
        'wallet',
        {
          walletId,
          organizationId: localStorage.getItem('organization') || '',
        },
      ],
    });
  };

  return (
    <form
      onSubmit={handleSubmit((values) =>
        authSubmit(() => submitFormPix(values))
      )}
      data-testid="pix-copy-paste"
    >
      {!isAuthentication && (
        <div className="payment-confirmation">
          {contextualError && <ContextualError error={contextualError} />}
          <SidebarSection
            numberSection={1}
            titleSection={
              type === TypePayment.BANK_SLIP
                ? 'Confirmação de pagamento'
                : 'Confirmação do PIX'
            }
            subTitleSection={
              type === TypePayment.BANK_SLIP
                ? 'Confirme o pagamento do boleto'
                : 'Confirme o pagamento do PIX'
            }
          >
            {type === TypePayment.BANK_SLIP && paymentConfirm && (
              <BankSlipPaymentVoucher data={paymentConfirm as BankSlip} />
            )}

            {type === TypePayment.PIX && paymentConfirm && (
              <Grid className="paymentConfirmation_info">
                <div className="border-money">
                  {paymentConfirm.txnUpdatedAmount !== 0 && (
                    <Row>
                      <Column lg={12} md={12} sm={12}>
                        <Typography
                          tag="p"
                          weight="p3"
                          color="var(--product-neutral-n80)"
                        >
                          Valor
                        </Typography>
                      </Column>
                      <Column lg={12} md={12} sm={12}>
                        <Typography
                          tag="p"
                          weight="p1"
                          color="var(--product-neutral-n500)"
                        >
                          {calcCurrencyValue(
                            paymentConfirm.txnUpdatedAmount,
                            'BRL'
                          )}
                        </Typography>
                      </Column>
                    </Row>
                  )}

                  {paymentConfirm.txnUpdatedAmount === 0 && (
                    <Row>
                      <Column lg={12} md={12} sm={12}>
                        <div className="payment_value flex flex-row">
                          <Typography
                            tag="p"
                            weight="p3"
                            color="var(--product-neutral-n80)"
                          >
                            Valor
                          </Typography>

                          <div className="set_value_payment">
                            <Icon size="large">
                              <i className="fa-regular fa-triangle-exclamation payment_alert" />
                            </Icon>
                            <Typography
                              tag="p"
                              weight="p3"
                              color="var(--product-warning-w800)"
                            >
                              {t('pix.set-value-payment')}
                            </Typography>
                          </div>
                        </div>
                      </Column>
                      <Column lg={12} md={12} sm={12}>
                        <div className="box_input">
                          <input
                            type="text"
                            className="input_value_payment w-[90px]"
                            {...rest}
                            maxLength={17}
                            onChange={handleInputChange}
                            ref={(e) => {
                              ref(e);
                              inputPaymentValue.current = e;
                            }}
                          />

                          <Icon size="large">
                            <i
                              className="fa-regular fa-pen pen_edit"
                              onClick={focusInput}
                            />
                          </Icon>
                        </div>
                      </Column>
                    </Row>
                  )}
                </div>
                <hr className="hr" />
                {'debitParty' in paymentConfirm && (
                  <MyAccount
                    accountName="Sua conta"
                    debitParty={paymentConfirm.debitParty}
                  />
                )}
                <hr className="hr" />
                {'creditParty' in paymentConfirm && (
                  <DestinyAccount
                    accountName="Conta de destino"
                    creditParty={paymentConfirm.creditParty}
                  />
                )}
                {'description' in paymentConfirm &&
                  paymentConfirm.description !== '' && (
                    <Row>
                      <Column lg={12} md={12} sm={12}>
                        <Typography
                          tag="p"
                          weight="p3"
                          color="var(--product-neutral-n80)"
                        >
                          Descrição
                        </Typography>
                      </Column>
                      <Column lg={12} md={12} sm={12}>
                        <Typography
                          tag="p"
                          weight="p2"
                          color="var(--product-neutral-n500)"
                        >
                          {paymentConfirm.description}
                        </Typography>
                      </Column>
                    </Row>
                  )}
                <hr className="hr mb-4" />
              </Grid>
            )}
          </SidebarSection>

          {customFields?.items && customFields?.items?.length > 0 && (
            <div style={{ marginTop: 16 }}>
              <SidebarSection
                numberSection={2}
                titleSection={t('customFields.drawerBlockTitle')}
                subTitleSection=""
              >
                <Skeleton isLoading={loading} width={520} height={30}>
                  <Row>
                    <Column lg={12} md={12} sm={12}>
                      {renderCustomFields()}
                    </Column>
                  </Row>
                </Skeleton>
              </SidebarSection>
            </div>
          )}

          <SidebarFooter>
            <Button
              type="button"
              variant="tertiary"
              size="large"
              data-testid="cancelPix"
              onClick={() => {
                pathname?.includes('/company') &&
                  eventsHandler.cancelButtonPixFundsOutWalletCompany();
                pathname?.includes('/wallets') &&
                  eventsHandler.cancelButtonPixFundsOutWallet();

                cancelPix();
              }}
            >
              {loadingCancelPix ? <Spinner variant="primary" /> : 'Cancelar'}
            </Button>
            <Button
              type="submit"
              size="large"
              data-testid="submitForm"
              className="button_submit"
            >
              {loading ? (
                <Spinner variant="secondary" />
              ) : type === TypePayment.PIX ? (
                'Transferir agora'
              ) : (
                'Pagar agora'
              )}
            </Button>
          </SidebarFooter>
        </div>
      )}

      <InDrawerAuthentication
        isPending={isPendingPixConfirmation || isPendingBankSlipConfirmation}
        {...controller}
      />
    </form>
  );
};
