import { endpoint, organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { StatusEnum } from '@enums/Status.enum';
import { environment } from '@environments/environment';
import { ListResponse } from '@interfaces/Common.interfaces';
import {
  CurrentUser,
  EditUserParams,
  RealmUser,
  UserResponse,
} from '@interfaces/User.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export const getCurrentUser = async (): Promise<CurrentUser> => {
  const response = await api.get(
    `${environment.identityBaseUrl}${endpoint.currentUser}`
  );
  const { data } = response;

  return data;
};

export const findUsersByOrganization = async (
  query?: string,
  next?: string,
  limit?: number
): Promise<UserResponse> => {
  const params = { name: query, next, limit: limit || limitPerPage };

  Object.entries(params).forEach(([key, value]) => {
    if (!value) delete params[key as keyof typeof params];
  });

  const { data } = await api.get(
    `${environment.identityBaseUrl}${realm()}/${organization()}/${
      endpoint.users
    }`,
    {
      params,
    }
  );

  return data;
};

export interface GetUserListProps {
  name?: string;
  next?: string;
  status?: StatusEnum[];
  hasPin?: string | null;
  hasMfa?: string | null;
  level?: string;
}

export const getUsersByRealm = async (
  params: GetUserListProps
): Promise<ListResponse<RealmUser>> => {
  const copyParams = {
    ...params,
    limit: limitPerPage,
  };

  removeEmptyObjectAttributes(copyParams);

  const { data } = await api.get(
    `${environment.identityBaseUrl}${realm()}/${endpoint.users}`,
    {
      params: copyParams,
    }
  );

  return data;
};

export const createUser = async (
  params: EditUserParams,
  pin?: string
): Promise<RealmUser> => {
  const { data } = await api.post(
    `${environment.identityBaseUrl}${realm()}/${endpoint.users}`,
    params,
    { headers: { pin } }
  );

  return data;
};

export const findUserById = async (userId: string): Promise<RealmUser> => {
  const { data } = await api.get(
    `${environment.identityBaseUrl}${realm()}/${endpoint.users}/${userId}`
  );

  return data;
};

export const updateUser = async (
  userId: string,
  params: EditUserParams,
  pin?: string
): Promise<RealmUser> => {
  const { data } = await api.patch(
    `${environment.identityBaseUrl}${realm()}/${endpoint.users}/${userId}`,
    params,
    { headers: { pin } }
  );

  return data;
};

export const deactivateUser = async (
  userId: string,
  pin?: string
): Promise<unknown> => {
  const { data } = await api.put(
    `${environment.identityBaseUrl}${realm()}/${
      endpoint.users
    }/${userId}/deactivate`,
    {},
    { headers: { pin: pin || undefined } }
  );

  return data;
};

export const getMemberGetMemberId = async (
  userId: string
): Promise<{
  id: string;
  user: string;
}> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${endpoint.memberGetMember}/${userId}`
  );
  const { data } = response;

  return data;
};

export const createMemberGetMemberId = async (): Promise<{
  id: string;
  user: string;
}> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${endpoint.memberGetMember}`
  );
  const { data } = response;

  return data;
};
