import { useState } from 'react';
import { Skeleton, Typography } from '..';
import { InputProps } from './Input.interface';
import clsx from 'clsx';
import './Input.styles.scss';
import dayjs from 'dayjs';

export const Input = ({
  name,
  placeholder,
  label,
  hint = 'default',
  register,
  onPaste,
  validationSchema,
  classAttr,
  error = false,
  children,
  maxLength,
  showCounter = false,
  isLoading = false,
  form,
  ...props
}: InputProps) => {
  const [count, setCount] = useState(0);
  const [currentType, setCurrentType] = useState(props.type);

  const isDateType = props.type === 'date';
  const currentDate =
    isDateType && form?.watch(name) ? dayjs(form?.watch(name)) : null;

  const calendarDay = currentDate?.isSame(dayjs().startOf('day'))
    ? 'Hoje, '
    : currentDate?.isSame(dayjs().add(1, 'day').startOf('day'))
    ? 'Amanhã, '
    : '';

  return (
    <Skeleton height={62} isLoading={isLoading} className="mb-4">
      <div className="input_container">
        <div className="input_container_text">
          <Typography color="var(--product-neutral-n100)" tag="p" weight="p3">
            {label}
          </Typography>
        </div>

        {isDateType && form && (
          <div
            className={clsx(classAttr, {
              date_picker_container: true,
              'input--invalid': error,
              'input--valid': !error,
              'disabled bg-neutral-10 !border-neutral-30 border border-solid':
                props.disabled,
            })}
          >
            <i
              className={clsx(
                'text-p2 fa-regular fa-calendar fa-1x text-neutral-300',
                {
                  'text-neutral-80': props.disabled,
                }
              )}
            />

            <p
              className={clsx('text-p2 text-neutral-300', {
                'text-neutral-80': props.disabled,
              })}
            >
              {currentDate
                ? calendarDay + currentDate?.format('DD/MM/YYYY')
                : placeholder || 'dd/mm/aaaa'}
            </p>

            <input
              type="date"
              className="date_picker_input"
              data-testid={name}
              {...register(name, validationSchema)}
              {...props}
            />
          </div>
        )}

        {!isDateType && (
          <div className="input_container_input_wrapper">
            <input
              id={name}
              data-testid={name}
              className={clsx(classAttr, {
                'input--invalid': error,
                'input--valid': !error,
                with_icon: props.type === 'password' && maxLength !== 1,
              })}
              placeholder={placeholder}
              onPaste={(event) => {
                maxLength && setCount(event.currentTarget.value.length);
              }}
              onKeyUp={(event) => {
                maxLength && setCount(event.currentTarget.value.length);
              }}
              maxLength={maxLength}
              {...register(name, validationSchema)}
              {...props}
              type={maxLength === 1 ? props.type ?? currentType : currentType}
            />

            {props.type === 'password' && maxLength !== 1 && (
              <i
                className={clsx('fa-solid icon-input icon icon--medium', {
                  'fa-eye-slash': currentType !== 'password',
                  'fa-eye': currentType === 'password',
                })}
                onClick={() => {
                  setCurrentType(
                    currentType === 'password' ? 'text' : 'password'
                  );
                }}
              />
            )}
          </div>
        )}

        {children ||
          (showCounter && (
            <div
              className={`input_container${
                children ? '_children' : ''
              }_counter`}
            >
              {children}
              {maxLength && showCounter && (
                <Typography
                  color="var(--product-neutral-n100)"
                  tag="p"
                  weight="p3"
                >
                  {`${count}/${maxLength}`}
                </Typography>
              )}
            </div>
          ))}
      </div>
    </Skeleton>
  );
};
