import { useTranslation } from 'react-i18next';

import { Alert, SidebarFooter, SidebarSection } from '@components';
import { Button, InputOTP, Spinner, Typography } from '@portao3-web/ui';

export interface AuthenticationProps {
  pin: string;
  setPin: (value: string) => void;
  pinErrorsCount?: number;
  isPending: boolean;
  onClose: () => void;
  error?: boolean;
}

export const Authentication = ({
  pin,
  setPin,
  pinErrorsCount,
  isPending,
  onClose,
  error,
}: AuthenticationProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setPin('');
    onClose();
  };

  return (
    <>
      <SidebarSection
        titleSection={t('general.security-confirmation')}
        subTitleSection={t('auth.enter-eletronic-password')}
      >
        <section className="mx-auto mb-6">
          <InputOTP
            disabled={isPending}
            length={4}
            error={error}
            hiddenPassword
            value={pin}
            setValue={setPin}
          />
        </section>

        {!!pinErrorsCount && (
          <Alert status="error">
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {t('auth.wrong-password-evit-block', {
                remainingAttempts: 3 - pinErrorsCount,
              })}
            </Typography>
          </Alert>
        )}
      </SidebarSection>

      <SidebarFooter>
        <Button
          type="button"
          size="large"
          variant="tertiary"
          className="button_submit"
          onClick={handleClose}
        >
          {t('general.button.cancel')}
        </Button>

        <Button
          size="large"
          className="button_submit"
          type="submit"
          disabled={isPending || pin.length < 4}
        >
          {isPending ? <Spinner variant="secondary" /> : t('button.finish')}
        </Button>
      </SidebarFooter>
    </>
  );
};
