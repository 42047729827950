import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { ListResponse } from '@interfaces/Common.interfaces';
import { InvoiceDealProps } from '@interfaces/Invoices';

import { InvoicesProps } from '@interfaces/Invoices.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export interface InvoiceCustomerListParams {
  status?: string[];
  id?: string;
  description?: string;
  endDate?: string;
  startDate?: string;
  dealId?: string;
  customerId: string;
  next?: string;
}

export const getCustomerInvoicesList = async (
  params: InvoiceCustomerListParams
): Promise<ListResponse<InvoiceDealProps>> => {
  const { customerId, ...rest } = params;
  const paramsCopy = { ...rest, order: 'DESC' };
  removeEmptyObjectAttributes(paramsCopy);

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/customers/${customerId}/${
      endpoint.billingInvoices
    }`,
    {
      params: paramsCopy,
    }
  );

  const { data } = response;

  return data;
};

export const paymentInvoiceP2P = async (
  invoiceId: string
): Promise<InvoicesProps> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }/${invoiceId}/pay-p2p`
  );

  const { data } = response;

  return data;
};
