import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ClearFiltersQuery,
  EmptyStateBlock,
  MultiSelectQuery,
  Pagination,
  SearchQuery,
} from '@components';
import {
  emptyCustomersBillingDeals,
  emptyCustomersBillingDealsWithFilter,
  errorCustomersBillingDeals,
} from '@constants/emptyState';
import { useQueryParams } from '@hooks';
import { DrawerBillingDeal } from '@modules/drawer/drawerBillingDeal';
import { TableDemo } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useInfiniteBillingDealListQuery } from '@services/billingDeal/hooks/useInfiniteBillingDealListQuery';
import { useCustomerBillingDealsColumns } from './useCustomerBillingDealsColumns';

import { DealStatus } from '@enums/BillingDeal.enum';
import { Deal } from '@interfaces/BillingDeal.interfaces';

dayjs.extend(utc);

export const CustomersBillingDeals = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { queryParams } = useQueryParams();
  const { customerId } = useParams();
  const { openDrawer } = useDrawer();

  const status = queryParams.getAll('status') as DealStatus[];
  const description = queryParams.get('deal-name') as string;

  const hasFilters = !status || status.length > 0 || description;

  const statusOptions = [
    {
      label: t('general.canceled'),
      value: DealStatus.CANCELED,
    },
    {
      label: t('general.active'),
      value: DealStatus.OPEN,
    },
    {
      label: t('general.terminated'),
      value: DealStatus.TERMINATED,
    },
  ];

  const {
    data: dealData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isError,
    refetch,
  } = useInfiniteBillingDealListQuery({
    status,
    description,
    customerId,
  });

  const dealList = useMemo(() => {
    const handleOpenBillingDeal = (deal: Deal) => {
      openDrawer(<DrawerBillingDeal deal={deal} />);
    };

    return dealData?.pages[page].items?.map((deal) => ({
      ...deal,
      onRowClick: () => handleOpenBillingDeal(deal),
    }));
  }, [dealData, page, openDrawer]);

  const columns = useCustomerBillingDealsColumns();

  return (
    <div className="flex flex-col gap-4">
      <div className="mb-4 flex flex-wrap gap-3">
        <SearchQuery placeholder={t('invoices.deal-name')} query="deal-name" />

        <MultiSelectQuery
          maxSelectedShow={0}
          query="status"
          title={t('general.status')}
          options={statusOptions}
        />

        <ClearFiltersQuery queries={['status', 'deal-name']} />
      </div>

      <TableDemo
        headerColor
        columns={columns}
        isLoading={isLoading}
        data={dealList}
        pinLastColumn
        emptyState={
          <>
            {!hasFilters && !isError && (
              <EmptyStateBlock details={emptyCustomersBillingDeals} />
            )}

            {hasFilters && !isError && (
              <EmptyStateBlock details={emptyCustomersBillingDealsWithFilter} />
            )}

            {isError && (
              <EmptyStateBlock
                details={errorCustomersBillingDeals}
                button
                buttonLabel={t('button.try-again')}
                buttonAction={refetch}
              />
            )}
          </>
        }
      />

      <Pagination
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        page={page}
        setPage={setPage}
        pageCount={dealData?.pages.length}
        className="ml-auto"
      />
    </div>
  );
};
