export enum DrawerPixAreaType {
  PIX = 'PIX',
  FUNDS_IN = 'FUNDS_IN',
  KEYS = 'KEYS',
}

export interface DrawerPixAreaProps {
  area?: DrawerPixAreaType;
  walletId: string;
}
