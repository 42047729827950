import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  ContextualError,
  Input,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { ICONS } from '@constants/icons';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Sidebar, Skeleton } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useCreateBillingPaymentMutation } from '@services/invoices/hooks/useCreateBillingPaymentMutation';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { moneyMask, removeMoneyMask } from '@utils/formatMasks';
import { DrawerInvoiceStatementBankSlip } from '../drawerInvoiceStatement/DrawerInvoiceStatementBankSlip';
import { DrawerInvoiceStatementPix } from '../drawerInvoiceStatement/DrawerInvoiceStatementPix';

import { PaymentMethod } from '@enums/BillingDeal.enum';
import {
  FinancialImpactType,
  InvoiceIdentifierType,
  InvoiceStatementStatus,
} from '@enums/Invoices.enum';
import { useV4Validator } from '@hooks';
import { ListResponse } from '@interfaces/Common.interfaces';
import { InvoiceDealProps } from '@interfaces/Invoices';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';
import {
  customersInvoiceStatementsKeys,
  useCustomerStatementsQuery,
} from '@services/invoices/hooks';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface DrawerGenerateInvoicePaymentProps {
  invoice: InvoiceDealProps;
  type: 'bank-slip' | 'pix';
  buyer?: boolean;
}

export const DrawerGenerateInvoicePayment = ({
  invoice,
  type,
  buyer,
}: DrawerGenerateInvoicePaymentProps) => {
  const { closeDrawer, openDrawer } = useDrawer();
  const { t } = useTranslation();
  const { isFilialV4 } = useV4Validator();
  const queryClient = useQueryClient();

  const diffDueExpireInDays = dayjs(invoice.expiresAt).diff(
    dayjs(invoice.dueAt),
    'day'
  );

  const customValidation = {
    amount: yup
      .string()
      .required('error.required-value')
      .test('is-token', 'error.required-value', (value) => {
        return +removeMoneyMask(value) > 0;
      }),
    dueAt: yup
      .string()
      .required()
      .test('is-past-date', 'error.past-date', (value) => {
        const isSinceToday = dayjs(value).diff(dayjs(), 'day') >= 0;

        return isSinceToday;
      })
      .test('is-past-date', 'error.after-5-days-due-date', (value) => {
        const isFiveDaysAfterDueDate =
          dayjs(value).diff(dayjs(invoice.dueAt), 'day') <= 5;

        return isFilialV4 ? isFiveDaysAfterDueDate : true;
      }),
  };

  const form = useForm({
    defaultValues: {
      amount: '',
      dueAt: dayjs(invoice.dueAt)?.utc(false)?.format('YYYY-MM-DD'),
    },
    resolver: yupResolver(yup.object().shape(customValidation)),
  });

  const {
    mutate: createBillingPayment,
    isPending: isPendingCreateBillingPayment,
    customError,
  } = useCreateBillingPaymentMutation();

  const { data: statements, isLoading: isLoadingStatements } =
    useCustomerStatementsQuery({
      invoiceId: invoice.id,
      enabled: true,
      buyer,
    });

  const getTotalValueStatements = useMemo(() => {
    return statements?.items
      ? statements?.items.reduce((acc, statement) => {
          if (
            statement.financialImpactType === FinancialImpactType.DEBIT &&
            statement.identifier === InvoiceIdentifierType.INCREASE
          ) {
            return acc + statement.originalAmount;
          } else if (
            statement.financialImpactType === FinancialImpactType.CREDIT &&
            statement.identifier === InvoiceIdentifierType.DISCOUNT
          ) {
            return acc - statement.originalAmount;
          }
          return acc;
        }, 0)
      : 0;
  }, [statements?.items]);

  const getTotalAmountPayments = useMemo(() => {
    return statements?.items
      ? statements?.items.reduce((acc, statement) => {
          if (
            statement.financialImpactType === FinancialImpactType.CREDIT &&
            statement.status === InvoiceStatementStatus.DONE
          ) {
            return acc + statement.billingAmount;
          }
          return acc;
        }, 0)
      : 0;
  }, [statements?.items]);

  const finalBalanceValue = useMemo(() => {
    const total =
      getTotalValueStatements + invoice.amount - getTotalAmountPayments;
    form.setValue('amount', moneyMask(total > 0 ? total.toString() : '0'), {
      shouldDirty: total > 0,
      shouldValidate: total > 0,
    });

    const objTotalValue = {
      value: total,
      signal: total < 0 ? `(-)` : total > 0 ? `(+)` : ``,
    };

    return objTotalValue;
  }, [invoice, getTotalValueStatements, getTotalAmountPayments, form]);

  const submit = ({ amount, dueAt }: { amount: string; dueAt: string }) => {
    const paymentMethod =
      type === 'pix' ? PaymentMethod.PIX : PaymentMethod.BANK_SLIP;

    createBillingPayment(
      {
        invoiceId: invoice.id,
        params: {
          dueAt: new Date(dueAt).toISOString(),
          expiresAt: new Date(
            dayjs(dueAt).add(diffDueExpireInDays, 'days').utc(true).toDate()
          ).toISOString(),
          paymentMethod,
          txnAmount: removeMoneyMask(amount),
        },
      },
      {
        onSuccess: (statement) => {
          const drawer =
            type === 'pix' ? (
              <DrawerInvoiceStatementPix
                invoice={invoice}
                onClose={closeDrawer}
                statement={statement}
              />
            ) : (
              <DrawerInvoiceStatementBankSlip
                invoice={invoice}
                onClose={closeDrawer}
                statement={statement}
              />
            );

          openDrawer(drawer);

          queryClient.setQueryData(
            customersInvoiceStatementsKeys.list(invoice.id),
            (
              oldData: ListResponse<InvoiceStatementProps>
            ): ListResponse<InvoiceStatementProps> => {
              return {
                ...oldData,
                items: [{ ...statement }, ...oldData.items],
              };
            }
          );
        },
      }
    );
  };

  return (
    <Sidebar
      open={true}
      title={`${t('general.generate')} ${t(`general.${type}`)}`}
      icon={type === 'pix' ? ICONS['pix'] : ICONS['barcode']}
      onClose={closeDrawer}
    >
      <form onSubmit={form.handleSubmit(submit)}>
        <SidebarSection
          titleSection={`Gerar ${t(`general.${type}`)} para Pagamento`}
        >
          <div className="flex gap-2">
            <Input
              isLoading={isLoadingStatements}
              form={form}
              value={moneyMask(form.watch('amount'))}
              name="amount"
              label={t('general.value')}
              disabled={finalBalanceValue.value <= 0 || isFilialV4 || buyer}
            />

            <Input
              type="date"
              isLoading={isLoadingStatements}
              label={t('general.due-date')}
              name="dueAt"
              form={form}
              disabled={finalBalanceValue.value <= 0 || buyer}
            />
          </div>

          <div className="flex flex-col gap-3">
            <h2 className="text-p1 text-neutral-500">
              {t('general.invoice-summary')}
            </h2>

            <div className="bg-neutral-20 flex flex-col gap-3 rounded-sm px-6 py-4">
              <div className="flex flex-col gap-1">
                <div className="flex justify-between">
                  <p className="text-p2 text-neutral-500">
                    {t('general.invoice-total')}
                  </p>
                  <Skeleton width={120} isLoading={isLoadingStatements}>
                    <p className="text-p2 text-neutral-500">
                      {calcCurrencyValue(invoice.amount, 'BRL')}
                    </p>
                  </Skeleton>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <div className="flex justify-between">
                  <p className="text-p2 text-neutral-500">
                    {t('general.payments')}
                  </p>

                  <Skeleton width={120} isLoading={isLoadingStatements}>
                    <p className="text-p2 text-neutral-500">
                      {getTotalAmountPayments !== 0 && `(-)`}
                      {calcCurrencyValue(getTotalAmountPayments || 0, 'BRL')}
                    </p>
                  </Skeleton>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <div className="flex justify-between">
                  <p className="text-p2 text-neutral-500">
                    {t('general.adjust')}
                  </p>
                  <Skeleton width={120} isLoading={isLoadingStatements}>
                    <p className="text-p2 text-neutral-500">
                      {calcCurrencyValue(getTotalValueStatements || 0, 'BRL')}
                    </p>
                  </Skeleton>
                </div>
              </div>

              <span className="border-neutral-40 w-full border-b border-solid" />

              <div className="flex justify-between">
                <p className="text-p1 font-semibold text-neutral-500">
                  {t('general.balance')}
                </p>

                <Skeleton width={120} isLoading={isLoadingStatements}>
                  <p className="text-p1 font-semibold text-neutral-500">
                    {finalBalanceValue.signal}
                    {moneyMask(
                      removeMoneyMask(finalBalanceValue.value.toString())
                    )}
                  </p>
                </Skeleton>
              </div>
            </div>

            <ContextualError error={customError} />
          </div>
        </SidebarSection>

        <SidebarFooter>
          <Button
            type="button"
            variant="tertiary"
            size="large"
            onClick={closeDrawer}
          >
            {t('general.button.close')}
          </Button>

          <Button
            type="submit"
            size="large"
            data-testid="submit-generate-invoice"
            isLoading={isPendingCreateBillingPayment}
            disabled={
              finalBalanceValue.value <= 0 ||
              removeMoneyMask(form.watch('amount')) === '0'
            }
          >
            {t('general.generate')} {t(`general.${type}`)}
          </Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};
