import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StandardLoggedNavbar } from '@components';
import {
  WalletRole,
  WalletStatus,
  WalletType,
} from '@interfaces/Wallets.interfaces';
import { eventsHandler } from '@lib';
import { DrawerEditWallet } from '@modules/drawer/drawerEditWallet';
import { DropDownSelected, Grid, Menu, MenuList } from '@portao3-web/ui';
import {
  useInfiniteWalletListQuery,
  useUserWalletQuery,
} from '@services/wallet/hooks';
import { capitalizeFirstLetter } from '../../shared/utils/CapitalizeFirstLetter';
import { useDrawer } from '../DrawerProvider';
import { useUser } from '../UserProvider';
import { MenuController } from './Menu';
import { OrgRouterProviderProps } from './OrgRouterProvider.interface';

export const OrgRouterProvider = ({ children }: OrgRouterProviderProps) => {
  const { openDrawer } = useDrawer();
  const user = useUser();
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [skeletonLoading, setSkeletonLoading] = useState(true);

  const currentOrganization = localStorage.getItem('organization') ?? '';

  const { data: userWallet } = useUserWalletQuery({
    userId: user.id,
    organizationId: currentOrganization,
  });

  const { data: wallets, fetchStatus } = useInfiniteWalletListQuery({
    status: WalletStatus.ACTIVE,
    organizationId: currentOrganization,
    search: search,
  });

  const menu = useMemo(() => {
    const currentWallets = wallets?.pages?.[0]?.items;

    const listItems: DropDownSelected[] = [];
    if (!userWallet) return MenuController;

    const hasUserWallet = !!wallets?.pages?.[0].items?.find(
      (wallet) => wallet.id === userWallet.id
    );

    const walletList = currentWallets
      ? search === '' && !hasUserWallet
        ? [...currentWallets, userWallet]
        : [...currentWallets]
      : [userWallet];

    user.isAdmin &&
      listItems.push({
        name:
          walletList.find((wallet) => wallet.type === WalletType.ORGANIZATION)
            ?.name ?? t('general.company-account'),
        type: WalletType.ORGANIZATION,
        secondaryName:
          user.organizations.find((org) => org.id === currentOrganization)
            ?.tradingName ?? '',
        walletId: 'default',
        quickAccess: [
          {
            name: 'Visão Geral',
            url: '/company',
            trackUserAction: () => '',
          },
          {
            name: 'Extrato',
            url: `/wallets/default/statement`,
            trackUserAction: () =>
              eventsHandler.navigateButtonWalletStatementGlobalNavbar(),
          },
          {
            name: 'Cartões',
            url: `/wallets/default/cards`,
            trackUserAction: () =>
              eventsHandler.navigateButtonWalletCardsGlobalNavbar(),
          },
        ],
      });

    walletList
      .filter((item) => item.type !== WalletType.ORGANIZATION)
      .map((wallet) => {
        const owner = wallet?.shared?.find(
          (elem) => elem.role === WalletRole.OWNER
        );

        return listItems.push({
          name: capitalizeFirstLetter(wallet.name),
          type: wallet.type,
          secondaryName: `${owner?.firstName} ${owner?.lastName}`,
          walletId: wallet.id,
          quickAccess: [
            {
              name: 'Visão Geral',
              url: `/wallets/${wallet.id}/geral`,
              trackUserAction: () =>
                eventsHandler.navigateButtonWalletOverviewGlobalNavbar(),
            },
            {
              name: 'Extrato',
              url: `/wallets/${wallet.id}/statement`,
              trackUserAction: () =>
                eventsHandler.navigateButtonWalletStatementGlobalNavbar(),
            },
            {
              name: 'Cartões',
              url: `/wallets/${wallet.id}/cards`,
              trackUserAction: () =>
                eventsHandler.navigateButtonWalletCardsGlobalNavbar(),
            },
          ],
        });
      });

    const copyMenu = JSON.stringify(MenuController);
    const newMenu: MenuList[] = JSON.parse(copyMenu);
    newMenu[0].dropDown.listItems = listItems;

    if (user.isAdmin) {
      newMenu[1].menuList.map((item) => {
        return (item.visibleOption = true);
      });
    }

    const filterMenu = newMenu[1].menuList.filter((item) => item.visibleOption);
    newMenu[1].menuList = filterMenu;

    setSkeletonLoading(false);

    return newMenu;
  }, [user, currentOrganization, userWallet, wallets, search, t]);

  return (
    <div className="flex">
      <Menu
        data={menu}
        showLastLink={true}
        setSearchWallet={setSearch}
        isLoading={fetchStatus === 'fetching'}
        skeletonLoading={skeletonLoading}
        createWallet={
          user.isAdmin ? () => openDrawer(<DrawerEditWallet />) : undefined
        }
        trackCreateWalletUserAction={() =>
          eventsHandler.createButtonNewWalletGlobalNavbar()
        }
        trackSearchWalletUserAction={() =>
          eventsHandler.searchButtonWalletListGlobalNavbar()
        }
        trackShowAllWalletUserAction={() =>
          eventsHandler.navigateButtonWalletsPageGlobalNavbar()
        }
      />

      <div className="boxNavbar">
        <StandardLoggedNavbar />
      </div>

      <div className="content">
        <Grid>
          <div className="content_page">{children}</div>
        </Grid>
      </div>
    </div>
  );
};
