import { createSentryClickBreadcrumb } from '@lib/sentry';

enum WALLET_EVENT {
  SELECT_DATEPICKER_RANGE = 'select_datepickerRange_wallet',
  VIEW_OPTIONS_WALLET_TRANSACTION = 'view_options_walletTransaction_wallet',
  SELECT_OPTION_AREA_PIX = 'select_option_areaPix_wallet',
  SELECT_OPTION_FUNDS_OUT_BOLETO = 'select_option_fundsOutBoleto_wallet',
  SELECT_OPTION_FUNDS_IN = 'select_option_fundsIn_wallet',
  CLICK_BUTTON_MANAGE_WALLET = 'click_button_manageWallet_wallet',
  VIEW_OPTIONS_WALLET_CONFIG = 'view_options_walletConfig_wallet',
  SELECT_OPTION_EDIT_WALLET = 'select_option_editWallet_wallet',
  SELECT_OPTION_RESET_WALLET_BALANCE = 'select_option_resetWalletBalance_wallet',
  SELECT_OPTION_DEACTIVATE_WALLET = 'select_option_deactivateWallet_wallet',
  CLICK_BUTTON_AREA_PIX = 'click_button_areaPix_wallet',
  CLICK_BUTTON_FUNDS_OUT_BOLETO = 'click_button_fundsOutBoleto_wallet',
  CLICK_BUTTON_FUNDS_IN = 'click_button_fundsIn_wallet',
  CREATE_BUTTON_DRAWER_NEW_CARD = 'create_button_drawerNewCard_wallet',
  CLICK_BUTTON_VIEW_CARDS_LIST = 'click_button_viewCardsList_wallet',
  SHOW_BUTTON_CARD_DATA = 'show_button_cardData_wallet',
  HIDE_BUTTON_CARD_DATA = 'hide_button_cardData_wallet',
  OPEN_BUTTON_TRANSACTION_DETAILS = 'open_button_transactionDetails_wallet',
  SELECT_OPTION_WALLET_CATEGORY = 'select_option_walletCategory_wallet',
  CANCEL_BUTTON_UPDATE_WALLET_BALANCE = 'cancel_button_updateWalletBalance_wallet',
  SUBMIT_BUTTON_UPDATE_WALLET_BALANCE = 'submit_button_updateWalletBalance_wallet',
  ENABLE_TOGGLE_WALLET_PIX = 'enable_toggle_walletPix_wallet',
  DISABLE_TOGGLE_WALLET_PIX = 'disable_toggle_walletPix_wallet',
  ENABLE_TOGGLE_WALLET_BOLETO = 'enable_toggle_walletBoleto_wallet',
  DISABLE_TOGGLE_WALLET_BOLETO = 'disable_toggle_walletBoleto_wallet',
  ENABLE_TOGGLE_WALLET_CARD = 'enable_toggle_walletCard_wallet',
  DISABLE_TOGGLE_WALLET_CARD = 'disable_toggle_walletCard_wallet',
  VIEW_OPTIONS_WALLET_PERMISSION = 'view_options_walletPermission_wallet',
  CLICK_INPUT_CUSTOMFIELD = 'click_input_customField_wallet',
  CANCEL_BUTTON_WALLET_CREATION = 'cancel_button_walletCreation_wallet',
  SUBMIT_BUTTON_WALLET_CREATION = 'submit_button_walletCreation_wallet',
  CANCEL_MODAL_RESET_WALLET_BALANCE = 'cancel_modal_resetWalletBalance_wallet',
  SUBMIT_MODAL_RESET_WALLET_BALANCE = 'submit_modal_resetWalletBalance_wallet',
  CLOSE_BUTTON_AREA_PIX_DRAWER = 'close_button_areaPixDrawer_wallet',
  CLICK_BUTTON_AREA_PIX_TRANSFER = 'click_button_areaPixTransfer_wallet',
  CLICK_BUTTON_AREA_PIX_FUNDS_IN = 'click_button_areaPixFundsIn_wallet',
  CLICK_BUTTON_AREA_PIX_KEYS = 'click_button_areaPixKeys_wallet',
  CANCEL_BUTTON_PIX_FUNDS_IN = 'cancel_button_pixFundsIn_wallet',
  CANCEL_BUTTON_PIX_FUNDS_OUT = 'cancel_button_pixFundsOut_wallet',
  SUBMIT_BUTTON_PIX_FUNDS_OUT = 'submit_button_pixFundsOut_wallet',
  CLICK_TAB_AREA_PIX_DEFAULT = 'click_tab_areaPixDefault_wallet',
  CLICK_TAB_AREA_PIX_QR = 'click_tab_areaPixQR_wallet',
  CLICK_TAB_AREA_PIX_COPY_PASTE = 'click_tab_areaPixCopyPaste_wallet',
  CLICK_BUTTON_PASTE_KEY = 'click_button_pasteKey_wallet',
  BACK_BUTTON_AREA_PIX_TRANSFER = 'back_button_areaPixTransfer_wallet',
  SUBMIT_BUTTON_PIX_INTENT_FUNDS_OUT = 'submit_button_pixIntentFundsOut_wallet',
  CREATE_BUTTON_NEW_PIX_KEY = 'create_button_newPixKey_wallet',
  VIEW_OPTIONS_PIX_KEY_TYPES = 'view_options_pixKeyTypes_wallet',
  CANCEL_BUTTON_NEW_PIX_KEY = 'cancel_button_newPixKey_wallet',
  SUBMIT_BUTTON_PIX_KEY_TYPE = 'submit_button_pixKeyType_wallet',
  COPY_BUTTON_PIX_KEY = 'copy_button_pixKey_wallet',
  CLOSE_BUTTON_FUNDS_OUT_BOLETO = 'close_button_fundsOutBoleto_wallet',
  BACK_BUTTON_FUNDS_OUT_BOLETO = 'back_button_fundsOutBoleto_wallet',
  CANCEL_BUTTON_FUNDS_OUT_BOLETO = 'cancel_button_fundsOutBoleto_wallet',
  SUBMIT_BUTTON_FUNDS_OUT_BOLETO = 'submit_button_fundsOutBoleto_wallet',
}

export const walletEvents = {
  selectDatepickerRangeWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SELECT_DATEPICKER_RANGE);
  },
  viewOptionsWalletTransactionWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.VIEW_OPTIONS_WALLET_TRANSACTION);
  },
  selectOptionAreaPixWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SELECT_OPTION_AREA_PIX);
  },
  selectOptionFundsOutBoletoWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SELECT_OPTION_FUNDS_OUT_BOLETO);
  },
  selectOptionFundsInWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SELECT_OPTION_FUNDS_IN);
  },
  clickButtonManageWalletWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_MANAGE_WALLET);
  },
  viewOptionsWalletConfigWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.VIEW_OPTIONS_WALLET_CONFIG);
  },
  selectOptionEditWalletWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SELECT_OPTION_EDIT_WALLET);
  },
  selectOptionResetWalletBalanceWallet: () => {
    createSentryClickBreadcrumb(
      WALLET_EVENT.SELECT_OPTION_RESET_WALLET_BALANCE
    );
  },
  selectOptionDeactivateWalletWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SELECT_OPTION_DEACTIVATE_WALLET);
  },
  clickButtonAreaPixWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_AREA_PIX);
  },
  clickButtonFundsOutBoletoWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_FUNDS_OUT_BOLETO);
  },
  clickButtonFundsInWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_FUNDS_IN);
  },
  createButtonDrawerNewCardWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CREATE_BUTTON_DRAWER_NEW_CARD);
  },
  clickButtonViewCardsListWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_VIEW_CARDS_LIST);
  },
  showButtonCardDataWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SHOW_BUTTON_CARD_DATA);
  },
  hideButtonCardDataWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.HIDE_BUTTON_CARD_DATA);
  },
  openButtonTransactionDetailsWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.OPEN_BUTTON_TRANSACTION_DETAILS);
  },
  selectOptionWalletCategoryWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SELECT_OPTION_WALLET_CATEGORY);
  },
  cancelButtonUpdateWalletBalanceWallet: () => {
    createSentryClickBreadcrumb(
      WALLET_EVENT.CANCEL_BUTTON_UPDATE_WALLET_BALANCE
    );
  },
  submitButtonUpdateWalletBalanceWallet: () => {
    createSentryClickBreadcrumb(
      WALLET_EVENT.SUBMIT_BUTTON_UPDATE_WALLET_BALANCE
    );
  },
  enableToggleWalletPixWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.ENABLE_TOGGLE_WALLET_PIX);
  },
  disableToggleWalletPixWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.DISABLE_TOGGLE_WALLET_PIX);
  },
  enableToggleWalletBoletoWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.ENABLE_TOGGLE_WALLET_BOLETO);
  },
  disableToggleWalletBoletoWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.DISABLE_TOGGLE_WALLET_BOLETO);
  },
  enableToggleWalletCardWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.ENABLE_TOGGLE_WALLET_CARD);
  },
  disableToggleWalletCardWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.DISABLE_TOGGLE_WALLET_CARD);
  },
  viewOptionsWalletPermissionWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.VIEW_OPTIONS_WALLET_PERMISSION);
  },
  clickInputCustomFieldWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_INPUT_CUSTOMFIELD);
  },
  cancelButtonWalletCreationWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CANCEL_BUTTON_WALLET_CREATION);
  },
  submitButtonWalletCreationWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SUBMIT_BUTTON_WALLET_CREATION);
  },
  cancelModalResetWalletBalanceWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CANCEL_MODAL_RESET_WALLET_BALANCE);
  },
  submitModalResetWalletBalanceWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SUBMIT_MODAL_RESET_WALLET_BALANCE);
  },
  closeButtonAreaPixDrawerWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLOSE_BUTTON_AREA_PIX_DRAWER);
  },
  clickButtonAreaPixTransferWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_AREA_PIX_TRANSFER);
  },
  clickButtonAreaPixFundsInWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_AREA_PIX_FUNDS_IN);
  },
  clickButtonAreaPixKeysWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_AREA_PIX_KEYS);
  },
  cancelButtonPixFundsOutWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CANCEL_BUTTON_PIX_FUNDS_OUT);
  },
  submitButtonPixFundsOutWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SUBMIT_BUTTON_PIX_FUNDS_OUT);
  },
  clickTabAreaPixDefaultWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_TAB_AREA_PIX_DEFAULT);
  },
  clickTabAreaPixQRWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_TAB_AREA_PIX_QR);
  },
  clickTabAreaPixCopyPasteWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_TAB_AREA_PIX_COPY_PASTE);
  },
  clickButtonPasteKeyWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLICK_BUTTON_PASTE_KEY);
  },
  backButtonAreaPixTransferWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.BACK_BUTTON_AREA_PIX_TRANSFER);
  },
  submitButtonPixIntentFundsOutWallet: () => {
    createSentryClickBreadcrumb(
      WALLET_EVENT.SUBMIT_BUTTON_PIX_INTENT_FUNDS_OUT
    );
  },
  createButtonNewPixKeyWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CREATE_BUTTON_NEW_PIX_KEY);
  },
  viewOptionsPixKeyTypesWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.VIEW_OPTIONS_PIX_KEY_TYPES);
  },
  cancelButtonNewPixKeyWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CANCEL_BUTTON_NEW_PIX_KEY);
  },
  submitButtonPixKeyTypeWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SUBMIT_BUTTON_PIX_KEY_TYPE);
  },
  copyButtonPixKeyWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.COPY_BUTTON_PIX_KEY);
  },
  closeButtonFundsOutBoletoWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CLOSE_BUTTON_FUNDS_OUT_BOLETO);
  },
  backButtonFundsOutBoletoWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.BACK_BUTTON_FUNDS_OUT_BOLETO);
  },
  cancelButtonFundsOutBoletoWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.CANCEL_BUTTON_FUNDS_OUT_BOLETO);
  },
  submitButtonFundsOutBoletoWallet: () => {
    createSentryClickBreadcrumb(WALLET_EVENT.SUBMIT_BUTTON_FUNDS_OUT_BOLETO);
  },
};
