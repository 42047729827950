import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { BankSlip } from '@interfaces/BankSlip.interface';
import api from '@services/api';
import { FormTicket } from '../NewTicket.interface';

export const bankSlipPayment = async (
  formData: FormTicket,
  walletId?: string
): Promise<BankSlip> => {
  const isWalletId = walletId || 'default';
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWalletId}/bank-slip/check`,
    formData
  );
  const { data } = response;

  return data;
};
