import { StatusEnum } from '@enums/Status.enum';
import { WalletBalanceCategory } from '@enums/WalletEnum';
import { CustomFieldView } from './CustomFields.interfaces';
import { PixKey } from './Pix.interface';

export interface WalletBalance {
  id: string;
  name: string;
  realm: string;
  account: string;
  role: string;
  owner: Owner;
  currency: string;
  organization: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  totalBalance: number;
  balances: WalletCategory[];
  status: StatusEnum;
  settings: {
    payment: {
      pix: boolean;
      bankSlip: boolean;
      card: boolean;
    };
  };
}

export enum OwnerType {
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
}

interface Owner {
  id: string;
  type?: OwnerType;
}

export interface WalletCategory {
  category: WalletBalanceCategory;
  amount: number;
}

interface PaymentSettings {
  pix: boolean;
  bankSlip: boolean;
  card: boolean;
}

interface Balance {
  amount: number;
  email: boolean;
  isActive: boolean;
}

export interface Balancelimit {
  balanceLimit: Balance;
}

export interface WalletPreferences {
  payment?: PaymentSettings;
  notification?: Balancelimit;
}

export enum WalletRole {
  OWNER = 'OWNER',
  USER = 'USER',
  READ_ONLY = 'READ_ONLY',
}

export interface CreateWallet {
  name: string;
  type: WalletType;
  settings: WalletPreferences;
  shared: Pick<UserWalletCredentials, 'id' & 'role'>;
  customFields?: CustomFieldView[];
}

export interface CreateWalletResponse {
  id: string;
  name: string;
  createdBy: string;
  updatedBy: string;
  realm: string;
  organization: string;
  status: StatusEnum;
  type: WalletType;
  currency: string;
  settings: WalletPreferences;
  shared: SharedProps[];
  customFields: CustomFieldView[];
}

interface UserWalletCredentials {
  id: string;
  role: WalletRole;
  firstName: string;
  lastName: string;
  email: string;
}

export interface WalletDetailsProps {
  id: string;
  name: string;
  realm: string;
  type: WalletType;
  shared: SharedProps[];
  settings: WalletPreferences;
  currency: string;
  organization: string;
  status: WalletStatus;
  balances?: WalletCategory[];
  totalBalance?: number;
  createdAt: Date;
  customFields: CustomFieldView[];
  dict?: PixKey;
}

export enum WalletType {
  PERSONAL = 'PERSONAL',
  ORGANIZATION = 'ORGANIZATION',
  SHARED = 'SHARED',
}

export interface SharedProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: WalletRole;
  _id: string;
  src?: string;
}

export interface ListWalletsProps {
  items: WalletDetailsProps[];
  next: string | null;
}

export enum WalletStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface WalletPixKey {
  id: string;
  key: string;
  type: string;
}

export interface UpdateWalletParams {
  walletId: string;
  payload: CreateWallet | Omit<CreateWallet, 'name' | 'type' | 'shared'>;
  pin?: string;
}
