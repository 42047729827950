export enum WalletType {
  PERSONAL = 'PERSONAL',
  ORGANIZATION = 'ORGANIZATION',
  SHARED = 'SHARED',
}

export interface DropDownSelected {
  name: string;
  type: WalletType;
  secondaryName: string;
  walletId: string;
  quickAccess: QuickAccess[];
}

interface QuickAccess {
  name: string;
  url: string;
  trackUserAction?: () => void;
}

export interface DropDownListProps {
  selectedItem: (itemSelected: DropDownSelected) => void;
  listItems: DropDownSelected[];
  itemSelected: DropDownSelected;
  setSearchWallet: (wallet: string) => void;
  isLoading: boolean;
  createWallet?: () => void;
  trackShowAllWalletsUserAction?: () => void;
  trackCreatelWalletsUserAction?: () => void;
  trackSearchWalletsUserAction?: () => void;
}
