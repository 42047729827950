import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { AddressCep } from '@interfaces/General.interfaces';
import { findCep } from '../findCep';

export const useFindCepQuery = (cep: string) => {
  return useQuery<AddressCep, ApiError, AddressCep, unknown[]>({
    queryKey: ['postalCode', cep],
    queryFn: () => findCep(cep),
    enabled: !!cep && cep.length === 8,
  });
};
