import { endpoint, organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { environment } from '@environments/environment';
import {
  BatchPayment,
  FindBatchPaymentsListParams,
} from '@interfaces/BatchPayments.interfaces';
import { ListResponse } from '@interfaces/Common.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export const findBatchList = async (
  params?: FindBatchPaymentsListParams
): Promise<ListResponse<BatchPayment>> => {
  const paramsCopy = { ...params };
  paramsCopy.limit = paramsCopy.limit || limitPerPage;
  removeEmptyObjectAttributes(paramsCopy);

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.batchPayment
    }`,
    { params: paramsCopy }
  );

  const { data } = response;

  return data;
};
