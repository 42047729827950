import { createSentryClickBreadcrumb } from '@lib/sentry';

enum NAVBAR_GLOBAL_EVENT {
  VIEW_OPTIONS_WALLET_LIST = 'view_options_walletList_globalNavbar',
  SEARCH_BUTTON_WALLET_LIST = 'search_button_walletList_globalNavbar',
  NAVIGATE_BUTTON_WALLETS_PAGE = 'navigate_button_walletsPage_globalNavbar',
  CREATE_BUTTON_NEW_WALLET = 'create_button_newWallet_globalNavbar',
  NAVIGATE_BUTTON_WALLET_OVERVIEW = 'navigate_button_walletOverview_globalNavbar',
  NAVIGATE_BUTTON_WALLET_STATEMENT = 'navigate_button_walletStatement_globalNavbar',
  NAVIGATE_BUTTON_WALLET_CARDS = 'navigate_button_walletCards_globalNavbar',
  NAVIGATE_BUTTON_ADMIN_STATEMENT = 'navigate_button_adminStatement_globalNavbar',
  NAVIGATE_BUTTON_ADMIN_WALLETS = 'navigate_button_adminWallets_globalNavbar',
  NAVIGATE_BUTTON_ADMIN_CARDS = 'navigate_button_adminCards_globalNavbar',
  NAVIGATE_BUTTON_ADMIN_USERS = 'navigate_button_adminUsers_globalNavbar',
  NAVIGATE_BUTTON_ADMIN_PREFERENCES = 'navigate_button_adminPreferences_globalNavbar',
}

export const navbarGlobalEvents = {
  viewOptionsWalletListGlobalNavbar: () => {
    createSentryClickBreadcrumb(NAVBAR_GLOBAL_EVENT.VIEW_OPTIONS_WALLET_LIST);
  },
  searchButtonWalletListGlobalNavbar: () => {
    createSentryClickBreadcrumb(NAVBAR_GLOBAL_EVENT.SEARCH_BUTTON_WALLET_LIST);
  },
  navigateButtonWalletsPageGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_WALLETS_PAGE
    );
  },
  createButtonNewWalletGlobalNavbar: () => {
    createSentryClickBreadcrumb(NAVBAR_GLOBAL_EVENT.CREATE_BUTTON_NEW_WALLET);
  },
  navigateButtonWalletOverviewGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_WALLET_OVERVIEW
    );
  },
  navigateButtonWalletStatementGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_WALLET_STATEMENT
    );
  },
  navigateButtonWalletCardsGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_WALLET_CARDS
    );
  },
  navigateButtonAdminStatementGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_ADMIN_STATEMENT
    );
  },
  navigateButtonAdminWalletsGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_ADMIN_WALLETS
    );
  },
  navigateButtonAdminCardsGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_ADMIN_CARDS
    );
  },
  navigateButtonAdminUsersGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_ADMIN_USERS
    );
  },
  navigateButtonAdminPreferencesGlobalNavbar: () => {
    createSentryClickBreadcrumb(
      NAVBAR_GLOBAL_EVENT.NAVIGATE_BUTTON_ADMIN_PREFERENCES
    );
  },
};
