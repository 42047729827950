import { endpoint } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { UserEmail } from '@interfaces/User.interfaces';
import api from '@services/api';

export const requestToken = async (payload: UserEmail): Promise<[]> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.forgotPassword}`,
    payload
  );
  const { data } = response;

  return data;
};
