import { useMemo, useState } from 'react';

import { eventsHandler } from '@lib';
import { Sidebar, Skeleton, Tabs } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useWalletQuery } from '@services/wallet/hooks';
import { ActiveNotifications } from './components/ActiveNotifications';
import { General } from './components/General';

export const DrawerEditWallet = () => {
  const organizationId = localStorage.getItem('organization') ?? '';

  const { closeDrawer, params } = useDrawer();

  const [hasModal, setHasModal] = useState<boolean>(false);
  const [actionModal, setActionModal] = useState(false);

  const { data: wallet, isLoading } = useWalletQuery({
    walletId: params?.walletId ?? '',
    organizationId,
  });

  const handleCloseDrawer = () => {
    if (!wallet) {
      eventsHandler.closeDrawerCreateCardAdminCards();
    }

    closeDrawer();
  };

  const tabs = useMemo(() => {
    const tabs = [
      {
        id: '1',
        label: 'Geral',
        content: (
          <Skeleton height={'500px'} isLoading={isLoading}>
            <General
              wallet={wallet}
              setHasConfirmationModal={setHasModal}
              setOpenConfirmationModal={setActionModal}
            />
          </Skeleton>
        ),
      },
    ];

    if (wallet) {
      tabs.push({
        id: '2',
        label: 'Notificações',
        content: (
          <Skeleton height={'500px'} isLoading={isLoading}>
            <ActiveNotifications
              wallet={wallet}
              setHasConfirmationModal={setHasModal}
              setOpenConfirmationModal={setActionModal}
            />
          </Skeleton>
        ),
      });
    }

    return tabs;
  }, [wallet, isLoading]);

  return (
    <Sidebar
      title={`${wallet ? 'Editar carteira' : 'Nova carteira'}`}
      icon="fa-regular fa-wallet"
      open={true}
      onClose={handleCloseDrawer}
      hasModal={hasModal}
      actionModal={actionModal}
      setActionModal={setActionModal}
    >
      <Tabs tabs={tabs} hasModal={hasModal} setActionModal={setActionModal} />
    </Sidebar>
  );
};
