import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import {
  Entries,
  InvoicesDealResponse,
  InvoicesProps,
} from '@interfaces/Invoices.interfaces';
import { ListResponse } from '@interfaces/Common.interfaces';

export const getInvoiceList = async (): Promise<InvoicesDealResponse> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }?role=BUYER`
  );
  const { data } = response;

  return data;
};

export const getInvoiceEntries = async (
  invoiceId: string
): Promise<ListResponse<Entries>> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }/${invoiceId}/entries?role=BUYER`
  );
  const { data } = response;

  return data;
};

export const paymentInvoiceP2P = async (
  invoiceId: string
): Promise<InvoicesProps> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }/${invoiceId}/pay-p2p`
  );

  const { data } = response;

  return data;
};
