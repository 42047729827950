import { createSentryClickBreadcrumb } from '@lib/sentry';

enum TOPBAR_GLOBAL_EVENT {
  VIEW_OPTIONS_AVATAR = 'view_options_avatar_globalTopbar',
  CLICK_BUTTON_CHANGE_ORG = 'click_button_changeOrg_globalTopbar',
  CLICK_BUTTON_LOGOUT = 'click_button_logout_globalTopbar',
}

export const topbarGlobalEvents = {
  viewOptionsAvatarGlobalTopbar: () => {
    createSentryClickBreadcrumb(TOPBAR_GLOBAL_EVENT.VIEW_OPTIONS_AVATAR);
  },
  clickButtonChangeOrgGlobalTopbar: () => {
    createSentryClickBreadcrumb(TOPBAR_GLOBAL_EVENT.CLICK_BUTTON_CHANGE_ORG);
  },
  clickButtonLogoutGlobalTopbar: () => {
    createSentryClickBreadcrumb(TOPBAR_GLOBAL_EVENT.CLICK_BUTTON_LOGOUT);
  },
};
