import { Button, InputOTP, Spinner, ToastrContainer } from '@portao3-web/ui';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ContextualError,
  GeneralPresentationLogin,
  HeaderForm,
} from '@components';
import { AuthToken } from '@interfaces/Auth.interfaces';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { AuthContext } from '@providers';
import { mfa } from '@services/mfa';
import { encryptToken } from '@utils/crypto';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

export const Mfa = () => {
  const { session, token, email } = useContext(AuthContext);
  const { t } = useTranslation();

  const [userCode, setUserCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [traceId, setTraceId] = useState('');
  const history = useNavigate();

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);
      const requestMfa = {
        userCode,
        email: email.value,
        session: session.value,
      };

      const mfaResponse = await mfa<AuthToken>(requestMfa);

      localStorage.setItem('token', JSON.stringify(encryptToken(mfaResponse)));
      token.setToken(mfaResponse.accessToken);
    } catch (error: unknown) {
      const errorService = error as AxiosError;
      const errorInfo = errorService.response?.data as ErrorRequest;

      setError(t('error.mfa-validation'));
      setTraceId(errorInfo?.traceId);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!email.value || !session.value) {
      history('/');
    }
  }, [email.value, session.value, history]);

  return (
    <div className="authentication">
      <div className="authentication_content">
        <header className="authentication_img_header" />
        <GeneralPresentationLogin />
        <div className="authentication_content_right">
          <div className="authentication_content_right_form">
            <HeaderForm
              title="Verificação em duas etapas"
              subTitle="Essa etapa é necessária para garantir a segurança da sua conta."
            />

            {error && <ContextualError error={{ message: error, traceId }} />}

            <form
              className="authentication_content_right_form_inputs"
              onSubmit={submitForm}
            >
              <div className="authentication_submit">
                <section className="mx-auto mb-6">
                  <InputOTP
                    key="input-mfa-login"
                    length={6}
                    error={!!error && userCode.length === 6}
                    hiddenPassword={false}
                    value={userCode}
                    setValue={(value) => {
                      setUserCode(value);
                      setError('');
                    }}
                  />
                </section>

                <Button
                  size="large"
                  disabled={userCode.length < 6 || loading}
                  data-testid="submitForm"
                >
                  {loading ? <Spinner variant="secondary" /> : 'Concluir'}
                </Button>

                <Button
                  type="button"
                  size="large"
                  variant="secondary"
                  data-testid="cancelButton"
                  onClick={() => history('/login')}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastrContainer />
    </div>
  );
};
