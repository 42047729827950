import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Column, Grid, Row, Typography } from '@portao3-web/ui';
import { getFullDateByISO } from '@utils/date';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { cnpjMask, cpfMask } from '@utils/formatMasks';
import { Alert } from '../../../alerts';
import { PixPaymentVoucherProps } from './PixPaymentVoucher.interfaces';

export const PixPaymentVoucher = ({
  txnUpdatedAmount,
  createdAt,
  creditParty,
  endToEndId,
  debitParty,
  description,
}: PixPaymentVoucherProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Alert status="success">
        <Typography tag="p" weight="p2" color="var(--product-success-s700)">
          {t('payment-voucher.pix-success')}
        </Typography>
        <Typography tag="p" weight="p2" color="var(--product-neutral-n100)">
          {t('payment-voucher.pix-success-message', {
            value: calcCurrencyValue(txnUpdatedAmount, 'BRL'),
            destination: creditParty.name,
            date: getFullDateByISO(createdAt),
            time: dayjs(createdAt).utc(true).format('HH:mm'),
          })}
        </Typography>
      </Alert>

      <div>
        <Grid className="paymentVoucher_info">
          <Row>
            <Column lg={12} md={12} sm={12}>
              <div>
                <Typography
                  tag="p"
                  weight="p1"
                  color="var(--product-neutral-n500)"
                  className="accountName"
                >
                  {t('payment-voucher.your-account')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n60)"
                  className="accountName"
                >
                  {t('payment-voucher.origin-account')}
                </Typography>
              </div>
            </Column>
          </Row>
          <Row>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('general.from')}
                </Typography>

                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {debitParty.name}
                </Typography>
              </div>
            </Column>
            <Column lg={6} md={6} sm={6}>
              {debitParty?.document && (
                <div>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    {t('general.cpf/cnpj')}
                  </Typography>

                  <Typography
                    tag="p"
                    weight="p2"
                    color="var(--product-neutral-n500)"
                  >
                    {debitParty?.document?.length === 14
                      ? cnpjMask(debitParty.document)
                      : cpfMask(debitParty.document)}
                  </Typography>
                </div>
              )}
            </Column>
          </Row>
          <hr className="hr" />

          <Row>
            <Column lg={12} md={12} sm={12}>
              <div>
                <Typography
                  tag="p"
                  weight="p1"
                  color="var(--product-neutral-n500)"
                  className="accountName"
                >
                  {t('payment-voucher.destination-account')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n60)"
                  className="accountName"
                >
                  {t('payment-voucher.destination-account-info')}
                </Typography>
              </div>
            </Column>
          </Row>
          <Row>
            <Column lg={12} md={12} sm={12}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
              >
                {t('general.to')}
              </Typography>
            </Column>
            <Column lg={12} md={12} sm={12}>
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                {creditParty.name}
              </Typography>
            </Column>
          </Row>

          <Row>
            <Column lg={6} md={6} sm={6}>
              {creditParty?.document && (
                <div>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    {t('general.cpf/cnpj')}
                  </Typography>

                  <Typography
                    tag="p"
                    weight="p2"
                    color="var(--product-neutral-n500)"
                  >
                    {creditParty?.document?.length === 14
                      ? cnpjMask(creditParty.document)
                      : cpfMask(creditParty.document)}
                  </Typography>
                </div>
              )}
            </Column>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('general.pix-key')}
                </Typography>

                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {creditParty?.key}
                </Typography>
              </div>
            </Column>
          </Row>

          {description && (
            <Row>
              <Column lg={12} md={12} sm={12}>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('general.description')}
                </Typography>
              </Column>
              <Column lg={12} md={12} sm={12}>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {description}
                </Typography>
              </Column>
            </Row>
          )}

          <hr className="hr" />

          <Row>
            <Column lg={12} md={12} sm={12}>
              <div>
                <Typography
                  tag="p"
                  weight="p1"
                  color="var(--product-neutral-n900)"
                  className="accountName"
                >
                  {t('payment-voucher.transfer-info')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n60)"
                  className="accountName"
                >
                  {t('payment-voucher.additional-info')}
                </Typography>
              </div>
            </Column>
          </Row>
          <Row>
            <Column lg={6} md={6} sm={6}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('general.type')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {t('general.pix')}
                </Typography>
              </div>
            </Column>
          </Row>

          <Row>
            <Column lg={12} md={12} sm={12}>
              <div>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  {t('payment-voucher.auth-code')}
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {endToEndId}
                </Typography>
              </div>
            </Column>
          </Row>
        </Grid>
      </div>
    </>
  );
};
