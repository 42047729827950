import { createSentryClickBreadcrumb } from '@lib/sentry';

enum MEMBER_GET_MEMBER_EVENT {
  OPEN_MODAL_MEMBER_GET_MEMBER_HEADER = 'OPEN_MODAL_MEMBER_GET_MEMBER_HEADER',
  CLICK_BUTTON_CREATE_MEMBER_GET_MEMBER = 'CLICK_BUTTON_CREATE_MEMBER_GET_MEMBER',
  COPY_MEMBER_GET_MEMBER_LINK = 'COPY_MEMBER_GET_MEMBER_LINK',
}
// view_options_walletTransaction_walletCompany

export const memberGetMemberEvents = {
  openModalMemberGetMember: () => {
    createSentryClickBreadcrumb(
      MEMBER_GET_MEMBER_EVENT.OPEN_MODAL_MEMBER_GET_MEMBER_HEADER
    );
  },
  clickButtonCreateMemberGetMember: () => {
    createSentryClickBreadcrumb(
      MEMBER_GET_MEMBER_EVENT.CLICK_BUTTON_CREATE_MEMBER_GET_MEMBER
    );
  },
  copyMemberGetMemberLink: () => {
    createSentryClickBreadcrumb(
      MEMBER_GET_MEMBER_EVENT.COPY_MEMBER_GET_MEMBER_LINK
    );
  },
};
