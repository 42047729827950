export const environment = {
  production: true,
  sentryEnabled: true,
  title: 'Prod Environment Heading',
  name: 'production',
  expensesBaseUrl: 'https://api.v2.portao3.com.br/',
  identityBaseUrl: 'https://api.identity.v2.portao3.com.br/',
  platformBaseUrl: 'https://api.platform.v2.portao3.com.br/',
  recaptchaKey: '6LdCxuIpAAAAAPWQXrIaYyOeK3dlHel-s3iEkQjN',
  secretCryptoKey: 'secret-crypto-key',
  secretCryptoPin:
    'LS0tLS1CRUdJTiBSU0EgUFVCTElDIEtFWS0tLS0tCk1JSUJDZ0tDQVFFQW9ZK0RtcTdsVVlhamVXcVAzdEJqdmJnVjIySFdSSk4wME5iMW90UHlIbElxMGRtcVozUHcKb29qMXViNW1FYWlselAwdTNaOGxZY2tJMWhYdnhZNGFGOWhUZGVXaDZlUWtyVlpETzJ0d1c3Tk85dG5QNWNCcwpNNXR0dmt1dFpXT1pxbXN2VjFGcjVUQTRyUm9ESGxqU04rYjBsMzhXbUNCV3hZMTNUcjlXdVlLOHduT2tKQVhxClFXTy9Qa3RRc3BaZVlUVnZxUDdKUkM1V1A5Z2xCNDQyTXh6WDNGWkx1OEM3RGEwQ1p6K2w5cjVoNTZWa0FlbEUKMUNadGZjYzZ5eXRSQTZLR2ViV1hQVEtyYzdUOTJTbFhza3pBV1NtOUZUS24yL1YwQVFaVlZqL1hRRFU4dElBRApQQWl1NUhNYXBubzM2S2VXY2V1N1ljcS9sSEVwTnV4UnNRSURBUUFCCi0tLS0tRU5EIFJTQSBQVUJMSUMgS0VZLS0tLS0=',
};
