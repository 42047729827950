import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeStatusModal } from '@components';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import { Button, Icon, Spinner, Toastr, Typography } from '@portao3-web/ui';
import { useLocation } from 'react-router-dom';
import { PIX_ICONS, PIX_KEY_MASKS, REMOVE_KEY_TEXT } from '../../constants';
import { Dict } from '../../interfaces/interfaces';
import { removePixDict } from '../../services';
import { KeyCardProps } from './KeyCard.interfaces';
import './KeyCard.styles.scss';

export const KeyCard = ({
  walletId,
  pixKey,
  setError,
  onSuccess,
}: KeyCardProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [animation, setAnimation] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCopyKey = async (key: string) => {
    pathname?.includes('/company') &&
      eventsHandler.copyButtonPixKeyWalletCompany();
    pathname?.includes('/wallets') && eventsHandler.copyButtonPixKeyWallet();

    setAnimation(true);
    setTimeout(() => setAnimation(false), 3000);

    try {
      await navigator.clipboard.writeText(key);
    } catch (error) {
      Toastr.error(t('error.need-permission-clipboard'));
    }
  };

  const {
    mutateAsync: removeKey,
    isPending,
    error,
  } = useMutation({
    mutationFn: ({ id, key }: { id: string; key: Dict }) =>
      removePixDict(id, key.id),
    onSuccess: () => {
      customerEvents.pixKeysDeleted({
        pixId: pixKey.id,
        pixKeyType: pixKey.type,
        walletId,
      });

      setError(null);
      setShowConfirmationModal(false);
      onSuccess();
    },
    onError: (error) => {
      const errorService = error as AxiosError;
      const errorInfo = errorService.response?.data as ErrorRequest;

      setError({
        message: t('error.default'),
        traceId: errorInfo?.traceId,
      });
    },
  });

  const getFormattedKey = () => {
    const value = pixKey.value;

    // if (pixKey.type === PixKeyType.PHONE) {
    //   value = value.replace('+55', '');
    // }

    const maskedValue =
      PIX_KEY_MASKS[pixKey.type as keyof typeof PIX_KEY_MASKS]?.(value);

    return maskedValue ? maskedValue : value;
  };

  return (
    <div className="pix-key-card">
      <Icon size="medium">
        <i className={`fa-regular ${PIX_ICONS[pixKey.type]} fa-1x key-icon`} />
      </Icon>
      <div className="flex w-full flex-col gap-1">
        <Typography
          tag="p"
          weight="p2"
          color="var(--product-neutral-n500)"
          className="fixed-line-height"
        >
          {t(`pix.drawer.key.type.${pixKey.type}`)}
        </Typography>
        <div className="flex flex-row gap-1">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n90)">
            {getFormattedKey()}
          </Typography>
          <button
            type="button"
            className="pix-key-card_copy-button"
            onClick={() => handleCopyKey(pixKey.value)}
            data-testid={`copy-pix-key-${pixKey.id}`}
          >
            <Icon size="medium">
              {animation ? (
                <i className="fa-regular fa-check fa-1x check-icon" />
              ) : (
                <i className="fa-regular fa-copy fa-1x key-icon" />
              )}
            </Icon>
          </button>
        </div>
      </div>
      {isPending ? (
        <Spinner />
      ) : (
        <Button
          size="small"
          variant="tertiary"
          className="pix-key-card_remove-button"
          onClick={() => setShowConfirmationModal(true)}
          data-testid={`remove-pix-key-${pixKey.id}`}
        >
          <Icon size="medium">
            <i className="fa-regular fa-trash fa-1x key-icon" />
          </Icon>
        </Button>
      )}
      {showConfirmationModal && (
        <ChangeStatusModal
          open={showConfirmationModal}
          closeModal={() => setShowConfirmationModal(false)}
          changeStatus={() => removeKey({ id: walletId, key: pixKey })}
          modalText={REMOVE_KEY_TEXT}
          errorService={!!error}
          isLoading={isPending}
        />
      )}
    </div>
  );
};
